const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

const describeArc = (x, y, radius, spread, startAngle, endAngle) => {
    const innerStart = polarToCartesian(x, y, radius, endAngle);
    const innerEnd = polarToCartesian(x, y, radius, startAngle);
    const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
        'M', outerStart.x, outerStart.y,
        'A', radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
        'L', innerEnd.x, innerEnd.y,
        'A', radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
        'L', outerStart.x, outerStart.y, 'Z'
    ].join(' ');
};

const percentageToDegree = (percentage) => {
    return percentage / 100 * 360;
};

export const svgBase = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path id="arc" d=" M 50 50 A 50 50 0 0 0 0 0" stroke="white" /></svg>';

const getSvgPath = (percentage) => {
    return describeArc(250, 250, 230, 20, 0, percentageToDegree(100 - percentage));
};

export default getSvgPath;