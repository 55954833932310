<template>
    <div class="alternative-events-selector">
        <label>
            {{ project.alternativeRegistrationEvents.labelText }}
        </label>
        <v-select v-model="selectedEvent"
                  :items="alternativeEvents"
                  item-value="_id"
                  item-text="name"
                  :color="darkenPrimaryColor"
                  class="field-dropdown alternative-events-selector-field"
                  single
                  solo
                  flat
                  dense
                  outlined
                  background-color="white"
                  :menu-props="{'two-line': true}"
        >
            <template v-slot:item="{item}">
                <div class="d-flex"
                     style="width: 100%"
                >
                    <span class="mr-4"
                          style="flex: 1 1 0"
                    >
                        {{ item.name }}
                    </span>
                    <span class="grey--text"
                          style="flex: 0 0 auto; font-size: 12px"
                    >
                        {{ item.startTimeFormatted }}
                    </span>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import {ROUTE_REGISTRATION} from '@/router/constants';

    export default {
        name: 'AlternativeEventsSelector',
        computed: {
            ...mapGetters('project', ['alternativeEvents', 'darkenPrimaryColor']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['selectedEventId']),
            selectedEvent: {
                get() {
                    return this.selectedEventId;
                },
                set(value) {
                    this.setSelectedEventId(value);
                }
            }
        },
        methods: {
            ...mapMutations('registration', ['setSelectedEventId']),
        },
        watch: {
            selectedEvent(value, oldValue) {
                if (value === oldValue) {
                    return;
                }

                if (value === this.$route.params['event_id']) {
                    return false;
                }

                window.sessionStorage.setItem('selectedAlternativeEvent', value);
                this.$emit('event-selected');
                setTimeout(() => {
                    this.$router.push({
                        name: ROUTE_REGISTRATION,
                        params: {
                            event_id: value,
                        },
                        query: this.$route.query,
                    });
                }, 300);
            }
        }
    };
</script>

<style lang="scss">
.v-menu__content{
    border-radius: var(--input-border-radius);
}

.alternative-events-selector-field.v-input{
    border-radius: var(--input-border-radius);
}

.alternative-events-selector-field {
    i{
        color: #3E3E3E !important;
        bottom: 4px !important;
        right: -8px !important
    }
}
</style>