<template>
    <div class="text-content-block"
         :class="{'mobile': isMobile, 'gradient': gradient}"
    >
        <div class="content-wrapper">
            <div class="content"
                 v-html="content"
            />
            <v-btn v-if="ctaButtonEnabled"
                   :color="darkenPrimaryColor"
                   class="cta-button mt-5"
                   :href="options.ctaButton.ctaButtonUrl"
                   target="_blank"
                   :style="{...textColorStyle, 'align-self': options.ctaButton.alignment}"
            >
                {{ options.ctaButton.ctaButtonText }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'TextContentBlock',
        props: {
            options:{
                type: Object,
                default: () => {}
            },
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'textColorStyle',
                'currentEvent',
                'privacyPolicyUrl',
                'primaryColorRGB',
                'darkenPrimaryColor',
                'getContent'
            ]),
            content(){
                if(this.options.content){
                    return this.getContent(this.options.content);
                }
                return '';
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            ctaButtonEnabled(){
                return this.options?.ctaButton?.enableCtaButton || false;
            }
        }
    };
</script>

<style lang="scss" scoped>
.text-content-block {
    padding: 60px 325px;
    @media (min-width:1600px) and (max-width: 1800px) {
        padding: 40px 200px;
    }

    @media (min-width:960px) and (max-width: 1600px) {
        padding: 40px 130px;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), linear-gradient(130.22deg, rgba(0, 0, 0, 0.3) -16.19%, rgba(var(--r), var(--g), var(--b), 0.3) 52.73%);
    }

    &.mobile{
        padding: 68px 16px;
        min-height: 320px;
        &.gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), linear-gradient(130.22deg, rgba(0, 0, 0, 0.4) -16.19%, rgba(var(--r), var(--g), var(--b), 0.4) 52.73%);
        }

        .cta-button{
            color: var(--text-color);
            min-width: 101px;
            height: 32px;
            font-size: 11px !important;
        }
    }

    .cta-button{
        color: var(--text-color);
        min-width: 148px;
        height: 40px;
        font-size: 15px;
    }

    .content-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

}

.half-width{
    .text-content-block {
        color: #ffffff;
        padding: 60px 60px;
        display: flex;
        align-items: center;
        &.gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), linear-gradient(130.22deg, rgba(0, 0, 0, 0.3) -16.19%, rgba(var(--r), var(--g), var(--b), 0.3) 52.73%);
        }

        .cta-button{
            color: var(--text-color);
            min-width: 148px;
            height: 40px;
            font-size: 15px;
        }

        &.mobile{
            padding: 68px 16px;
            &.gradient {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), linear-gradient(130.22deg, rgba(0, 0, 0, 0.4) -16.19%, rgba(var(--r), var(--g), var(--b), 0.4) 52.73%);
            }
            .cta-button{
                color: var(--text-color);
                min-width: 101px;
                height: 32px;
                font-size: 11px !important;
            }
        }
    }
}
</style>

<style lang="scss">
.block-container {
  .text-content-block {
    .content > * {
      color: var(--block-text-color);
    }

    .content > div > * {
      color: var(--block-text-color) !important;
    }
  }
}

.half-width {
  .text-content-block {
    .content {
      p {
        font-size: 1rem !important;
      }
    }
  }
}
</style>
