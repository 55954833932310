<template>
    <div class="field-dropdown">
        <v-select
            ref="select"
            v-model="model"
            :placeholder="$t('fields.choose_answer')"
            :items="options"
            :options="options"
            :item-value="valueTextOrId"
            item-text="text"
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            :color="color"
            outlined
            attach
            class="dropdown-field"
            :menu-props="{'max-width': ownWidth, contentClass: 'should-wrap'}"
            :background-color="backgroundColor"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldDropdown',
        props: {
            value: {
                type: String,
                default: () => '',
            },
            options: {
                type: Array,
                default: () => [],
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            name:{
                type: String,
                default: () => ''
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
            color:{
                type: String,
                default: () => ''
            }        
        },
        data() {
            return {
                model: this.value,
                currentElement: null
            };
        },
        watch: {
            model() {
                this.$emit('input', this.model);
            },
        },
        computed: {
            ownWidth() {
                if (this.currentElement) {
                    const style = getComputedStyle(this.currentElement);
                    return style.width.replace('px', '');
                }

                return 'auto';
            },
            isRegistrationPage(){
                return this.$route.name === 'Registration' || this.$route.name === 'RegistrationConfirmation';
            },
            valueTextOrId(){
                return this.isRegistrationPage && 
                    (this.name.includes('company') || this.name.includes('title')) ? 
                        'text' : 'id';
            }
        },
        methods: {
            documentScroll() {
                this.$refs.select.blur();
            },
        },
        mounted() {
            this.currentElement = this.$refs.select.$el;
            if(document.querySelector('.form-holder')){
                document.querySelector('.form-holder').addEventListener('scroll', this.documentScroll);
                window.addEventListener('resize', this.documentScroll);
            }
        },
        beforeDestroy() {
            if(document.querySelector('.form-holder')){
                document.querySelector('.form-holder').removeEventListener('scroll', this.documentScroll);
                window.removeEventListener('resize', this.documentScroll);
            }
        }
    };
</script>

<style lang="scss">

.v-menu__content{
    border-radius: var(--input-border-radius);
}

.field-dropdown{
    .v-input{
        border-radius: var(--input-border-radius);
    }
}

.dropdown-field {
    i{
        color: #3E3E3E !important;
        bottom: 4px !important;
        right: -8px !important
    }
}

.should-wrap {
    .v-list-item__title {
        white-space: initial;
    }
}

</style>
