<template>
    <div class="errorContainer">
        <v-card tile
                class="error-holder"
        >
            <v-card-title v-if="hasErrorsWithoutTitle"
                          class="error-holder-title"
            >
                {{ errorTitle }}
            </v-card-title>
            <v-card-text class="error-content">
                <div v-if="!splitError || !hasErrorsWithoutTitle">
                    {{ error }}
                </div>
                <div v-else>
                    <div v-for="(e, index) in errorsWithoutTitle"
                         :key="`main-error-${index}`"
                    >
                        {{ e }}
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'MainErrorDisplay',
        props: {
            error: {
                type: String,
                default: () => '',
            },
            splitError: {
                type: Boolean,
                default: () => false,
            }
        },
        computed: {
            errors() {
                const errors = this.error.split('.');
                errors.pop();
                for (let x in errors) {
                    errors[x] = `${errors[x]}.`;
                }
                return errors;
            },
            errorTitle() {
                return [...this.errors].shift();
            },
            errorsWithoutTitle() {
                let errors = [...this.errors];
                errors.shift();
                return errors;
            },
            hasErrorsWithoutTitle() {
                return this.splitError && this.errorsWithoutTitle.length > 0;
            }

        }
    };
</script>

<style scoped>
.errorContainer {
    height: calc(100vh - 64px);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.error-holder {
    flex: 0 0 auto;
    text-align: center;
}

.error-holder-title {
    justify-content: center;
}

.error-content {
    font-size: 16px;
    color: #3E3E3E !important;
}
</style>
