<template>
    <div class="contents"
         :style="darkenPrimaryColorStyle"
    >
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.overview.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.overview.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.liveBroadcast.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.liveBroadcast.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.cookies.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.cookies.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.savedData.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.savedData.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.retentionPeriod.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.retentionPeriod.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.dataAccess.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.dataAccess.content')" />
        <component :is="headingComponent"
                   class="mb-2 font-weight-regular"
                   :class="headingClass"
        >
            {{ $t('privacyPolicy.contact.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa"
                   class="mb-3"
        />
        <p v-html="$t('privacyPolicy.contact.content')" />
        <address class="mt-4">
            {{ $t('privacyPolicy.contactAddressLine1') }} <br>
            {{ $t('privacyPolicy.contactAddressLine2') }} <br>
            {{ $t('privacyPolicy.contactAddressLine3') }} <br>
            {{ $t('privacyPolicy.contactAddressLine4') }} <br>
            {{ $t('privacyPolicy.contactAddressLine5') }} <br><br>
            {{ $t('privacyPolicy.contactAddressLine6') }} <br>
            {{ $t('privacyPolicy.contactAddressLine7') }} <br>
        </address>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'PrivacyPolicy',
        computed: {
            ...mapGetters('project', ['darkenPrimaryColorStyle', 'fontFamilyStyle']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            headingComponent() {
                return this.isRegisteredForPwa ? 'h4' : 'h2';
            },
            headingClass() {
                return {
                    'mt-5': this.isRegisteredForPwa,
                    'mt-4': !this.isRegisteredForPwa,
                };
            }
        }
    };
</script>

<style scoped>

</style>