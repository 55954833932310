<template>
    <div class="full-height mx-0 d-flex flex-column">
        <MainLoader />
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import MainLoader from '@/components/controls/MainLoader.vue';
    import {ROUTE_EVENT_WITH_PERSONAL_ID} from '@/router/constants';

    export default {
        name: 'CustomBreakoutNavigation',
        components: {MainLoader},
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['currentEvent']),
            ...mapState('registration', ['registrationData', 'registrationId']),
        },
        methods: {
            ...mapActions('registration', ['getRegistration', 'registerForEvent']),
        },
        async mounted() {
            await this.getRegistration(window.sessionStorage.getItem('registration_id'));
            await this.registerForEvent({
                event: this.currentEvent,
                isNavigating: true,
            });
            window.location.href = this.$router.resolve({
                name: ROUTE_EVENT_WITH_PERSONAL_ID,
                params: {
                    event_id: this.currentEvent._id,
                    registration_id: this.registrationId,
                }
            }).href;

        }
    };
</script>

<style scoped lang="scss">

</style>