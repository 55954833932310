<template>
    <label :class="{'clickable': clickable}"
           @click="handleClick"
    >
        <span>
            {{ buttonText }}
        </span>
    </label>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {navigate, timeUntilReactive, timeUntilToString} from '@/services/AgendaService';
    import {ROUTE_EVENT_WITH_PERSONAL_ID} from '@/router/constants';

    export default {
        name: 'AgendaStatusSession',
        props: {
            event: {
                type: Object,
                required: true 
            },            
            startTime: {
                type: Number,
                required: true
            },
            now: {
                type: Boolean,
                required: true
            },
            ended: {
                type: Boolean,
                required: true
            },
            isCurrentEvent: {
                type: Boolean,
                required: true
            },       
            onDemandActive: {
                type: Boolean,
                required: true
            },
            isMobile: {
                type: Boolean,
                required: true
            },
        },
        computed: {
            ...mapState('time', ['currentTime']),
            ...mapGetters('registration', ['registrationId']),
            buttonText() {
                if (this.ended) {
                    return this.onDemandActive 
                        ? this.$t('multiSession.watch')
                        : this.$t('multiSession.ended');
                } else if (this.now) {
                    return this.isCurrentEvent
                        ? this.$t('multiSession.now')
                        : this.$t('multiSession.join');
                } else {
                    return timeUntilToString(timeUntilReactive(this.startTime, this.currentTime));
                }
            },
            clickable() {
                return (this.now && !this.isCurrentEvent) || (this.ended && this.onDemandActive);
            },
        },
        methods: {
            handleClick() {
                if (this.clickable) {
                    navigate(this.$router, ROUTE_EVENT_WITH_PERSONAL_ID, this.event._id, this.registrationId);
                }
            }
        }
    };
</script>
<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>