<template>
    <div class="main-loader">
        <v-progress-circular indeterminate
                             color="primary"
        />
    </div>
</template>

<script>
    export default {
        name: 'MainLoader'
    };
</script>

<style scoped>
    .main-loader {
        width: 100vw;
        height: 100vh;
        background-color: #f2f2f2;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
</style>