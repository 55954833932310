<template>
    <div class="fullHeight mx-0">
        <registration-form
            @submit="register"
        />
        <registration-confirmation v-if="renderRegistrationConfirmation" />
    </div>
</template>

<script>
    import RegistrationForm from '@/components/pages/RegistrationPage/RegistrationForm';
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import RegistrationConfirmation from '@/components/pages/RegistrationPage/RegistrationConfirmation';
    import {
        ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE,
        ROUTE_PERSONAL_LINK,
        ROUTE_REGISTRATION_CONFIRMATION
    } from '@/router/constants';
    import {getClientTimezone} from '@/services/TimeZoneService';

    export default {
        name: 'RegistrationPage',
        components: {RegistrationConfirmation, RegistrationForm},
        computed: {
            ...mapGetters('project', ['currentEvent']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['loading']),
            ...mapGetters('registration', ['isOnSiteVisitor']),
            renderRegistrationConfirmation() {
                return this.$route.name === ROUTE_REGISTRATION_CONFIRMATION;
            },
            shouldRedirectToPersonalOndemand() {
                return this.$route.query.redirectToPersonalOnDemand === true;
            }
        },
        methods: {
            ...mapMutations('registration', [ 'setRegistrationLoading', 'setRegistrationData', 'setRegistrationId', 'setCannotRegisterForEvents']),
            ...mapActions('registration', ['sendRegistration']),
            ...mapActions('project', ['registerAction']),
            async register(data) {
                if(this.loading) {
                    return;
                }
                const form = Object.assign({}, data.fieldValues, {
                    event_id: this.currentEvent._id,
                    time_zone: getClientTimezone(),
                    SELECTED_EVENTS: data.selectedEvents
                });

                this.setRegistrationLoading(true);
                this.setRegistrationData(form);
                const {registrationID, cannotRegisterForEvents} = await this.sendRegistration(form);
                if(cannotRegisterForEvents){
                    this.setCannotRegisterForEvents(cannotRegisterForEvents);
                }
                this.setRegistrationId(registrationID);
                if(this.$route.query.pwa || this.isOnSiteVisitor){
                    await this.$router.push({
                        name: ROUTE_PERSONAL_LINK,
                        params: {
                            'event_id': this.currentEvent._id,
                            'registration_id': registrationID
                        }
                    });
                    return;
                }
                if (this.shouldRedirectToPersonalOndemand) {
                    this.goToPersonalOnDemand(registrationID);
                    this.setRegistrationLoading(false);
                    return;
                }
                if (this.$route.name !== ROUTE_REGISTRATION_CONFIRMATION) {
                    this.goToRegistrationConfirmationPage(registrationID);
                }
                this.setRegistrationLoading(false);
            },
            goToRegistrationConfirmationPage(registrationId) {
                this.$router.push(
                    {
                        name: ROUTE_REGISTRATION_CONFIRMATION,
                        params: {
                            event_id: this.currentEvent._id,
                            registration_id: registrationId,
                        }
                    });
            },
            goToPersonalOnDemand(registrationId) {
                this.$router.push(
                    {
                        name: ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE,
                        params: {
                            event_id: this.currentEvent._id,
                            registration_id: registrationId,
                        }
                    });
            },
        },
        mounted() {
            if (this.$route.name !== ROUTE_REGISTRATION_CONFIRMATION) {
                this.registerAction('registration-page-view');
            }
        }
    };
</script>

<style scoped>
.fullHeight{
    height: 100%;
}
</style>
