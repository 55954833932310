<template>
    <v-row class="fullHeight mx-0 test-page"
           :class="classes"
           justify="center"
    >
        <v-col cols="12"
               xl="8"
               lg="8"
               md="9"
               sm="9"
               class="pa-0 px-3 test-page-wrapper-col"
        >
            <h4 class="test-page-title mb-3">
                {{ $t('test.title') }}
            </h4>
            <div id="onDemandVideoPlayer"
                 :class="playerClass"
                 class="test-page-video-player"
            >
                <script
                    type="application/javascript"
                    data-autoplay="viewable"
                    :src="playerURL"
                    data-custom-unmute="true"
                    :data-custom-unmute-text="$t('event.unmute_text')"
                    :data-video-url="$t('test.video_url')"
                />
            </div>
            <div class="content-text-test-page"
                 v-html="$t('test.content')"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'TestPage',
        data() {
            return {
                playerScale: 'normal',
            };
        },
        computed: {
            ...mapGetters('project', ['currentEvent']),
            ...mapState('project', ['project']),
            playerURL() {
                return process.env.VUE_APP_PLAYER_URL;
            },
            playerClass() {
                return [`playerscale-${this.playerScale}`, 'player-container'];
            },
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown;
            },
            classes() {
                return {
                    'mobile': this.isMobile
                };
            }
        },
    };
</script>

<style scoped lang="scss">
.test-page {
    overflow-y: auto;
    .test-page-wrapper-col {
        height: calc(100vh - 64px);

    }

    .test-page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .content-text-test-page {
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 18px !important;
            line-height: 150%;
            margin-bottom: 0 !important;
            color: white !important;
        }
    }

    .content-text-test-page:hover {
        overflow-y: auto !important;
    }

    .test-page-video-player {
        margin-bottom: 24px;
    }

    &.mobile {
        .test-page-wrapper {
            margin: 40px 20px 0 20px;
        }

        h4.test-page-title {
            font-size: 23px;
        }
    }
}
</style>

<style lang="scss">
.test-page-page {
    .content-text-test-page {
        a{
            color: white !important
        }
    }

    &.mobile {
        .content-text-test-page {
            max-height: 30vh;
            overflow: auto;
            p {
                font-size: 16px !important;
            }
        }
    }
}
</style>
