export const forceFullScreen = (v) => {
    const liveSidebar = document.querySelector('.live-sidebar');
    const header = document.querySelector('.header');
    const titleWrapper = document.querySelector('.live-title-wrapper');
    if (v) {
        if (liveSidebar) {
            liveSidebar.style.display = 'none';
        }
        if (header) {
            header.classList.remove('d-flex');
            header.classList.add('d-none');
        }
        if (titleWrapper) {
            titleWrapper.classList.add('d-none');
            titleWrapper.classList.remove('d-flex');
        }

    } else {
        if (liveSidebar) {
            liveSidebar.style.display = 'block';
        }
        if (header) {
            header.classList.add('d-flex');
            header.classList.remove('d-none');
        }
        if (titleWrapper) {
            titleWrapper.classList.add('d-flex');
            titleWrapper.classList.remove('d-none');
        }
    }
};
