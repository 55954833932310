const commonToolbarButtons = [
    'camera',
    'desktop',
    'download',
    'filmstrip',
    'fullscreen',
    'help',
    'microphone',
    'participants-pane',
    'profile',
    'raisehand',
    'select-background',
    'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    'tileview',
    'toggle-camera',
    'videoquality',
    '__end',
];

const moderatorToolbarButtons = [
    //  'chat',
    //  'closedcaptions',
    'embedmeeting',
    // "etherpad",
    // "feedback",
    // "hangup",
    'invite',
    'livestreaming',
    'mute-everyone',
    'mute-video-everyone',
    'recording',
    'security',
    'stats',
];

export const toolbarButtons = (moderator) => {
    if (moderator) {
        return [...commonToolbarButtons, ...moderatorToolbarButtons];
    }

    return commonToolbarButtons;
};