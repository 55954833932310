<template>
    <div>
        <v-row no-gutters
               class="event-page-form-wrapper"
               :class="{'mobile': isMobile}"
        >
            <v-col v-if="!isMobile"
                   cols="12"
                   lg="8"
                   md="8"
                   sm="0"
                   class="overlay"
                   @click="closeRegistration"
            />
            <v-col cols="12"
                   lg="4"
                   md="4"
                   sm="12"
                   :class="{'col-md-12': isMobile }"
            >
                <v-card v-if="!loading"
                        class="event-page-registration-form"
                        :class="{'preview' : isInPreviewMode, 'mobile': isMobile}"
                        :style="{...darkenPrimaryColorStyle, ...textColorStyle, ...rgbStyleVars}"
                        color="white"
                >
                    <div class="form-wrapper">
                        <div class="form-holder d-flex flex-column">
                            <v-btn
                                icon
                                class="close-button"
                                @click="closeRegistration"
                            >
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <div v-if="!isMobile">
                                <img class="logo"
                                     :src="logo"
                                     alt="Logo"
                                     :style="customLogoStyle"
                                >
                            </div>
                            <span class="form-title">
                                {{ $t('registration.title') }}
                            </span>
                            <linked-in />
                            <control-holder>
                                <alternative-events-selector @event-selected="eventSelected" />
                                <v-form :key="updateCount"
                                        ref="form"
                                        class="registration-form"
                                        @submit.prevent="register"
                                >
                                    <div v-for="field in registrationFields"
                                         :key="field.id"
                                    >
                                        <label class="form-label">
                                            {{ field.label }}
                                            <span v-if="isMobilePhoneField(field)"
                                                  class="mobile-input-text"
                                            >
                                                ({{ $t('fields.mobile_text') }})
                                            </span>
                                        </label>
                                        <component :is="getComponent(field)"
                                                   v-model="fieldValues[field.name]"
                                                   :label="field.label"
                                                   :name="`register-${field.name}`"
                                                   :class="`register-${field.name}`"
                                                   :required="field.required"
                                                   :options="field.options"
                                                   :color="darkenPrimaryColor"
                                                   single
                                                   solo
                                                   flat
                                                   dense
                                        />
                                    </div>
                                    <multi-session-event-fields v-if="isMultiSession"
                                                                v-model="selectedEvents"
                                                                :required="true"
                                    />
                                    <div class="privacy-policy-remark mb-4">
                                        {{ $t('registration.accept_terms_1') }}
                                        <a v-if="hasPrivacyPolicy"
                                           :href="hasPrivacyPolicy"
                                           target="_blank"
                                        >{{ $t('registration.accept_terms_2') }}</a>
                                        <a v-if="!hasPrivacyPolicy"
                                           href="#privacy-policy"
                                        >{{ $t('registration.accept_terms_2') }}</a>
                                        {{ $t('registration.accept_terms_3') }}
                                    </div>
                                    <v-btn
                                        type="submit"
                                        :loading="registrationLoading"
                                        :style="buttonBackgroundStyle"
                                        class="submit-button"
                                    >
                                        {{ $t('registration.submit') }}
                                    </v-btn>
                                </v-form>
                            </control-holder>
                        </div>
                    </div>
                </v-card>
                <div v-else>
                    {{ $t('registration.loading') }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters, mapState, mapActions, mapMutations} from 'vuex';
    import FieldEmail from '@/components/controls/fields/FieldEmail';
    import FieldText from '@/components/controls/fields/FieldText';
    import ControlHolder from '@/components/templates/default/ControlHolder';
    import FieldRadio from '@/components/controls/fields/FieldRadio';
    import FieldCheckbox from '@/components/controls/fields/FieldCheckbox';
    import FieldDropdown from '@/components/controls/fields/FieldDropdown';
    import FieldTextarea from '@/components/controls/fields/FieldTextarea';
    import FieldMobile from '@/components/controls/fields/FieldMobile';
    import FieldOnSiteViewer from '@/components/controls/fields/FieldOnSiteViewer';
    import {getInitialFieldValues} from '@/services/FieldService';
    import CountDown from '@/components/controls/CountDown';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import MultiSessionEventFields from '@/components/controls/fields/MultiSessionEventFields';
    import ProgramSingleSession from '@/components/controls/ProgramSingleSession';
    import RegistrationConfirmation from '@/components/pages/RegistrationPage/RegistrationConfirmation';
    import { get } from 'lodash';
    import LinkedIn from '@/components/controls/LinkedIn';
    import {
        ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE,
        ROUTE_PERSONAL_LINK,
    } from '@/router/constants';
    import {getClientTimezone} from '@/services/TimeZoneService';
    import FieldDatePicker from '@/components/controls/fields/FieldDatePicker';
    import AlternativeEventsSelector from '@/components/controls/AlternativeEventsSelector.vue';

    export default {
        name: 'RegistrationForm',
        components: {
            AlternativeEventsSelector,
            LinkedIn,
            ControlHolder,
            FieldText,
            FieldDatePicker,
            FieldEmail,
            FieldTextarea,
            FieldDropdown,
            FieldCheckbox,
            FieldRadio,
            FieldOnSiteViewer,
            CountDown,
            FieldMobile,
            Agenda,
            MultiSessionEventFields,
            ProgramSingleSession,
            RegistrationConfirmation
        },
        data() {
            return {
                fieldValues: {},
                selectedEvents: [],
                loading: false,
                updateCount: 0,
                showTimerLabel: true,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapState('registration', ['prefill']),
            ...mapState('registration', {registrationLoading: 'loading'}),
            ...mapGetters('registration', ['isOnSiteVisitor']),
            ...mapGetters('project', [
                'registrationIntroductionText',
                'registrationFields',
                'currentEvent',
                'logo',
                'backgroundImage',
                'getContent', 
                'isMultiSession', 
                'isInPreviewMode', 
                'backgroundGradient',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'textColorStyle',
                'primaryColorRGB', 
                'topics', 
                'buttonBackgroundStyle',
            ]),
            ...mapGetters('dynamicBlocks', ['openRegistrationForm']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            rgbStyleVars() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            backgroundStyle() {
                return {
                    'background-image': `url(${this.backgroundImage})`,
                };
            },
            registrationIntroduction(){
                return this.getContent(this.registrationIntroductionText);
            },
            hasPrivacyPolicy() {
                return this.project.privacyPolicyUrl;
            },
            customLogoStyle() {
                const style = {};
                if(this.project.style.customLogoHeightRegistrationPage) {
                    style.height = this.project.style.customLogoHeightRegistrationPage + 'px';
                }
                if(this.project.style.customLogoWidthInPercentRegistrationPage) {
                    style.width = `${this.project.style.customLogoWidthInPercentRegistrationPage}%`;
                    style.height = 'auto';
                }

                return style;
            },
            shouldRedirectToPersonalOndemand() {
                return this.$route.query.redirectToPersonalOnDemand === true;
            },
        },
        methods: {
            ...mapActions('project', ['getRegistrationsCountPerEvent']),
            ...mapMutations('dynamicBlocks', ['setOpenRegistrationForm']),
            ...mapMutations('registration', [ 'setRegistrationLoading', 'setRegistrationData', 'setCannotRegisterForEvents']),
            ...mapActions('registration', ['sendRegistration']),
            async register() {
                if (this.$refs.form.validate()) {
                    const form = Object.assign({}, this.fieldValues, {
                        event_id: this.currentEvent._id,
                        time_zone: getClientTimezone(),
                        SELECTED_EVENTS: this.selectedEvents
                    });
                    this.setRegistrationLoading(true);
                    this.setRegistrationData(form);
                    const {registrationID, cannotRegisterForEvents} = await this.sendRegistration(form);
                    if(cannotRegisterForEvents){
                        this.setCannotRegisterForEvents(cannotRegisterForEvents);
                    }
                    if (this.shouldRedirectToPersonalOndemand) {
                        this.goToPersonalOnDemand(registrationID);
                        this.setRegistrationLoading(false);
                        return;
                    }
                    if(this.$route.query.pwa || this.isOnSiteVisitor){
                        await this.$router.push({
                            name: ROUTE_PERSONAL_LINK,
                            params: {
                                'event_id': this.currentEvent._id,
                                'registration_id': registrationID
                            }
                        });
                        return;
                    }
                    this.$emit('registered', registrationID);
                    this.setRegistrationLoading(false);
                }
            },
            goToPersonalOnDemand(registrationId) {
                this.$router.push(
                    {
                        name: ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE,
                        params: {
                            event_id: this.currentEvent._id,
                            registration_id: registrationId,
                        }
                    });
            },
            getComponent(field) {
                return `field-${field.type}`;
            },
            isMobilePhoneField(field) {
                return field.type === 'mobile';
            },
            getFieldValues() {
                const fields = {};
                this.registrationFields.forEach(field => {
                    fields[field.name] = getInitialFieldValues(field);
                    const prefill = get(this.prefill, field.name);
                    if (prefill) {
                        fields[field.name] = prefill;
                    }
                });
                this.fieldValues = fields;
                ++this.updateCount;
            },
            closeRegistration(){
                this.setOpenRegistrationForm(false);
            },
            eventSelected() {
                window.sessionStorage.setItem('registrationFormRaw', JSON.stringify(this.fieldValues));
            }
        },
        async mounted() {
            this.loading = true;
            this.getFieldValues();

            if (this.isMultiSession) {
                await this.getRegistrationsCountPerEvent();
            }
            this.loading = false;
            await this.$nextTick();
            if(this.$route.query.linkedin){
                await this.register();
            }
        }
    };
</script>

<style scoped lang="scss">

.preview{
    a{
        pointer-events: none;
    }
}

.register-mail.v-input.v-input__control.v-input__slot.v-text-field__slot.v_label.theme--light {
    font-size: 100px
}


.submit-button {
    height: 44px !important;
    width: 133px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    padding: 10px 32px;
    box-shadow: 0 8px 16px rgba(var(--r), var(--g), var(--b), 0.3);
    color: var(--text-color);
    background: var(--button-background) !important;
}

</style>

<style lang="scss">
$font-size-base-px: 16px;
@function rem-calc($px) {
     @return $px / $font-size-base-px * 1rem;
}

.privacy-policy-remark{
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;

    a{
        color:  var(--darken-primary-color) !important;
    }
}


.registration-form {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0 !important
    }

    .v-input__slot{
        margin-bottom: 4px !important;
    }
    .row.field-checkbox {
        margin-top: initial;
        margin-bottom: 0.2rem;
        .col {
            min-width: 100%;
        }
    }
    .field-radio {
        .v-radio {
            width: 100%;
            margin: 0.3rem 0;
        }
    }
}

.event-page-form-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 100;

    .overlay{
        background: #000000a1;
    }

    .logo {
        position: static;
        height: 44px;
        left: 0;
        top: 0;

        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 32px;
    }

    .event-page-registration-form{
        color: black !important;
        font-weight: 500;
        padding: 40px 60px;
        background-color: #ffffff;
        overflow-y: auto;
        height: 100vh;

        .close-button{
            position: absolute;
            top: 8px;
            left: 10px;
        }

        .v-label{
            color: black !important;
        }

        .form-wrapper{
            .form-title{
                font-size: rem-calc(42px);
                line-height: 50.4px;
                margin-bottom: 24px
            }

            .form-event-title{
                font-size: rem-calc(28px);
                line-height: 33.6px;
                margin-bottom: 12px;
            }

            .start-time{
                font-size: rem-calc(15px);
                line-height: 22.5px;
                margin-bottom: 2px;
            }

            .form-description{
                margin-bottom: 24px;
                p{
                    font-size: rem-calc(14px) !important;
                    color: black;
                }
            }
        }
        &.mobile{
            padding: 60px 40px;
        }
    }
}
</style>
