<template>
    <div class="control-holder">
        <slot :color="color"
              :dark="dark"
        />
    </div>
</template>

<script>
    export default {
        name: 'ControlHolder',
        data() {
            return {
                color: 'primary',
                dark: true,
            };
        }
    };
</script>

<style scoped>

</style>