<template>
    <v-row 
        class="live-page my-0 pwa"
        :class="{
            'preview' : isInPreviewMode
        }"
        tabindex="0"
    >
        <v-col cols="12"
               sm="12"
               class="live-sidebar pwa"
               :style="cssVars"
        >
            <tab-contents :tab="selectedTab" />
            <default-footer class="end-footer dark-text mt-16" />
            <tab-headers v-model="selectedTab"
                         class="menu"
            />
        </v-col>
    </v-row>
</template>
<script>
    import {mapGetters} from 'vuex';
    import TabContents from '@/pwa/templates/default/EventPage/LiveComponents/TabContents';
    import TabHeaders from '@/pwa/templates/default/EventPage/LiveComponents/TabHeaders';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';


    export default {
        name: 'Live',
        mixins: [trackUserLinkClicks],
        components: {
            DefaultFooter,
            TabHeaders,
            TabContents,
        },
        data() {
            return {
                closedPasswordDialog: false,
                selectedTab: '',
            };
        },
        computed: {
            ...mapGetters('project', ['isInPreviewMode', 'pwaPrimaryColorRGB']),
            cssVars(){
                return {
                    '--r': this.pwaPrimaryColorRGB.r,
                    '--g': this.pwaPrimaryColorRGB.g,
                    '--b': this.pwaPrimaryColorRGB.b,
                };
            }
        },
    };
</script>

<style lang="scss">
.template-default.pwa {
    .dark-text a {
        color: #3e3e3e;
    }
    .live-page {
        .live-sidebar {
            z-index: 1;
            height: calc(100vh - 64px);
            background-color: rgba(255, 255, 255, 0.35);
            overflow-y: auto;
            overflow-x: hidden;            
            padding: 40px 30px 77px !important;

            @media (min-width: 961px) {
                padding: 40px 150px 77px !important;
            }
            @media (min-width: 1300px) {
                padding: 40px 200px 77px !important;
            }
            @media (min-width: 1500px) {
                padding: 40px 300px 77px !important;
            }
        }

        .menu{
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 100
        }
    } 
}
</style>
