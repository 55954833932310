<template>
    <div ref="wrapper"
         class="qld-question from-quadia-live"
    >
        <form novalidate
              @submit.prevent="save"
        >
            <div class="qld-form-group">
                <label @click="forwardClick('qld-question-name')">{{ questionFieldsConfig.name.label }}</label>
                <input
                    v-model="question.name"
                    class="qld-question-name qld-form-control"
                    type="text"
                    :class="{'qld-input-error': errors.name}"
                    :placeholder="questionFieldsConfig.name.label"
                    @input="resetError('name')"
                >
            </div>
            <div class="qld-form-group">
                <label
                    @click="forwardClick('qld-question-email')"
                >{{ questionFieldsConfig.email.label }}</label>
                <input
                    v-model="question.email"
                    class="qld-question-email qld-form-control"
                    type="email"
                    :class="{'qld-input-error': errors.email}"
                    :placeholder="questionFieldsConfig.email.label"
                    @input="resetError('email')"
                >
            </div>
            <div class="qld-form-group">
                <label @click="forwardClick('qld-question-type')">{{ questionFieldsConfig.type.label }}</label>
                <select
                    v-model="question.type"
                    class="qld-question-type qld-form-control"
                    :class="{'qld-input-error': errors.type}"
                >
                    <option value="content">
                        {{ contentOptionText }}
                    </option>
                    <option value="technical">
                        {{ technicalOptionText }}
                    </option>
                </select>
            </div>
            <div class="qld-form-group">
                <label
                    @click="forwardClick('qld-question-message')"
                >{{ questionFieldsConfig.message.label }}</label>
                <textarea
                    v-model="question.message"
                    maxlength="1024"
                    class="qld-question-message qld-form-control"
                    :class="{'qld-input-error': errors.message, 'is-processing': submitBtnDisabled}"
                    :placeholder="placeholder"
                    @input="resetError('message')"
                />
            </div>

            <div class="qld-form-group"
                 :class="{'mobile': isMobile}"
            >
                <button
                    type="submit"
                    :disabled="submitBtnDisabled"
                >
                    {{ questionFieldsConfig.submit.label }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import axios from 'axios';

    export default {
        name: 'LiveDirectorQuestion',
        created() {
            if (this.questionFieldsConfig.email.prefilled) {
                this.question.email = this.getIdentifier('email');
            }
            if (this.questionFieldsConfig.name.prefilled) {
                this.question.name = this.getIdentifier('name');
            }
        },
        props: {
            additionalData: {
                type: Object,
                default: null
            },
            customMessagePlaceholder: {
                type: String,
                default: () => '',
            },
            dataQldConfig: {
                type: String,
                required: false,
                default: function () {
                    return '{}';
                }
            }
        },
        data() {
            return {
                errors: {
                    name: false,
                    email: false,
                    type: false,
                    message: false
                },
                question: {
                    name: '',
                    email: '',
                    type: 'content',
                    message: ''
                },
                submitBtnDisabled: false,
            };
        },
        computed: {
            ...mapGetters('qld-vuex-store', {
                broadcast: 'broadcast',
                storeConfig: 'config',
                getIdentifier: 'getIdentifier',
            }),
            questionFieldsConfig() {
                var questionFieldsType = this.broadcast.question_fields_config.type || {};
                var customConfigType = this.config.type || {};
                var mergedType = Object.assign(
                    {},
                    questionFieldsType,
                    customConfigType
                );

                var result = Object.assign(
                    {},
                    this.broadcast.question_fields_config,
                    this.config
                );

                result.type = mergedType;

                return result;
            },
            contentOptionText() {
                return this.questionFieldsConfig.type?.contentLabel || 'Content';
            },
            technicalOptionText() {
                return this.questionFieldsConfig.type?.technicalLabel || 'Technical';
            },
            placeholder() {
                return this.customMessagePlaceholder ? this.customMessagePlaceholder : this.questionFieldsConfig.message.label;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            config: function () {
                try {
                    return JSON.parse(this.dataQldConfig.replace(/'/g, '"'));
                } catch (e) {
                    console.error('data-qld-config="' + this.dataQldConfig + '" is not valid. Configuration ignored!');
                    return {};
                }
            },
        },
        methods: {
            forwardClick(qClass) {
                this.$refs.wrapper.getElementsByClassName(qClass)[0].focus();
            },
            appendAdditionalData(message) {
                const keys = Object.keys(this.additionalData);
                if (keys.length === 0) {
                    return message;
                }

                let appendix = ' \r\n';
                for (let x in keys) {
                    appendix = `${appendix} \r\n ${keys[x]}: ${this.additionalData[keys[x]]}`;
                }
                return `${message}${appendix}`;
            },
            save() {
                if (!this.question.message) {
                    this.errors.message = true;
                    return false;
                }
                this.submitBtnDisabled = true;
                if (this.additionalData) {
                    this.question.message = this.appendAdditionalData(this.question.message);
                }
                axios
                    .post(process.env.VUE_APP_LIVEDIRECTOR + 'ask-question', {
                        broadcastSlug: this.storeConfig.broadcastId,
                        question: this.question,
                        identifiers: this.storeConfig.identifiers
                    })
                    .then(() => {
                        this.submitBtnDisabled = false;
                        this.$emit('submitted');
                        this.question.message = '';
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            Object.keys(this.errors).forEach(key => {
                                this.errors[key] = error.response.data.indexOf(key) !== -1;
                            });
                        }
                        this.submitBtnDisabled = false;
                    });
            },
            resetError(field) {
                this.errors[field] = false;
            }
        }
    };
</script>

<style>
.is-processing {
    color: transparent !important;
}

.qld-form-group.mobile {
    justify-content: center;
}
</style>