<template>
    <div v-if="customCtaButtonEnabled"
         class="custom-cta-container"
         :class="{'preview': isInPreviewMode}"
    >
        <v-btn class="custom-cta"
               :class="{mobile: isMobile}"
               :style="buttonShadowColorStyle"
               :disabled="loading"
               :color="color"
               @click="navigate"
        >
            <span class="custom-cta-content">
                {{ customCtaButtonTitle }}
            </span>
        </v-btn>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'CustomCtaButton',
        data() {
            return {
                loading: false,
            };
        },
        props: {
            color: {
                type: String,
                default: () => null,
            }
        },
        computed: {
            ...mapGetters('project',[
                'customCtaButtonEnabled',
                'customCtaButtonTitle',
                'customCtaButtonHref',
                'buttonShadowColorStyle',
                'isInPreviewMode',
            ]),
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown;
            }
        },
        methods: {
            ...mapActions('project', ['registerAction']),
            navigate() {
                this.loading = true;
                this.registerAction('custom-cta-button-click');

                setTimeout(() => {
                    this.loading = false;
                    window.open(this.customCtaButtonHref, 'blank');
                }, 300);
            }
        }
    };
</script>

<style scoped>
.custom-cta-container {
    text-align: center;
}

:not(.in-video) {
    .custom-cta {
        height: 46px !important;
        box-shadow: 0 8px 16px var(--button-shadow-color);

        &.mobile {
            font-size: 12px;
            height: 36px !important;
        }
    }
}
</style>

<style lang="scss">
.live-sidebar,
.end-page-side-bar {
    .custom-cta.theme--light.v-btn.v-btn--has-bg {
        background: var(--button-background);
        .custom-cta-content {
            color: var(--button-text-color);
        }
    }
}
</style>