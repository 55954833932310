<template>
    <v-card class="header d-flex rounded-0"
            :class="{'header-pwa': isRegisteredForPwa || isMobile}"
            :color="isRegisteredForPwa ? secondaryColor: ''"
    >
        <div class="logo-container">
            <img :src="logo"
                 class="company-logo"
                 :class="{'pwa': isRegisteredForPwa || isMobile}"
                 alt="Logo"
            >
        </div>
        <div v-if="!registrationPage && !isRegisteredForPwa">
            <div v-if="!isMobile">
                <privacy-policy-link class="support-link text-decoration-none" />
                <support-link class="support-link text-decoration-none" />
            </div>
            <div v-else
                 class="floating-support"
            >
                <v-menu>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               fab
                               rounded
                               v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item ripple>
                            <privacy-policy-link class="support-link text-decoration-none" />
                        </v-list-item>
                        <v-list-item ripple>
                            <support-link class="support-link text-decoration-none" />
                        </v-list-item>
                        <v-list-item v-if="springerLook && isMobile"
                                     ripple
                        >
                            <a href="https://www.mednet.nl/algemene-voorwaarden/"
                               target="_blank"
                               class="support-link text-decoration-none"
                            >{{ $t('termsAndConditions.title') }}</a>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
    </v-card>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import PrivacyPolicyLink from '@/components/controls/help/PrivacyPolicyLink.vue';
    import SupportLink from '@/components/controls/help/SupportLink.vue';

    export default {
        name: 'DefaultHeader',
        components: {SupportLink, PrivacyPolicyLink},
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['logo', 'secondaryColor', 'springerLook']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            registrationPage() {
                return this.$route.name === 'Registration' || this.$route.name === 'RegistrationConfirmation';
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown || /iPhone|iPad/i.test(navigator.userAgent);
            },
        }
    };
</script>

<style scoped lang="scss">
.header {
  height: 64px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08) !important;

    &.header-pwa {
        justify-content: center;
    }
}

.company-logo {
  max-height: 54px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 30px;
  @media (max-width: 960px) {
    margin-left: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
    max-height: 27px !important;
  }

  &.pwa {
      margin: 0 !important;
      display: flex;
      align-content: center;
      justify-content: center;
  }
}

.support-link {
  color: #3E3E3E;
  margin-top: 21px;
  margin-bottom: 21px;
  margin-right: 30px;
  cursor: pointer;
  @media (max-width: 960px) {
    margin-right: 20px;
    margin-top: 22.5px;
    margin-bottom: 22.5px;
  }
}

.v-list-item {
    .support-link {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
}

.floating-support {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
}

</style>
