<template>
    <div
        class="align-center"
        :class="{
            'no-flex': isMobile,
        }"
    >
        <h5
            v-if="showCountDown"
            class="webinar-start-title-dialog"
        >
            {{ $t("countdown.text") }}
        </h5>
        <div v-if="showCountDown"
             class="countdown-outer"
             :class="classes"
        >
            <div class="countdown-item">
                <div class="value">
                    {{ days }}
                </div>
                <div class="text days">
                    {{ $t("countdown.days") }}
                </div>
            </div>
            <v-divider vertical
                       class="countdown-separator"
            />
            <div class="countdown-item">
                <div class="value">
                    {{ leadingZero(hours) }}
                </div>
                <div class="text hours">
                    {{ $t("countdown.hours") }}
                </div>
            </div>
            <v-divider vertical
                       class="countdown-separator"
            />
            <div class="countdown-item">
                <div class="value">
                    {{ leadingZero(minutes) }}
                </div>
                <div class="text minutes">
                    {{ $t("countdown.minutes") }}
                </div>
            </div>
            <v-divider vertical
                       class="countdown-separator"
            />
            <div class="countdown-item">
                <div class="value">
                    {{ leadingZero(seconds) }}
                </div>
                <div class="text seconds">
                    {{ $t("countdown.seconds") }}
                </div>
            </div>
        </div>
        <div v-else
             :class="classes"
        >
            <div
                v-if="showLoadingAfterCountDown"
                class="white--text expiration-message"
            >
                {{ $t("countdown.is_about_to_start") }}
                <div class="text-center mt-4">
                    <v-progress-circular indeterminate />
                </div>
            </div>
            <div v-else
                 class="white--text status-message"
            >
                {{ eventStatus }}
            </div>
        </div>
    </div>
</template>

<script>
    import { timeUntilReactive } from '@/services/AgendaService';
    import {
        ROUTE_EVENT_WITH_PERSONAL_ID,
    } from '@/router/constants';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'CountDown',
        props: {
            value: {
                type: Date,
                default: () => new Date(),
            },
        },
        data() {
            return {
                days: null,
                hours: null,
                minutes: null,
                seconds: null,
                diff: null,
            };
        },
        computed: {
            ...mapState('time', ['currentTime']),
            ...mapGetters('project', ['currentEvent']),
            isStarting() {
                return this.diff < 60;
            },
            endTime() {
                return (
                    this.currentEvent.startTime + 60 * this.currentEvent.durationInMinutes
                );
            },
            ended() {
                return this.endTime <= this.currentTime;
            },
            now() {
                return (
                    this.currentEvent.startTime <= this.currentTime &&
                    this.currentTime < this.endTime
                );
            },
            eventStatus() {
                return this.ended ? this.$t('countdown.ended') : this.$t('countdown.now');
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            classes() {
                return {
                    countdown: !this.isMobile,
                    mobile: this.isMobile,
                    'full-width': this.isStarting,
                };
            },
            showCountDown() {
                return !this.isStarting;
            },
            showLoadingAfterCountDown() {
                return this.$route.name === ROUTE_EVENT_WITH_PERSONAL_ID;
            },
        },
        methods: {
            leadingZero(value) {
                let s = `${value}`;
                if (s.length < 2) {
                    s = `0${s}`;
                }
                return s;
            },
        },
        watch: {
            currentTime: {
                immediate: true,
                handler() {
                    const until = timeUntilReactive(this.value.getTime(), this.currentTime);
                    this.diff = until.diff;
                    this.days = until.days < 0 ? 0 : until.days;
                    this.hours = until.days < 0 ? 0 : until.hours;
                    this.minutes = until.days < 0 ? 0 : until.minutes;
                    this.seconds = until.days < 0 ? 0 : until.seconds;
                },
            },
        },
    };
</script>

<style scoped lang="scss">
.expiration-message {
  font-size: 18px;
  width: 100%;
}

.status-message {
  display: flex;
  align-items: center;
  font-size: 24px;
  width: 100%;
  min-height: 113px;
}

.countdown-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  padding: 0;
  width: 425px;
  height: 78px;
  left: 216px;
  top: 0;

  .countdown-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: static;
    width: 88px;
    height: 78px;
    left: 337px;
    top: 0;

    .value {
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      font-size: 42px;
      line-height: 48px;
      @media (max-width: 1366px) and (min-width: 960px) {
        font-size: 36px !important;
        line-height: 40px !important;
      }
    }

    .text {
      color: #ffffff;
      position: static;
      left: 0;
      height: 28px;
      top: 50px;
      margin: 0 0;
      font-size: 23px;
      text-align: center;
      flex: none;
      order: 1;
      flex-grow: 0;
      @media (max-width: 1366px) and (min-width: 960px) {
        font-size: 16px !important;
      }
    }
  }

  .countdown-separator {
    position: static;
    opacity: 0.4;
    border: 1px solid #ffffff;
    flex: none;
    flex-grow: 0;
    width: 0;
    left: 74px;
    top: 4px;
    margin: 0 24px;

    @media (max-width: 1366px) and (min-width: 960px) {
      max-height: 45px !important;
    }
  }

  @media (max-width: 1227px) and (min-width: 960px) {
    width: 370px;
  }

  &.mobile {
    flex-grow: 0;
    flex: none;
    order: 1;
    width: 290px;
    height: 57px;
    left: 0;
    top: 31px;
    margin-left: -6px;

    .countdown-separator {
      height: 50px;
      left: 140px;
      top: 3.5px;
      margin: 0 8px;
    }

    .countdown-item {
      .value {
        height: 34px;
        font-size: 28px;
      }

      .text {
        height: 23px;
        top: 34px;
        font-size: 18px;
        margin: 0;
        opacity: 0.7;
      }
    }
  }
}

.no-flex {
    display: block !important;
}
</style>

<style>
 .count-down-block.no-flex {
    display: flex !important;
   }

</style>