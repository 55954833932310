export default {
    install(Vue) {
        Vue.mixin({
            mounted() {
                if (typeof (window.executeComponent) === 'function') {
                    window.executeComponent(this, this.$options.name);
                }
            }
        });
    }
};