<template>
    <v-dialog
        scrollable
        max-width="700"
        :value="open"
        @click:outside.stop="close"
    >
        <v-card class="privacy-policy-card"
                :style="fontFamilyStyle"
        >
            <v-card-title>
                <h1 class="font-weight-regular mt-2 mb-2">
                    {{ $t('privacyPolicy.title') }}
                </h1>
                <v-spacer />
                <v-btn icon
                       class="mb-2"
                       :to="hashLessRoute"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <privacy-policy />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import PrivacyPolicy from '@/components/controls/PrivacyPolicy.vue';


    export default {
        name: 'PrivacyPolicyModal',
        components: {PrivacyPolicy},
        props: {
            open: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['darkenPrimaryColorStyle', 'fontFamilyStyle']),
            hashLessRoute() {
                return {
                    name: this.$route.name,
                    params: this.$route.params,
                    meta: this.$route.meta,
                    query: this.$route.query
                };
            },
        },
        methods: {
            close() {
                this.$router.push(this.hashLessRoute);
            }
        }
    };
</script>

<style scoped lang="scss">
h1 {
    font-size: 33px;
}
.privacy-policy-card{
    font-family: var(--font-family);
    ::-webkit-scrollbar-thumb {
        background: #201f1f4b;
    }
}
</style>

<style>

.contents a {
    color: var(--darken-primary-color) !important;
}
</style>