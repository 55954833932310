<template>
    <div class="registration-link ma-4">
        <p class="mb-2"
           style="color: #3e3e3e"
        >
            {{ $t('loginPage.registrationLinkInstruction') }}
        </p>
        <v-btn 
            :color="darkenPrimaryColor"
            :href="registrationPageApp"
            width="120"
            :style="textColorOnDarkenPrimaryColor"
            class="registration-button"
        >
            {{ $t('loginPage.registrationButtonText') }}
        </v-btn>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import {ROUTE_REGISTRATION} from '@/router/constants';

    export default {
        name: 'RegistrationLink',
        computed: {
            ...mapGetters('project', [
                'textColorOnDarkenPrimaryColor',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'primaryColorRGB',
                'currentEvent'
            ]),
            registrationPageApp() {
                return this.$router.resolve({
                    name: ROUTE_REGISTRATION,
                    params: {
                        event_id: this.currentEvent._id
                    },
                    query: {
                        pwa: true
                    }
                }).href;
            }
        }
    };
</script>

<style scoped lang="scss">
.registration-button{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    padding: 10px 32px;
    color: var(--text-color-on-darken) !important;
}
</style>