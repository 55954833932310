<template>
    <footer class="footer">
        <img v-if="springerLook && !isMobile"
             class="logo"
             src="/img/springerhealthcarelogo.png"
             alt="logo"
        >
        <a v-if="springerLook && !isMobile"
           href="https://www.mednet.nl/algemene-voorwaarden/"
           target="_blank"
        >{{ $t('termsAndConditions.title') }}</a>
    </footer>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'DefaultFooter',
        computed: {
            ...mapGetters('project', ['springerLook']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            }
        },
    };
</script>

<style scoped lang="scss">
.footer {
  text-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  a {
    color: #ffffff;
    line-height: 42px;
    &:last-of-type {
      margin-left: 10px;
    }
  }
  img {
    max-height: 40px;
    @media (max-width: 480px) {
      height: auto;
      width: 165px;
      margin: 0 auto;
    }
  }
}
</style>
