import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import { get } from 'lodash';

const LiveDirectorService = class {
    constructor(broadCastSlug, registrationID, registrationData, isPwaUser) {
        this.broadcastSlug = broadCastSlug;
        this.registrationID = registrationID;
        this.isPwaUser = isPwaUser;
        this.name = '';
        this.email = '';
        if (!get(registrationData, 'GENERATED')) {
            this.email = get(registrationData, 'email', '');
            this.name = `${get(registrationData, 'first_name', '')} ${get(registrationData, 'last_name', '')}`.trim();
        }
    }

    includeLibrary() {
        const liveDirectorScript = document.createElement('script');
        liveDirectorScript.type = 'application/javascript';
        liveDirectorScript.src = process.env.VUE_APP_LIVEDIRECTOR_LIB;
        document.head.appendChild(liveDirectorScript);
    }

    load() {
        return new Promise((resolve, reject) => {
            // existing liveDirector instance
            if (window.LiveDirector) {
                return resolve();
            }

            let attempts = 0;
            this.includeLibrary();

            const t = setInterval(() => {
                if (attempts > 10) {
                    clearInterval(t);
                    return reject('Unable to load LiveDirector library');
                }

                if (!window.LiveDirector) {
                    ++attempts;
                } else {
                    Vue.use(window.LiveDirector, { store });
                    clearInterval(t);
                    return resolve();
                }
            }, 400);
        });
    }

    init() {
        return this.load().then(() => {
            return window.LiveDirector.ready(Object.assign({
                broadcastId: this.broadcastSlug,
                identifiers: {
                    name: this.name,
                    registration_id: this.registrationID,
                    email: this.email
                },
                metadata: {
                    pwa_user: this.isPwaUser,
                }
            }, this.extraLiveDirectorConfig()));
        });
    }

    extraLiveDirectorConfig() {
        try {
            var extraLiveDrectorConfigBase64 = router.currentRoute.query.extraLiveDrectorConfig;
            var extraLiveDrectorConfigJSONString = atob(extraLiveDrectorConfigBase64);
            return JSON.parse(extraLiveDrectorConfigJSONString);
        } catch {
            return {};
        }
    }
};

export default LiveDirectorService;
