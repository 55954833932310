<template>
    <div class="field-dropdown-open-answer">
        <v-select
            v-model="model"
            :placeholder="$t('fields.choose_answer')"
            :items="options"
            item-value="id"
            item-text="text"
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            :color="color"
            outlined
            class="dropdown-field-open"
            :background-color="backgroundColor"
        />
        <v-text-field
            v-if="model == openAnswerId"
            v-model="openAnswer"
            type="text"
            :required="required"
            :placeholder="$t('fields.enter_answer')"
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            outlined
            :color="color"
            :background-color="backgroundColor"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldDropdownOpenAnswer',
        props: {
            value: {
                type: String,
                default: () => '',
            },
            label:{
                type: String,
                default: () => ''
            },
            color:{
                type: String,
                default: () => ''
            },
            options: {
                type: Array,
                default: () => [],
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                openAnswerId: this.options[this.options.length - 1].id,
                openAnswer: '',
                model: this.value,
            };
        },
        watch: {
            model(value) {
                let answer = {};
                if (value === this.openAnswerId) {
                    answer = {
                        id: this.model,
                        openAnswer: this.openAnswer,
                    };
                } else {
                    answer = {
                        id: this.model,
                        openAnswer: '',
                    };
                }
                this.$emit('input', answer);
            },
            openAnswer(value) {
                const answer = {
                    id: this.model,
                    openAnswer: value,
                };
                this.$emit('input', answer);
            },
        },
    };
</script>

<style lang="scss">
.field-dropdown-open-answer{
    .v-input{
        border-radius: var(--input-border-radius);
    }
}
.dropdown-field-open {
    i{
        color: #3E3E3E !important;
        bottom: 4px !important;
        right: -8px !important
    }
}

</style>
