<template>
    <on-demand :registration-id="registrationId" />
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {ROUTE_ONDEMAND_PAGE, ROUTE_REGISTRATION} from '@/router/constants';
    import OnDemand from '@/components/templates/default/EventPage/OnDemand';

    export default {
        name: 'OndemandWithRegistration',
        components: {OnDemand},
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['requiresRegistrationForOnDemand', 'currentEvent']),
            ...mapGetters('registration', ['registrationId']),
            isRegistered() {
                return !!this.registrationId;
            }
        },
        mounted() {
            if (!this.requiresRegistrationForOnDemand) {
                this.$router.push({
                    name: ROUTE_ONDEMAND_PAGE,
                    params: {
                        event_id: this.currentEvent._id
                    }
                });
            } else {
                if (!this.isRegistered) {
                    this.$router.push({
                        name: ROUTE_REGISTRATION,
                        params: {
                            event_id: this.currentEvent._id,
                        },
                        query: {
                            redirectToPersonalOnDemand: true,
                        }
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>