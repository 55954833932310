<template>
    <component
        :is="componentType"
        class="agenda-event-status ml-4"
        :event="event"
        :style="{...darkenPrimaryColorStyle,...textColorStyle,...style}"
        :class="classes"
        :start-time="startTime"
        :now="now"
        :ended="ended"
        :is-mobile="isMobile"
        :is-current-event="isCurrentEvent"
        :is-registered="isRegistered"
        :on-demand-active="onDemandActive"
    />
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'AgendaStatusEvent',
        components: {
            AgendaStatusBreakout: () => import('./AgendaStatusBreakout.vue'),
            AgendaStatusSession: () => import('./AgendaStatusSession.vue'),
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            forceMobileView: {
                type: Boolean,
                default: () => false,
            },
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapState('time', ['currentTime']),
            ...mapGetters('project', [
                'isInPreviewMode',
                'currentEvent',
                'darkenPrimaryColorStyle',
                'textColorStyle',
                'labelBorderRadiusStyle'
            ]),
            ...mapGetters('registration', ['isRegisteredForEvent', 'registrationId',]),
            componentType() {
                return this.isBreakout ? 'AgendaStatusBreakout' : 'AgendaStatusSession';
            },
            style(){
                return {
                    ...this.labelBorderRadiusStyle,
                };
            },
            startTime() {
                return this.event.startTime;
            },
            endTime() {
                return (
                    this.event.startTime + (60 * this.event.durationInMinutes)
                );
            },
            ended() {
                return this.endTime <= this.currentTime;
            },
            now() {
                return (
                    this.startTime <= this.currentTime && this.currentTime < this.endTime
                );
            },
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.forceMobileView;
            },
            isRegistered() {
                return this.isRegisteredForEvent(this.event._id);
            },
            isBreakout() {
                return (
                    !!this.event.breakout_group &&
                    typeof this.event.breakouts === 'undefined'
                );
            },
            onDemandActive(){
                return this.event.onDemandActive ? true : false;
            },
            isCurrentEvent(){
                return this.event._id === this.currentEvent._id;
            },
            classes() {
                return {
                    active:  (this.now && !this.isCurrentEvent) || (!this.isRegistered && this.isBreakout && !this.ended) || this.onDemandActive,
                    mobile: this.isMobile,
                    preview : this.isInPreviewMode
                };
            },
        },
    };
</script>

<style scoped lang="scss">
.template-default:not(.pwa) label.agenda-event-status {
  font-size: 12px;
  line-height: 24px;
  font-weight: 900;
  padding: 0 12px;
  height: 24px;
  background: rgba(226, 226, 226, 0.5);
  display: flex;
  order: 3;
  justify-content: center;
  width: 83px;
  border-radius: var(--label-border-radius);


  &.mobile {
    padding: 4px 12px !important;
    font-size: 10px !important;
    height: 20px;
    line-height: 12px;
    margin-top: 2px;
    width: 83px;
  }

  &.active {
    background-color: var(--darken-primary-color) !important;
    color: var(--text-color);
    justify-content: center;
    opacity: 1 !important;
  }

    &.checkmark{
        background: rgba(226, 226, 226, 0.5) !important;
    }

  &.preview{
    pointer-events: none;
  }
}
</style>
