<template>
    <v-btn
        v-show="showEvaluationButton"
        class="evaluation-button px-4"
        :class="{ mobile: isMobile}"
        :style="buttonShadowColorStyle"
        :disabled="loading"
        @click="toEvaluationPage"
    >
        <span class="evaluation-button-content">
            {{ text }}
        </span>
    </v-btn>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';

    export default {
        name: 'EvaluationButton',
        props: {
            blank: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapGetters('project', ['currentEvent', 'overwriteEvaluationButton', 'evaluationButtonTitle', 'evaluationButtonHref', 'buttonShadowColorStyle']),
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showEvaluationButton() {
                return this.currentEvent.evaluation.fields.length > 0 || this.overwriteEvaluationButton;
            },
            text() {
                return this.overwriteEvaluationButton 
                    ? this.evaluationButtonTitle
                    : this.$t('event.evaluation_button');
            }
        },
        methods: {
            ...mapActions('project', ['registerAction']),
            toEvaluationPage() {
                this.overwriteEvaluationButton 
                    ? this.toExternalEvaluationPage()
                    : this.toInternalEvaluationPage();
            },
            toInternalEvaluationPage() {
                if (this.blank) {
                    let evaluationPage = this.$router.resolve({ name: 'Evaluation' });
                    const evaluationPageQuery = this.isRegisteredForPwa ? '?pwa=true' : '';
                    window.open(evaluationPage.href + evaluationPageQuery, '_blank');
                } else {
                    this.$router.push({ name: 'Evaluation' });
                }
            },
            toExternalEvaluationPage() {
                this.loading = true;
                this.registerAction('custom-evaluation-button-click');
                const target = this.blank ? '_blank' : '_self';

                setTimeout(() => {
                    this.loading = false;
                    window.open(this.evaluationButtonHref, target);
                }, 300);

            },
        },
    };
</script>

<style lang="scss">
.evaluation-button:not(.in-video) {
    height: 46px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    background: #ffffff;
    box-shadow: 0 8px 16px var(--button-shadow-color);

    .evaluation-button-content {
        color: #3e3e3e !important;
    }
}

.live-sidebar,
.end-page-side-bar {
    .evaluation-button.theme--light.v-btn.v-btn--has-bg {
        background: var(--button-background);
        .evaluation-button-content {
            color: var(--button-text-color) !important;
        }
    }
}
</style>
