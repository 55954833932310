<template>
    <v-dialog v-model="open"
              max-width="840"
              persistent
    >
        <v-card class="auto-navigation-modal"
                :style="{...darkenPrimaryColorStyle, ...textColorStyle, ...fontFamilyStyle, ...buttonBorderRadiusStyle}"
        >
            <v-card-title class="d-flex justify-center">
                <h5 class="auto-navigation-title text-center">
                    {{ $t('multiSession.autoNavigationTitle') }}
                </h5>
            </v-card-title>
            <v-divider class="divider"
                       :style="{'color': darkenPrimaryColor}"
            />

            <v-card-text class="py-6">
                <div class="countdown-content">
                    <p class="font-weight-regular my-5 countdown-info"
                       v-html="$t('multiSession.autoNavigationText').replace('{{countdownSeconds}}', countdownSeconds).replace('{{session}}', nextEvent.name)"
                    />
                    <div :class="{'px-15': !isMobile}">
                        <v-progress-linear
                            v-model="value"
                            :color="darkenPrimaryColor"
                        />
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center pb-5 pt-5">
                <v-btn :color="darkenPrimaryColor"
                       class="navigate-next-button"
                       @click="navigate"
                >
                    {{ $t('multiSession.autoNavigationNavigateNowButton') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import {ROUTE_EVENT_WITH_PERSONAL_ID} from '@/router/constants';
    import {minBy} from 'lodash';
    import {furtherEvents} from '@/services/FurtherEvents';

    export default {
        name: 'AutoNavigation',
        data() {
            return {
                open: true,
                countdownSeconds: 30,
                now: null,
                interval: null,
                value: 0
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId']),
            ...mapGetters('project', [
                'currentEvent', 
                'primaryColor', 
                'darkenPrimaryColorStyle', 
                'darkenPrimaryColor', 
                'textColorStyle',
                'fontFamilyStyle',
                'buttonBorderRadiusStyle'
            ]),
            ...mapGetters('registration', ['registeredEvents']),
            nextEvent() {
                const nextEvent = this.furtherEvents.find(e => e !== undefined);
                if (!nextEvent) {
                    return this.project.events[this.project.events.length - 1];
                }
                return nextEvent;
            },
            furtherEvents() {
                return furtherEvents(this.project.events, this.currentEvent, this.now, this.registeredEvents);
            },
            hasFurtherEvents() {
                return this.furtherEvents.length > 0;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods: {
            navigate() {
                clearInterval(this.interval);
                if (!this.hasFurtherEvents) {
                    if(this.currentEvent._id === this.project.events[this.project.events.length - 1]._id) {
                        return;
                    }
                    this.goToEvent(this.nextEvent._id);
                    return;
                }
                const nextEvent = minBy(this.furtherEvents, 'startTime');
                this.goToEvent(nextEvent._id);
            },
            updateTimers() {
                this.countdownSeconds--;
                this.value = this.value + 100/30;
                this.now = Math.floor(Date.now() / 1000);
                this.open = this.hasFurtherEvents;
            },
            goToEvent(eventId) {
                const route = this.$router.resolve({
                    name: ROUTE_EVENT_WITH_PERSONAL_ID,
                    params: {
                        event_id: eventId,
                        registration_id: this.registrationId,
                    },
                    query: this.$route.query,
                });
                window.open(route.href, '_self');
            }
        },
        created() {
            this.updateTimers();
            this.interval = setInterval(() => {
                this.updateTimers();
                if (this.countdownSeconds <= 0) {
                    return this.navigate();
                }
            }, 1000);
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
    };
</script>

<style lang="scss">
.auto-navigation-modal{
    .auto-navigation-title {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        color: #3e3e3e;
        color: var(--darken-primary-color);
        text-align: center;
    }

    .countdown-info {
        font-family: var(--font-family);
        font-size: 18px;
        text-align: center;
        color: #3e3e3e;
    }

    .countdown-button-holder {
        text-align: center;
    }

    .countdown-wrapper {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
        color: #3e3e3e;
        display: flex;
        justify-content: center;
    }

    .navigate-next-button .v-btn__content {
        color: var(--text-color) !important;
        font-family: var(--font-family);
    }

    .navigate-next-button{
        border-radius: var(--button-border-radius) !important;
    }
}
</style>
