<template>
    <div class="fullHeight mx-0">
        <span>Requesting token...</span>
    </div>
</template>

<script>
    import axios from 'axios';
    import { ROUTE_REGISTRATION } from '@/router/constants';
    import {mapActions, mapMutations} from 'vuex';

    export default {
        methods: {
            ...mapMutations('registration', ['setPrefill']),
            ...mapActions('project', ['getProject']),
        },
        async created() {
            await this.getProject(window.sessionStorage.getItem('event_id'));
            if (this.$route.query.state !== window.sessionStorage.getItem('state')) {
                console.error('failed to log in! incorrect state.');
                return;
            }

            try {
                const profileResponse = await axios.post(
                    process.env.VUE_APP_LINKEDIN_ACCESS_TOKEN_URL,
                    {
                        code: this.$route.query.code,
                    }
                );
                this.setPrefill({
                    email: profileResponse.data.email,
                    firstName: profileResponse.data.firstName,
                    lastName: profileResponse.data.lastName,
                });
            } catch (e) {
                console.error('failed to log in! could not fetch profile.');
            }

            this.$router.push({
                name: ROUTE_REGISTRATION,
                params: { event_id: window.sessionStorage.getItem('event_id') },
                query: { linkedin: 'true' },
            });
        },
    };
</script>
