<template>
    <div ref="imageContentBlock"
         class="image-content-block"
         :class="classes"
         :style="styles"
    >
        <div
            class="image"
            :style="{
                height: `${height}px`,
                'background': `url(${options.image.url})`,
            }"
        />
        <div class="content">
            <div 
                v-html="content"
            />
            <v-btn v-if="ctaButtonEnabled"
                   :color="darkenPrimaryColor"
                   class="cta-button mt-5"
                   :href="options.ctaButton.ctaButtonUrl"
                   target="_blank"
                   :style="{...textColorStyle, 'align-self': options.ctaButton.alignment}"
            >
                {{ options.ctaButton.ctaButtonText }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { tagSubtitution } from '@/services/ContentService';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'ImageContentBlock',
        props: {
            options: {
                type: Object,
                default: () => {},
            },
            gradient: {
                type: Boolean,
                default: () => false,
            },
            width: {
                type: String,
                default: () => ''
            },
            blockId: {
                type: String,
                default: () => ''
            }
        },
        data() {
            return {
                height: null,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['currentEvent', 'privacyPolicyUrl', 'primaryColorRGB', 'darkenPrimaryColor', 'textColorStyle']),
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                    '--image': `url(${this.options.image.url})`,
                };
            },
            alignmentClass() {
                return {
                    'image-top-full-width': 'image-top-full-width',
                    'image-bottom-full-width': 'image-bottom-full-width',
                    'image-left': 'image-left',
                    'image-top': 'image-top',
                    'image-right': 'image-right',
                    'image-bottom': 'image-bottom',
                }[this.options.alignment];
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            classes() {
                var classes = [this.alignmentClass];
                if (this.isMobile) {
                    classes.push('mobile');
                }
                if (this.gradient) {
                    classes.push('gradient');
                }
                return classes;
            },
            content() {
                if(this.options.content){
                    return tagSubtitution(
                        this.options.content,
                        this.currentEvent,
                        '',
                        this.project.eventTitle,
                        this.privacyPolicyUrl
                    );
                }
                return '';
            },
            ctaButtonEnabled(){
                return this.options?.ctaButton?.enableCtaButton || false;
            }
        },
        mounted() {
            let width = this.$refs.imageContentBlock.offsetWidth;
            if (
                this.alignmentClass !== 'image-top-full-width' &&
                this.alignmentClass !== 'image-bottom-full-width' && 
                !this.isMobile &&
                this.width !== 'half-width'
                
            ) {
                width = width * 0.4;
            }
            const ratio = this.options.image.height / this.options.image.width;
            this.height = ratio * width;
        },
    };
</script>

<style lang="scss">
.image-content-block {
    display: flex;
    align-items: center;
    padding: 60px 60px;
    @media (min-width:1600px) and (max-width: 1800px) {
        padding: 40px 40px;
    }

    @media (min-width:960px) and (max-width: 1600px) {
        padding: 40px 30px;
    }

    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(344.71deg, rgba(51, 51, 51, 0.5) -9.98%, rgba(var(--r), var(--g), var(--b), 0.5) 152%);
    }

    .image {
        flex: 0 1 40%;
        max-width: 40%;
        min-width: 40%;
        background-position: center;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
    .content {
        display: flex;
        flex-direction: column;
        flex: 0 1 60%;
        min-width: 60%;
        max-width: 60%;
        & > *{
            color: var(--block-text-color) !important;
        }
        & > div > *{
            color: var(--block-text-color) !important;
        }

        .cta-button{
            color: var(--text-color) !important;
            min-width: 148px;
            height: 40px;
            font-size: 15px;
        }
    }

    &.image-left {
        .image {
            order: 1;
            background-position: left !important;

        }
        .content {
            order: 2;
            padding-left: 80px
        }
    }

    &.image-top {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .image {
            order: 1;
            min-width: 40%;
            max-width: 40%;
        }

        .content {
            order: 2;
            min-width: 100%;
            max-width: 100%;
        }
    }

    &.image-top-full-width {
        display: grid;
        .image {
            order: 1;
            min-width: 100%;
            max-width: 100%;
        }
        .content {
            order: 2;
            min-width: 100%;
            max-width: 100%;
        }
    }

    &.image-right {
        .image {
            order: 2;
            background-position: right !important;
        }
        .content {
            order: 1;
            padding-right: 80px;
        }
    }

    &.image-bottom {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .image {
            order: 2;
            min-width: 40%;
            max-width: 40%;
        }
        .content {
            order: 1;
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 30px;
        }
    }

    &.image-bottom-full-width {
        display: grid;
        .image {
            order: 2;
            min-width: 100%;
            max-width: 100%;
        }
        .content {
            order: 1;
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 30px;

        }
    }
    &.mobile{
        padding: 35px 16px;

        .cta-button{
            color: var(--text-color);
            min-width: 101px;
            height: 32px;
            font-size: 11px !important;
        }

        &.image-right, &.image-left{
            display: grid;
            gap: 25px;

            .image {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
        }
        &.image-top {
            gap: 25px;
            .image {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
        }

        &.image-bottom{
            gap: 25px;
            .image {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
}

.half-width{
    .image-content-block {
        align-items: center;
        padding: 60px 60px;
        @media (min-width:1600px) and (max-width: 1800px) {
            padding: 40px 50px;
        }

        @media (min-width:960px) and (max-width: 1600px) {
            padding: 20px 30px;
        }
        &.gradient{
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), linear-gradient(130.22deg, rgba(0, 0, 0, 0.3) -16.19%, rgba(var(--r), var(--g), var(--b), 0.3) 52.73%);
        }

        &.image-right, &.image-left{
            display: grid !important;
            gap: 25px;

            .image {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 2;
                min-width: 100%;
                max-width: 100%;
                
            }
        }
        &.image-top, &.image-top-full-width {
            gap: 25px;
            .image {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
        }
        &.image-bottom, &.image-bottom-full-width{
            gap: 25px;
            .image {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 1;
                min-width: 100%;
                max-width: 100%;
            }
        }

        &.mobile{
            padding: 40px 16px;
        }
    }

}
</style>
