<template>
    <div>
        <div v-for="(group, key) in breakoutRounds"
             :key="key"
             class="multi-session-fields"
        >
            <multi-session-breakout-select v-model="model[key]"
                                           :group="group"
            />     
        </div>
        <small v-if="hasError"
               class="error--text"
        >{{ $t('multiSession.breakoutRoundValidationText') }}</small>
    </div>
</template>

<script>
    import MultiSessionBreakoutSelect from '@/components/controls/fields/MultiSessionBreakoutSelect';
    import {mapState} from 'vuex';
    import {groupBy} from 'lodash';
    export default {
        inject: {
            form: {
                default: null
            }
        },
        props:{
            value: {
                type: Array,
                default: () => [],
            },
        },
        components:{
            MultiSessionBreakoutSelect
        },
        data(){
            return {
                hasError: false,
                model: this.buildModel(),
            };
        },
        computed: {
            ...mapState('project', ['project']),
            breakoutRounds() {
                return groupBy(this.project.events.filter(e => !!e.breakout_group), 'breakout_group');
            }
        },
        methods:{
            validate() {
                this.hasError = false;
                for (let group in this.breakoutRounds) {
                    if (!this.model[group]) {
                        this.hasError = true;
                        break;
                    }
                }
                return !this.hasError;
            },
            buildModel() {
                const res = {};
                for (let v in this.value) {
                    const group = this.project.event.filter(e => e._id === v)?.breakout_group;
                    if (group) {
                        res[group] = v;
                    }
                }

                return res;
            }
        },
        watch: {
            model: {
                handler (value) {
                    this.validate();
                    this.$emit('input', Object.values(value));
                },
                deep: true
            }
        },
        created() {
            this.form && this.form.register(this);
        },
        beforeDestroy() {
            this.form && this.form.unregister(this);
        }
    };
</script>

<style lang="scss">
.multi-session-fields{
    margin-bottom: 14px !important;
}
</style>
