<template>
    <div :class="classes"
         class="event-page-player"
         :style="styles"
    >
        <div v-if="hasContent"
             class="content"
             v-html="content"
        />
        <div ref="player-container"
             class="player-container"
        >
            <script
                type="application/javascript"
                data-autoplay="false"
                :src="playerURL"
                data-custom-unmute="true"
                :data-custom-unmute-text="$t('event.unmute_text')"
                :data-video-id="options.onDemandId"
                :data-id="playerId"
            />
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';
    import { tagSubtitution } from '@/services/ContentService';

    export default {
        name: 'VideoContentBlock',
        props: {
            options: {
                type: Object,
                default: () => {},
            },
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        data() {
            return {
                playerId: `player-${uuidv4()}`,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['currentEvent', 'privacyPolicyUrl', 'primaryColorRGB']),
            content() {
                const content = this.options.content || '';
                return tagSubtitution(
                    content,
                    this.currentEvent,
                    '',
                    this.project.eventTitle,
                    this.privacyPolicyUrl
                );
            },
            playerURL() {
                return process.env.VUE_APP_PLAYER_URL;
            },
            alignmentClass() {
                return {
                    'player-top': 'player-top',
                    'player-bottom': 'player-bottom',
                    'player-left': 'player-left',
                    'player-right': 'player-right'
                }[this.options.alignment];
            },
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.xsOnly;
            },
            hasContent() {
                return this.options.content;
            },
            classes() {
                var classes = [this.alignmentClass];
                if (this.isMobile) {
                    classes.push('mobile');
                }
                if(!this.hasContent){
                    classes.push('no-content');
                }
                if (this.gradient) {
                    classes.push('gradient');
                }
                return classes;
            },

        },
        methods: {
            setPlayerContainerHeight() {
                var playerContainer = this.$refs['player-container'];
                const height = playerContainer.offsetWidth / (16 / 9);
                playerContainer.style.height = `${height}px`;
            },
        },
        mounted() {
            this.setPlayerContainerHeight();
        },
    };
</script>

<style  lang="scss" scoped>
.event-page-player {
    display: grid;
    padding: 60px 320px;
    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(124.91deg, rgba(0, 0, 0, 0.5) 10.8%, rgba(var(--r), var(--g), var(--b), 0.5) 64.09%);
    }

    .player-container {
        min-width: 100%;
        max-width: 100%;
    }

    .content {
        min-width: 100%;
        max-width: 100%;
    }

    &.player-bottom {
        .player-container {
            order: 2;
        }
        .content {
            order: 1;
            margin-bottom: 24px;
        }
    }

    &.player-top {
        display: grid;
        .player-container {
            order: 1;
            margin-bottom: 24px
        }
        .content {
            order: 2;
        }
    }

    &.player-right {
        display: flex;
        padding: 115px 60px;
        @media (min-width:1600px) and (max-width: 1800px) {
            padding: 100px 40px;
        }

        @media (min-width:960px) and (max-width: 1600px) {
            padding: 80px 30px;
        }

        .player-container {
            order: 2;
            min-width: 40%;
            max-width: 40%;
        }
        .content {
            order: 1;
            min-width: 60%;
            max-width: 60%;
            padding-right: 80px;
        }
    }

    &.player-left {
        display: flex;
        padding: 115px 60px;
        @media (min-width:1600px) and (max-width: 1800px) {
            padding: 100px 40px;
        }

        @media (min-width:960px) and (max-width: 1600px) {
            padding: 80px 30px;
        }
        .player-container {
            order: 1;
            min-width: 40%;
            max-width: 40%;
        }
        .content {
            order: 2;
            min-width: 60%;
            max-width: 60%;
            padding-left: 80px;
        }
    }

    &.mobile{
        padding: 0;
        &.player-bottom {
            .player-container {
                order: 2;
            }
            .content {
                order: 1;
                margin-bottom: 24px;
                padding: 0 24px;
            }
        }

        &.player-top {
            display: grid;
            .player-container {
                order: 1;
                margin-bottom: 40px;
            }
            .content {
                order: 2;
                padding: 0 24px;
                margin-bottom: 103px;
            }
        }

        &.player-right {
            display: grid;
            .player-container {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 1;
                padding: 0 24px;
                min-width: 100%;
                max-width: 100%;
                margin-bottom: 24px;

            }
        }

        &.player-left {
            display: grid;
            .player-container {
                order: 2;
                min-width: 100%;
                max-width: 100%;
            }
            .content {
                order: 1;
                padding: 0 24px;
                min-width: 100%;
                max-width: 100%;
                margin-bottom: 24px;

            }
        }

        &.no-content{
            .player-container {
                margin: 0;
            }
        }
    }

    &.no-content{
        .player-container{
            margin: 0;
        }
    }
}

.half-width{
    .event-page-player {
        display: grid;
        padding: 0;
        &.gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(124.91deg, rgba(0, 0, 0, 0.5) 10.8%, rgba(var(--r), var(--g), var(--b), 0.5) 64.09%);
        }

        .player-container {
            min-width: 100%;
            max-width: 100%;
        }

        .content {
            min-width: 100%;
            max-width: 100%;
        }

        &.player-bottom {
            .player-container {
                order: 2;
            }
            .content {
                order: 1;
                margin-bottom: 40px;
                margin-top: 40px;
                padding: 0 24px;
            }
        }

        &.player-top {
            display: grid;
            .player-container {
                order: 1;
                margin-bottom: 40px;
            }
            .content {
                order: 2;
                padding: 0 24px;
                margin-bottom: 103px;
            }
        }

        &.no-content{
            &.player-bottom {
                .player-container {
                    order: 2;
                    margin: 0;
                }
            }

            &.player-top {
                display: grid;
                .player-container {
                    order: 1;
                    margin: 0;
                }
            }
        }

    }
}
</style>

<style lang="scss">
.event-page-player{
    .content > *{
        color: var(--block-text-color) !important;
    }
}
</style>
