<template>
    <div class="input-wrapper">
        <v-card
            rounded="1"
            width="650"
        >
            <v-card-title class="title justify-center text-center">
                {{ attendance ?
                    $t('registration.change_attendance_title_online') :
                    seats
                }}
            </v-card-title>
            <v-divider />
            <v-card-text class="py-8 d-flex flex-column align-center">
                <span class="information">                
                    {{ $t('registration.change_attendance_information') }}
                </span>
                <v-btn
                    :color="darkenPrimaryColor"
                    class="change-button"
                    :loading="loading"
                    :disabled="!enableAttendanceChange"
                    :style="textColorStyle"
                    @click="changeAttendance"
                >
                    {{ $t('registration.change_attendance_button') }}
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import {$http} from '@/services/http';
    import {ROUTE_UNREGISTER} from '@/router/constants';
    import {toastSuccess} from '@/services/responses';


    export default {
        name: 'ChangeAttendacePage',
        data(){
            return {
                attendance: null,
                loading: false,
                onSiteViewers: 0,
                disabled: false
            };
        },
        computed:{
            ...mapGetters('project', ['darkenPrimaryColor', 'textColorStyle', 'darkenPrimaryColorStyle', 'expectedOnSiteViewers']),
            ...mapState('registration', ['registrationData']),
            ...mapState('project', ['project']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            remainingSeats() {
                return this.expectedOnSiteViewers - this.onSiteViewers;
            },
            enableAttendanceChange() {
                if(this.disabled){
                    return false;
                }
                if (this.registrationData.on_site_viewer) {
                    return true;
                }
                return this.remainingSeats > 0;
            },
            seats(){
                if(this.remainingSeats === 1){
                    return this.$t('registration.change_attendance_title_on_site_one_seat');
                } else{
                    return this.$t('registration.change_attendance_title_on_site').replace('{$x}', this.remainingSeats);
                }
            }
        },
        methods:{
            ...mapActions('registration', ['updateAttendanceAndSendMail']),
            async changeAttendance(){
                this.loading = true;
                await this.updateAttendanceAndSendMail(!this.attendance);
                this.attendance = !this.attendance;
                const feedbackMessage = this.attendance ?
                    this.$t('registration.attendance_changed_to_on_site') :
                    this.$t('registration.attendance_changed_to_online');
                toastSuccess(feedbackMessage, 5000);
                this.loading = false;
                this.disabled = true;
                setTimeout(() => {
                    this.disabled = false;
                }, 5000);
            },
            async refreshOnSiteViewers() {
                const {data} = await $http.get(process.env.VUE_APP_REQUEST_REGISTRATION_COUNT_ONSITE_VIEWERS
                    .replace('{projectID}', this.project._id));
                this.onSiteViewers = data.onSiteViewers;
            },
            clearTimer() {
                if (this.$options.refreshInterval) {
                    clearInterval(this.$options.refreshInterval);
                }
            }
        },
        async mounted() {
            if(!this.registrationData || this.project.projectType !== 'hybrid'){
                this.$router.push({name: ROUTE_UNREGISTER});
                return;
            }
            this.attendance = this.registrationData.on_site_viewer;
            this.refreshOnSiteViewers();
            this.$options.refreshInterval = setInterval(this.refreshOnSiteViewers, 2500);
        },
        beforeDestroy() {
            this.clearTimer();
        }
    };
</script>

<style lang="scss" scoped>
.input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .title{
        word-break: break-all;
    }

    .information{
        font-size: 16px;
        margin-bottom: 24px;
        text-align: center;
    }
}
</style>

<style lang="scss">
.template-default{
    .change-button{
        border-radius: 4px;
        .v-btn__content {
            color: var(--text-color) !important;
        }
    }
}
</style>
