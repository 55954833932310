import store from '@/store/index';

/**
 * Use this for errors
 * @param message
 */
export function toastError(message, timeout) {
    toastMessage(message, 'error', timeout || 3000);
}

/**
 * Use this for success
 * @param message
 */
export function toastSuccess(message, timeout) {
    toastMessage(message, 'success', timeout || 3000);
}

/**
 * Use this for info
 * @param message
 */
export function toastInfo(message, timeout) {
    toastMessage(message, 'info', timeout || 3000);
}

/**
 * Use this for generic messages
 * @param message
 */
export function toast(message) {
    toastMessage(message, 'grey', 2000);
}

/**
 * Use this for customized messages (custom type and custom timeout (default: no timeout))
 * @param message
 * @param type // default: 'grey' - generic
 * @param timeout // default: no timeout
 */
export function toastMessage(message, type = 'med-grey-1', timeout = -1) {
    store.commit('snackbar/hideMessage');
    store.commit('snackbar/showMessage', {
        content: message,
        type: type,
        timeout: timeout
    });
}
