<template>
    <div class="field-text d-flex align-baseline">
        <v-combobox v-model="day"
                    outlined
                    class="dropdown-field date-picker-field"
                    :menu-props="{contentClass: 'should-wrap'}"
                    :rules="dayRules"
                    :color="color"
                    :background-color="backgroundColor"
                    :placeholder="$t('calendar.dayPlaceholder')"
                    :items="dayItems"
                    item-value="key"
                    item-text="label"
        />-
        <v-combobox v-model="month"
                    outlined
                    class="dropdown-field date-picker-field"
                    :menu-props="{contentClass: 'should-wrap'}"
                    :rules="monthRules"
                    :color="color"
                    :background-color="backgroundColor"
                    :placeholder="$t('calendar.monthPlaceholder')"
                    :items="monthItems"
                    item-value="key"
                    item-text="label"
        />-
        <v-combobox v-model="year"
                    outlined
                    class="dropdown-field date-picker-field"
                    :menu-props="{contentClass: 'should-wrap'}"
                    :rules="yearRules"
                    :color="color"
                    :background-color="backgroundColor"
                    :placeholder="$t('calendar.yearPlaceholder')"
                    :items="yearItems"
                    item-value="key"
                    item-text="label"
                    eager
        />
    </div>
</template>

<script>
    function valueAsString(x) {
        return x + '';
    }

    function getKey(x) {
        const sX = valueAsString(x);
        return sX.length > 1 ? sX : 0 + sX;
    }

    export default {
        name: 'FieldDatePicker',
        props: {
            value: {
                type: String,
                default: () => ''
            },
            color: {
                type: String,
                default: () => ''
            },
            label: {
                type: String,
                default() {
                    return this.$t('fields.enter_answer');
                },
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        computed: {
            splitModel() {
                return this.model?.split('-') ?? ['','',''];
            },
            day: {
                get() {
                    return this.splitModel[0] ?? '';
                },
                set(value) {
                    let newValue = [...this.splitModel];
                    newValue[0] = typeof value === 'string' ? value : value.key;
                    this.model = newValue.join('-');
                }
            },
            month: {
                get() {
                    return this.splitModel[1] ?? '';
                },
                set(value) {
                    let newValue = [...this.splitModel];
                    newValue[1] = typeof value === 'string' ? value : value.key;
                    this.model = newValue.join('-');
                }
            },
            year: {
                get() {
                    return this.splitModel[2] ?? '';
                },
                set(value) {
                    let newValue = [...this.splitModel];
                    newValue[2] = typeof value === 'string' ? value : value.key;
                    this.model = newValue.join('-');
                }
            },
            yearRules() {
                let rules = [];
                if (this.required) {
                    rules.push ((v) => !!v || this.$t('fields.validation.required'));
                }

                rules.push((v => (!this.required && v.length === 0) || v.length === 4 || this.$t('fields.validation.fixedLength').replace('{length}', 4)));
                rules.push((v) => {
                    if (!this.required && v.length === 0) {
                        return true;
                    }
                    const x = parseInt(v);
                    if (isNaN(x)) {
                        return this.$t('fields.validation.notANumber');
                    }
                    const y = parseInt((new Date()).getFullYear());
                    if (x > y  || x < y - 120) {
                        return this.$t('fields.validation.notBetween').replace('{min}', y - 120).replace('{max}', y);
                    }
                    return true;
                });

                return rules;
            },
            monthRules() {
                let rules = [];
                if (this.required) {
                    rules.push ((v) => !!v || this.$t('fields.validation.required'));
                }

                rules.push((v => (!this.required && v.length === 0) || v.length === 2 || this.$t('fields.validation.fixedLength').replace('{length}', 2)));
                rules.push((v) => {
                    if (!this.required && v.length === 0) {
                        return true;
                    }
                    const x = parseInt(v);
                    if (isNaN(x)) {
                        return this.$t('fields.validation.notANumber');
                    }
                    if (x > 12  || x < 1) {
                        return this.$t('fields.validation.notBetween').replace('{min}', 1).replace('{max}', 12);
                    }
                    return true;
                });

                return rules;
            },
            dayRules() {
                let rules = [];
                if (this.required) {
                    rules.push ((v) => !!v || this.$t('fields.validation.required'));
                }
                rules.push((v => (!this.required && v.length === 0) || v.length === 2 || this.$t('fields.validation.fixedLength').replace('{length}', 2)));
                rules.push((v) => {
                    if (!this.required && v.length === 0) {
                        return true;
                    }
                    const x = parseInt(v);
                    if (isNaN(x)) {
                        return this.$t('fields.validation.notANumber');
                    }
                    if (x > 31  || x < 1) {
                        return this.$t('fields.validation.notBetween').replace('{min}', 1).replace('{max}', 31);
                    }
                    return true;
                });

                return rules;
            },
            dayItems() {
                const items = [];
                for (let x = 1; x < 32; x++) {
                    items.push({
                        key: getKey(x),
                        label: getKey(x),
                    });
                }
                return items;
            },
            monthItems() {
                const items = [];
                for (let x = 1; x < 13; x++) {
                    items.push({
                        key: getKey(x),
                        label: getKey(x),
                    });
                }
                return items;
            },
            yearItems() {
                const items = [];
                const lastYear = (new Date()).getFullYear();
                for (let x = lastYear; x > lastYear - 120; x--) {
                    items.push({
                        key: '' + x,
                        label: '' + x,
                    });
                }
                return items;
            }
        },
        watch: {
            model(value) {
                console.log(value);
                this.$emit('input', value);
            },
        },
        created() {
            this.form && this.form.register(this);
        },
        beforeDestroy() {
            this.form && this.form.unregister(this);
        }
    };
</script>

<style lang="scss">
.v-menu__content {
    background-color: #fff;
}

.date-picker-field {
    div[role="combobox"] {
        padding: 3px 12px !important;
        .v-input__append-inner {
            i {
                margin-top: -7px;
            }
        }
    }
}
</style>
