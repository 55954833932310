<template>
    <v-card rounded="0">
        <v-toolbar bottom
                   extended
                   :extension-height="isIOS ? 20 : 0"
        >
            <template v-slot:default>
                <v-tabs
                    :key="renditionKey"
                    v-model="tab"
                    show-arrows
                    hide-slider
                    :style="{...pwaDarkenPrimaryColorStyle, ...secondaryColorStyle}"
                    active-class="active-tab"
                    grow
                    class="nav-tabs elevation-5"
                    :class="{'mobile': isMobile}"
                >
                    <div v-for="(tabSetting) in displayedTabs"
                         :key="tabSetting.name"
                         :class="{'qld-controller-tab': tabSetting.visibility}"
                         :style="tabSizeStyle"
                    >
                        <v-tab v-if="tabSetting.visibility"
                               :to="'#' + tabSetting.name"
                               :class="{'menu-tab-divider': tabSetting.name !== 'home'}"
                        >
                            <v-badge v-if="tabSetting.badge"
                                     :value="tabSetting.active"
                                     right
                                     dot
                                     overlap
                                     :color="pwaDarkenPrimaryColorStyle['--darken-primary-color']"
                                     transition="slide-x-transition"
                            >
                                <v-icon :size="tabSetting.iconSize"
                                        :color="secondaryTextColor"
                                        class="my-2"
                                >
                                    {{ tabSetting.icon }}
                                </v-icon>
                            </v-badge>
                            <v-icon v-else
                                    :size="tabSetting.iconSize"
                                    class="my-2"
                                    :color="secondaryTextColor"
                            >
                                {{ tabSetting.icon }}
                            </v-icon>
                        </v-tab>
                    </div>
                    <v-menu v-if="hiddenTabs.length > 0"
                            v-model="menuOpen"
                            top
                            offset-y
                            nudge-top="30"
                            nudge-left="5"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                :ripple="false"
                                class="align-self-center menu-button rounded-0"
                                :class="{'menu-active': menuOpen}"
                                icon
                                :style="{...pwaDarkenPrimaryColorStyle, ...tabSizeStyle}"
                                :height="isMobile ? 60 : 64"
                                v-on="on"
                            >
                                <v-icon :size="32"
                                        :color="secondaryTextColor"
                                >
                                    mdi-dots-horizontal
                                </v-icon>
                            </v-btn>
                        </template>

                        <v-list class="grey lighten-3">
                            <v-list-item v-for="tab in hiddenTabs"
                                         :key="tab.name"
                                         style="justify-content: center"
                            >
                                <v-tab
                                    :to="'#' + tab.name"
                                >
                                    <span class="mb-2"> {{ tab.label }} </span>
                                </v-tab>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </template>
        </v-toolbar>
    </v-card>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'TabHeaders',
        props: {
            value: {
                type: String,
                required: true,
            }
        },        
        data() {
            return {
                tab: null,
                renditionKey: 0,
                menuOpen:false
            };
        },
        watch: {
            availableTabs() {
                this.selectTab(this.tab);
                this.renditionKey++;
            },
            tab: {
                handler(value) {
                    this.$emit('input', value);
                },
            },
            active(newValue, oldValue){
                if(this.showPollTab && !oldValue && newValue){
                    this.tab = 'poll';
                }
            },
            '$route.hash': {
                handler: function(value){
                    const tab = value.substring(1);
                    const shouldSelectHiddenTab = this.hiddenTabs.some(obj => obj.name === tab);
                    if (shouldSelectHiddenTab) {
                        this.menuOpen = true;
                    }
                    this.selectTab(tab);
                },
                immediate: true,
                deep: true
            }
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('livePage', [
                'isPollVisible',
                'isChatVisible',
                'isQuestionsVisible',
                'isHandoutVisible',
                'isQuizVisible',
            ]),
            ...mapGetters('qld-vuex-store/poll', ['active']),
            ...mapGetters('qld-vuex-store/quiz', ['activeQuestion']),
            ...mapGetters('project', [
                'isMultiSession',
                'customTabTitle',
                'customTabIcon',
                'showCustomTab',
                'currentEvent',
                'pwaDarkenPrimaryColorStyle',
                'secondaryColorStyle',
                'secondaryTextColor',
                'chatType',
            ]),
            tabOrder() {
                if (this.currentEvent.tabOrder.length === 0) {
                    return ['home','agenda', 'question', 'poll', 'chat', 'handout', 'quiz', 'custom', 'support', 'privacy-policy'];
                }
                let result = this.currentEvent.tabOrder;
                result.unshift('home');
                result.push('support');
                result.push('privacy-policy');
                return result;
            },
            showQuizTab() {
                return this.isQuizVisible;
            },
            showPollTab() {
                return this.isPollVisible;
            },
            showChatTab(){
                if(!this.isChatVisible){
                    return false;
                }
                if (this.chatType === 'slido' && this.currentEvent.slidoEvent){
                    return true;
                }
                if (this.chatType === 'crisp' && this.currentEvent.crispChatEvent){
                    return true;
                }
                return false;
            },
            showHandoutTab(){
                return this.isHandoutVisible && this.currentEvent.showHandoutTab;
            },
            availableTabs() {
                let availableTabs = [];
                let map = new Map([
                    ['home', true],
                    ['handout', this.showHandoutTab],
                    ['agenda', false],
                    ['question', this.isQuestionsVisible],
                    ['poll', this.showPollTab],
                    ['chat', this.showChatTab],
                    ['quiz', this.showQuizTab],
                    ['custom', this.showCustomTab],
                    ['support', true],
                    ['privacy-policy', true],
                ]);

                map.forEach((isVisible, key) => {
                    if (isVisible) {
                        availableTabs.push(key);
                    }
                });
                return availableTabs;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            tabsList() {
                let tabList = [
                    {
                        name: 'home',
                        visibility: true,
                        icon: 'mdi-home-outline',
                        label: this.$t('event.home'),
                        iconSize: 34
                    },
                    {
                        name: 'handout',
                        visibility: this.showHandoutTab,
                        icon: 'mdi-download-outline',
                        label: this.$t('event.handout'),
                        iconSize: 34

                    },
                    {
                        name: 'agenda',
                        visibility: false,
                        icon: 'mdi-calendar-outline',
                        label: this.$t('agenda.program'),
                        iconSize: 30

                    },
                    {
                        name: 'question',
                        visibility: this.isQuestionsVisible,
                        icon: 'mdi-help',
                        label: this.$t('event.ask_a_question'),
                        iconSize: 30

                    },
                    {
                        name: 'poll',
                        visibility: this.showPollTab,
                        icon: 'mdi-chart-box-outline',
                        label: this.$t('event.poll'),
                        badge: true,
                        iconSize: 30,
                        active: this.active
                    },
                    {
                        name: 'chat',
                        visibility: this.showChatTab,
                        icon: 'mdi-message-text-outline',
                        label: this.$t('event.chat'),
                        iconSize: 30

                    },
                    {
                        name: 'quiz',
                        visibility: this.showQuizTab,
                        icon: 'mdi-star-box-multiple-outline',
                        label: this.$t('event.quiz'),
                        badge: true,
                        iconSize: 30,
                        active: this.activeQuestion
                    },
                    {
                        name: 'custom',
                        visibility: this.showCustomTab,
                        icon: this.customTabIcon,
                        label: this.customTabTitle,
                        iconSize: 30
                    },
                    {
                        name: 'support',
                        visibility: true,
                        label: this.$t('layout.support'),
                        icon: 'mdi-face-agent',
                        iconSize: 30
                    },
                    {
                        name: 'privacy-policy',
                        visibility: true,
                        label: this.$t('privacyPolicy.title'),
                        icon: 'mdi-file-eye',
                        iconSize: 30
                    },
                ].sort((a,b) => {
                    if (this.tabOrder.indexOf(a.name) > this.tabOrder.indexOf(b.name)) {
                        return 1;
                    }
                    if (this.tabOrder.indexOf(b.name) > this.tabOrder.indexOf(a.name)) {
                        return -1;
                    }
                    return 0;
                });

                return tabList;
            },
            displayedTabs(){
                return this.tabsList.filter(tab => tab.visibility).slice(0,5);
            },
            hiddenTabs(){
                return this.tabsList.filter(tab => tab.visibility).slice(5, this.tabsList.length);
            },
            tabSizeStyle(){
                return {
                    '--tab-flex-precentage': 100/this.displayedTabs.length + '%'
                };
            },
            isIOS(){
                const ua = navigator.userAgent;
                return /iPad|iPhone|iPod/.test(ua);

            }
        },
        methods: {
            selectTab(tab) {
                this.tab =
                    this.availableTabs.indexOf(tab) !== -1
                        ? tab
                        : this.availableTabs[0];
            }
        },
    };
</script>

<style lang="scss">
.pwa {
    .v-tabs {
        border-bottom: 1px solid #c5c3c3;
    }

    .v-tab {
        min-width: 0 !important;
        background: var(--secondary-color) !important;
    }

    .qld-controller-tab {
        width: 80px;
        flex: 0 1 var(--tab-flex-precentage);
    }

    .nav-tabs {
        &.mobile {
            .qld-controller-tab {
                width: 63px;
            }

            .menu-active {
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0 !important;
                    right: 0 !important;
                    content: "";
                    width: 100%;
                    border-bottom: 4px solid var(--darken-primary-color) !important;
                }
            }
        }
    }

    .menu-button {
        border-left: 1px solid #c5c3c3;
        flex: 0 1 var(--tab-flex-precentage);
        border-radius: 0 !important;
        background: var(--secondary-color) !important;
        color: transparent;
    }

    .menu-tab-divider {
        border-left: 1px solid #c5c3c3;
    }

    .active-tab {
        position: relative;

        &::after {
            position: absolute;
            bottom: 0 !important;
            right: 0 !important;
            content: "";
            width: 100%;
            border-bottom: 2px solid var(--darken-primary-color) !important;
        }

        .v-icon {
            color: var(--darken-primary-color) !important;
        }
    }

    .menu-active {
        position: relative;

        &::after {
            position: absolute;
            bottom: 0 !important;
            right: 0 !important;
            content: "";
            width: 100%;
            border-bottom: 2px solid var(--darken-primary-color) !important;
        }

        .v-icon {
            color: var(--darken-primary-color) !important;
        }
    }
}
</style>

