<template>
    <div class="calendar-page d-flex align-center justify-center">
        <p style="text-align: center;">
            {{ $t("calendar.downloaded_text") }}
            <br>
            <a ref="download-ics-button"
               :href="hrefIcs"
               :download="filename"
            >{{
                $t("calendar.click_to_download")
            }}</a>
        </p>
    </div>
</template>

<script>
    import {google, ics, office365} from 'calendar-link';
    import { ROUTE_PERSONAL_LINK } from '@/router/constants';
    import { mapGetters, mapState } from 'vuex';

    export default {
        computed: {
            ...mapGetters('project', ['currentEvent', 'buttonShadowColorStyle']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId', 'registrationData']),
            onSiteRegistrant() {
                return this.registrationData?.on_site_viewer === 1;
            },
            personalUrl() {
                return (
                    window.location.origin +
                    this.$router.resolve({
                        name: ROUTE_PERSONAL_LINK,
                        params: {
                            event_id: this.currentEvent._id,
                            registration_id: this.registrationId,
                        },
                    }).href
                );
            },
            filename() {
                const toSnakeCase = (str) =>
                    str &&
                    str
                        .match(
                            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
                        )
                        .map((x) => x.toLowerCase())
                        .join('_');

                return `${toSnakeCase(this.project.calendarTitle ? this.project.calendarTitle : this.project.eventTitle)}.ics`;
            },
            genericCalendarObj() {
                const startTime = this.currentEvent.startTime * 1000;
                const lastEvent = this.project.events[this.project.events.length - 1];
                const endTime =
                    lastEvent.startTime * 1000 + lastEvent.durationInMinutes * 60000;
                let calendarObject = {
                    title: this.project.calendarTitle ? this.project.calendarTitle : this.project.eventTitle,
                    description: this.$t('calendar.cta_before_link') + ': ' + this.personalUrl,
                    start: new Date(startTime),
                    end: new Date(endTime),
                };

                if (this.onSiteRegistrant) {
                    calendarObject['location'] = this.project.location.formattedAddress;
                }

                return calendarObject;
            },
            hrefIcs() {
                return ics(this.genericCalendarObj);
            },
        },
        methods: {
            goToGoogle() {
                window.location.href = google(this.genericCalendarObj);
            },
            goToOutlook() {
                let calendarObject = this.genericCalendarObj;
                calendarObject[
                    'description'
                ] = this.$t('calendar.cta_before_link') + ': ' + `<a href="${this.personalUrl}">${this.personalUrl}</a>`;
                window.location.href = office365(calendarObject);
            },
        },
        mounted() {
            if (this.$route.hash === '#ics') {
                this.$refs['download-ics-button'].click();
            } else if (this.$route.hash === '#google') {
                this.goToGoogle();
            } else if (this.$route.hash === '#outlook') {
                this.goToOutlook();
            }
        },
    };
</script>

<style lang="scss">
.calendar-page {
  height: 80vh;
}
</style>
