<template>
    <qld-component-player
        :data-qld-config="playerQldConfigAsString"
        data-qld-name="player"
        @player-loaded="playerReady"
    />
</template>
<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import screenfull from 'screenfull';
    import {forceFullScreen} from '@/services/FullscreenService';
    export default {
        name: 'LivePlayer',
        computed: {
            ...mapState('livePage', ['externalFullscreen']),
            ...mapState('project', ['smallDevice']),
            ...mapGetters('project', [
                'showPollsOverVideo',
                'showQuizOverVideo',
            ]),
            playerQldConfigAsString() {
                const playerQldConfig = {
                    width: '100%',
                    aspectratio: '16:9',
                    customUnmuteToggle: 'true',
                    customUnMuteLabel: this.$t('event.unmute_text'),
                    mute: 'true',
                    'allow-fullscreen': 'true',
                    externalFullscreen: true,
                };
                return JSON.stringify(playerQldConfig);
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods: {
            ...mapMutations('livePage', ['openSheet', 'updateExternalFullscreen']),
            onForceFullScreen(p, v) {
                forceFullScreen(v);
            },
            onExternalFullscreen(playerId, data) {
                this.updateExternalFullscreen(data.newValue);
            },
            playerReady() {
                this.removePlayerListeners();
                window.QuadiaPlayer.$on('forceFullscreen', this.onForceFullScreen);
                window.QuadiaPlayer.$on('externalFullscreen', this.onExternalFullscreen);
            },
            removePlayerListeners() {
                if (window.QuadiaPlayer) {
                    window.QuadiaPlayer.$off('forceFullscreen', this.onForceFullScreen);
                    window.QuadiaPlayer.$on('externalFullscreen', this.onExternalFullscreen);
                }
            },
            fullscreenChange() {
                window.QuadiaPlayer.players['qld-player-player'].$emit('externalFullscreen', {newValue: screenfull.isFullscreen, oldValue: this.externalFullscreen});
            },
        },
        mounted() {
            screenfull.on('change', this.fullscreenChange);
        },
        beforeDestroy() {
            screenfull.off('change', this.fullscreenChange);
            this.removePlayerListeners();
        },
    };
</script>

<style lang="scss">
.jw-player {
  overflow: hidden;
}

div[data-qld-name="player"] img {
  width: 100%;
}
</style>
