<template>
    <v-card class="pa-4 about-card mb-5"
            elevation="5"
    >
        <h3 class="text-center">
            {{ project.eventTitle }}
        </h3>
        <p class="text-center mb-4">
            <start-time />
        </p>
        <div v-html="homescreenContent" />
    </v-card>
</template>

<script>
    import StartTime from '@/components/controls/StartTime';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'AboutEvent',
        components: {StartTime},
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', ['pwaHomescreenContent', 'getContent', 'logo']),
            homescreenContent(){
                return this.getContent(this.pwaHomescreenContent);
            },
        }
    };
</script>

<style lang="scss">
.v-window{
    overflow: visible !important;
}
</style>