<template>
    <div class="layout">
        <participants-list />
    </div>
</template>

<script>
    import ParticipantsList from '@/components/controls/ParticipantsList';
    export default {
        name: 'ParticipantsPage',
        components: {ParticipantsList}
    };
</script>

<style lang="scss" scoped>
.layout{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 40px;
}
</style>