<template>
    <div class="header-block"
         :class="{'mobile': isMobile, 'gradient': gradient}"
    >
        <span class="start-time"
              :class="{'mb-4': !address}"
        >
            {{ currentEvent.startDateTimeFormattedFull }}
        </span>
        <span v-if="address"
              class="location"
        >
            {{ address }}
        </span>
        <h1 class="event-title">
            {{ project.eventTitle }}
        </h1>
        <div class="content"
             v-html="content"
        />
        <v-btn
            class="registration-button"
            :style="{...textColorStyle, ...buttonBackgroundStyle}"
            @click="openRegistrationForm"
        >
            {{ $t('registration.title') }}
        </v-btn>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'HeaderBlock',
        props: {
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        computed:{
            ...mapGetters('project', [
                'textColorStyle',
                'darkenPrimaryColor',
                'currentEvent',
                'privacyPolicyUrl',
                'primaryColorRGB', 
                'registrationIntroductionText',
                'getContent',
                'buttonBackgroundStyle',
            ]),
            ...mapState('project', ['project']),
            content(){
                return this.getContent(this.registrationIntroductionText);
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            address(){
                return this.project.location.formattedAddress;
            }
        },
        methods:{
            ...mapMutations('dynamicBlocks', [ 'setOpenRegistrationForm']),
            openRegistrationForm(){
                this.setOpenRegistrationForm(true);
            }
        }
    };
</script>
