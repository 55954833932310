import Vue from 'vue';
import VueRouter from 'vue-router';
import EventPage from '@/components/pages/EventPage';
import RegistrationPageRoot from '@/components/pages/RegistrationPageRoot';
import CalendarPage from '@/components/pages/CalendarPage';
import RegistrationConfirmationRoot from '@/components/pages/RegistrationConfirmationRoot';
import EvaluationPage from '@/components/pages/EvaluationPage';
import TestPage from '@/components/pages/TestPage';
import OnDemand from '@/components/templates/default/EventPage/OnDemand';
import CasCallback from '@/components/pages/CasCallback';
import PersonalLink from '@/components/pages/PersonalLink';
import SpringerAuth from '@/components/pages/SpringerAuth';
import LinkedInAuthCallback from '@/components/pages/LinkedInAuthCallback';
import DynamicEventPage from '@/components/pages/DynamicEventPage';
import DefaultHeader from '@/components/templates/default/DefaultHeader';
import UnregisterPage from '@/components/pages/UnregisterPage';
import ChangeAttendancePage from '@/components/pages/ChangeAttendancePage';
import ParticipantsPage from '@/components/pages/ParticipantsPage';

import {
    ROUTE_EVENT_WITH_PERSONAL_ID,
    ROUTE_REGISTRATION,
    ROUTE_REGISTRATION_CONFIRMATION,
    ROUTE_EVALUATION,
    ROUTE_REGISTRATION_COMPANY,
    ROUTE_TEST_PAGE,
    ROUTE_ONDEMAND_PAGE,
    ROUTE_CAS_CALLBACK,
    ROUTE_PERSONAL_LINK,
    ROUTE_DYNAMIC_EVENT_PAGE,
    ROUTE_CHANGE_ATTENDANCE,
    ROUTE_UNREGISTER,
    ROUTE_PARTICIPANTS,
    ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE
} from '@/router/constants';
import OndemandWithRegistration from '@/components/templates/default/EventPage/OndemandWithRegistration';
import LoginPage from '@/components/pages/LoginPage.vue';
import CustomBreakoutNavigation from '@/components/pages/CustomBreakoutNavigation.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/linkedin-auth-callback',
        name: 'linkedin-auth-callback',
        components: {
            body: LinkedInAuthCallback
        }
    },
    {
        path: '/forward-to-event-page/:event_id',
        name: 'customBreakoutNavigation',
        components: {
            body: CustomBreakoutNavigation,
        }
    },
    {
        path: '/:event_id',
        components: {
            header: DefaultHeader,
            body: LoginPage
        }
    },
    {
        path: '/:event_id/preview-dynamic-blocks',
        name: ROUTE_DYNAMIC_EVENT_PAGE,
        components: {
            body: DynamicEventPage,
        }
    },
    {
        path: '/:event_id/springer-auth',
        name: 'SpringerOAuthPage',
        components: {
            body: SpringerAuth
        }
    },
    {
        path: '/:event_id/cas-callback',
        name: ROUTE_CAS_CALLBACK,
        components: {
            body: CasCallback
        }
    },
    {
        path: '/:event_id/test-page',
        name: ROUTE_TEST_PAGE,
        components: {
            header: DefaultHeader,
            body: TestPage
        }
    },
    {
        path: '/:event_id/registration',
        name: ROUTE_REGISTRATION,
        components: {
            body: RegistrationPageRoot
        }
    },
    {
        path: '/:event_id/ondemand',
        name: ROUTE_ONDEMAND_PAGE,
        components: {
            header: DefaultHeader,
            body: OnDemand
        }
    },
    {
        path: '/:event_id/:registration_id/ondemand-with-registration',
        name: ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE,
        components: {
            header: DefaultHeader,
            body: OndemandWithRegistration
        }
    },
    {
        path: '/:event_id/:registration_id/registration-confirmation',
        name: ROUTE_REGISTRATION_CONFIRMATION,
        components: {
            body: RegistrationConfirmationRoot
        }
    },
    {
        path: '/:event_id/:registration_id/calendar',
        components: {
            header: DefaultHeader,
            body: CalendarPage
        }
    },
    {
        path: '/:company_id/registration-company',
        name: ROUTE_REGISTRATION_COMPANY,
        components: {
            header: DefaultHeader,
            body: RegistrationPageRoot,
        }
    },
    {
        path: '/:event_id/:registration_id',
        name: ROUTE_EVENT_WITH_PERSONAL_ID,
        components: {
            header: DefaultHeader,
            body: EventPage
        }
    },
    {
        path: '/:event_id/:registration_id/olr83ee2',
        name: ROUTE_PERSONAL_LINK,
        components: {
            body: PersonalLink
        },
        meta: {
            avoidRender: true,
            avoidRedirectingToOndemand: true
        }
    },
    {
        // It was added to redirect the old registrants to the new link
        path: '/:event_id/:registration_id/personal-link',
        redirect: '/:event_id/:registration_id/olr83ee2'
    },
    {
        path: '/:event_id/:registration_id/evaluation',
        name: ROUTE_EVALUATION,
        components: {
            header: DefaultHeader,
            body: EvaluationPage
        },
        meta: {
            avoidRedirectingToOndemand: true
        }
    },
    {
        path: '/:event_id/:registration_id/unregister',
        name: ROUTE_UNREGISTER,
        components: {
            header: DefaultHeader,
            body: UnregisterPage
        },
    },
    {
        path: '/:event_id/:registration_id/change-attendance',
        name: ROUTE_CHANGE_ATTENDANCE,
        components: {
            header: DefaultHeader,
            body: ChangeAttendancePage
        },
    },
    {
        path: '/:event_id/:registration_id/participants',
        name: ROUTE_PARTICIPANTS,
        components: {
            header: DefaultHeader,
            body: ParticipantsPage
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
