<template>
    <div>
        <div v-if="!hideTitle"
             class="text-left agenda-title program text-uppercase"
        >
            {{ $t('agenda.program') }}:
        </div>
        <div v-if="onlyUpcoming"
             class="text-left agenda-title agenda-title-coming-up text-uppercase"
        >
            {{ $t('agenda.comingUpNext') }}:
        </div>

        <div v-for="event in sortedEvents"
             :key="event._id"
             class="agenda-event"
             :class="{ mobile: isMobile }"
        >
            <agenda-session v-if="!event.breakout_group"
                            :event="event"
                            :force-mobile-view="forceMobileView"
                            :show-status="showStatus"
            />
            <agenda-breakout-group v-else
                                   :event="event"
                                   :force-mobile-view="forceMobileView"
                                   :show-status="showStatus"
                                   :collapse-breakouts="collapseBreakouts"
                                   :hide-breakouts="hideBreakouts"
            />
            <v-divider v-if="isDynamicBlocksFlavourAgenda"
                       dark
                       class="mt-5 mb-2"
            />
        </div>
    </div>
</template>

<script>
    import { buildAgenda } from '@/services/AgendaService';
    import {mapGetters, mapState} from 'vuex';
    import AgendaSession from '@/components/controls/Agenda/AgendaSession';
    import AgendaBreakoutGroup from '@/components/controls/Agenda/AgendaBreakoutGroup';
    import {furtherEvents} from '@/services/FurtherEvents';

    export default {
        name: 'Agenda',
        props: {
            forceMobileView: {
                type: Boolean,
                default: () => false
            },
            onlyUpcoming: {
                type: Boolean,
                default: () => false,
            },
            showStatus: {
                type: Boolean,
                default: () => false,
            },
            hideTitle: {
                type: Boolean,
                default: () => false
            },
            collapseBreakouts: {
                type: Boolean,
                default: () => false
            },
            hideBreakouts: {
                type: Boolean,
                default: () => false
            }
        },
        components: {AgendaBreakoutGroup, AgendaSession },
        computed:{
            ...mapState('project', ['project']),
            ...mapState('time', ['currentTime']),
            ...mapGetters('registration', ['registeredEvents']),
            ...mapGetters('project', ['isDynamicBlocksFlavourAgenda']),            
            sortedEvents() {
                const events = this.onlyUpcoming
                    ? furtherEvents(this.project.events, {_id: ''}, this.currentTime, this.registeredEvents)
                    : this.project.events;
                return buildAgenda(events, this.$t('agenda.breakout_round'));
            },
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.forceMobileView;
            },
        },
    };
</script>
<style scoped lang="scss">
.agenda-title{
    font-size: 23px !important;
    font-weight: 900;
    margin-bottom: 3px !important;
}

.agenda-event {
    margin-bottom: 20px;
    &.mobile{
        margin-bottom: 10px;
    }
}
</style>
