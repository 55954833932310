<template>
    <v-form ref="form"
            class="login-form ma-4"
            @submit.prevent="login"
    >
        <p style="color: #3e3e3e">
            {{ $t("loginPage.loginFormInstruction") }}
        </p>
        <label for="#login-email"
               class="mt-4 mb-1 d-block form-label"
        >
            {{ $t("loginPage.emailAddress") }}
        </label>
        <v-text-field
            id="login-email"
            v-model="loginEmail"
            :color="darkenPrimaryColor"
            :rules="requiredRule.concat(emailFormat)"
            class="mb-2"
            outlined
            :hide-details="displayError"
            :disabled="loading"
            validate-on-blur
        />
        <small v-if="displayError"
               class="error--text d-block mb-2 mx-3"
        >
            {{ $t("loginPage.loginError") }}
        </small>
        <v-btn
            type="submit"
            class="submit-button"
            :color="darkenPrimaryColor"
            :width="120"
            :loading="loading"
            :disabled="loading"
            :style="textColorOnDarkenPrimaryColor"
        >
            {{ $t("loginPage.loginFormSubmitText") }}
        </v-btn>
    </v-form>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { ROUTE_PERSONAL_LINK } from '@/router/constants';
    import { $http } from '@/services/http';

    export default {
        name: 'LoginForm',
        data() {
            return {
                loginEmail: '',
                displayError: false,
                loading: false,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'currentEvent',
                'textColorOnDarkenPrimaryColor',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'primaryColorRGB',
            ]),
            requiredRule() {
                return this.required
                    ? [(v) => !!v || this.$t('fields.validation.required')]
                    : [];
            },
            emailFormat() {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return [(v) => pattern.test(v) || this.$t('fields.validation.notEmail')];
            },
        },
        methods: {
            async login() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                this.loading = true;
                try {
                    const url = process.env.VUE_APP_LOGIN_ENDPOINT.replace(
                        '{projectID}',
                        this.project._id
                    );
                    const { data } = await $http.post(url, {
                        email: this.loginEmail.toLowerCase(),
                    });
                    this.gotToEventPage(data._id);
                } catch (e) {
                    if (e.response?.status === 404) {
                        this.displayError = true;
                    }
                } finally {
                    this.loading = false;
                }
            },
            gotToEventPage(registrationId) {
                this.$router.push({
                    name: ROUTE_PERSONAL_LINK,
                    params: {
                        event_id: this.currentEvent._id,
                        registration_id: registrationId,
                    },
                });
            },
        },
        watch: {
            loginEmail() {
                this.displayError = false;
            }
        }
    };
</script>

<style lang="scss">

.login-form{
    .v-input{
        border-radius: var(--input-border-radius) !important;
    }

    .submit-button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.2px;
        padding: 10px 32px;
        color: var(--text-color-on-darken) !important;
    }
}

</style>