<template>
    <div class="fullHeight mx-0">
        <h2 class="event-title">
            Redirecting to Springer
            <v-progress-circular model-value="20"
                                 class="progress-bar"
                                 indeterminate
            />
        </h2>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SpringerCasService from '@/services/SpringerCasService';

    export default {
        name: 'SpringerAuth',
        async created() {
            window.localStorage.removeItem('SpringerLoginFailed');
            if (this.registrationType === 'springer') {
                if (this.$route.query.pwa) {
                    window.localStorage.setItem('pwa', this.$route.params.event_id);
                }
                let springer = new SpringerCasService(this.$route.params.event_id, this.springerOAuthClientId);
                springer.redirectToAuthorizeAndSaveState();
            }
        },
        computed: {
            ...mapGetters('project', ['currentEvent', 'registrationType', 'springerOAuthClientId']),
        },
    };
</script>

<style lang="scss" scoped>
.fullHeight {
  display: flex !important;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.progress-bar {
  top: -5px;
  margin-left: 5px;
}
</style>
