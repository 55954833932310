export const splitColorsRGBA = (rgbaColor) => {
    const list = rgbaColor.replace('rgba(', '')
    .replace(')')
    .replace(' ', '')
    .split(',');
    const r = list[0];
    const g = list[1];
    const b = list[2];
    return {r, g, b};
};

export const splitColorsRGB = (rgbColor) => {
    const list = rgbColor.replace('rgb(', '')
    .replace(')', '')
    .replace(/\s/g, '')
    .split(',');

    const r = list[0];
    const g = list[1];
    const b = list[2];
    
    return {r, g, b};
};

export const colorDarken = (r, g, b) => {
    const average =(parseInt(r)+parseInt(g)+parseInt(b)) / 3;
     if (average > 192) {
            r = r/3 * 2;
            g = g/3 * 2;
            b = b/3 * 2;
    }
    if (average > 127 && average <= 192) {
            r = r/3 * 2.5;
            g = g/3 * 2.5;
            b = b/3 * 2.5;
    }

    return 'rgb('+r+', '+g+', '+b+')';
};

export const chooseTextColor = (r, g, b) => {
    return (r*0.299 + g*0.587 + b*0.114) > 186 ? '#3E3E3E' : '#FFFFFF';
};

export const chooseButtonIconColor = (r, g, b) => {
    return (r*0.299 + g*0.587 + b*0.114) > 186 ? '#0000008a' : '#757575';
};

export const isDark = (color) => {
    const split = splitColorsRGBA(color);
    const average =(parseInt(split.r)+parseInt(split.g)+parseInt(split.b)) / 3;
    return average < 128;
};
