import router from '@/router';
import { ROUTE_CAS_CALLBACK } from '@/router/constants';
import { $http } from '@/services/http';
import crypto from 'crypto-js';

const SpringerCasService = class {
    constructor(eventId, clientId) {
        this.eventId = eventId;
        this.clientId = clientId;
    }

    isSpringerInternationalSmedcom() {
        return (this.clientId === process.env.VUE_APP_SPRINGER_INTERNATIONAL_SMEDCOM_OAUTH_CLIENT_ID);
    }

    callbackUri() {
        let casCallbackRoute = router.resolve({
            name: ROUTE_CAS_CALLBACK,
            params: { event_id: this.eventId },
        });

        let queryString = '';
        if (this.isSpringerInternationalSmedcom()) {
            queryString = '?smedcom';
        }

        return `${location.protocol}//${location.host}${casCallbackRoute.href}` + queryString;
    }

    authorizeUri(code_challenge, state) {
        return process.env.VUE_APP_SPRINGER_OAUTH_HOST + 'oauth2.0/authorize?' +
            'client_id=' + this.clientId + '&' +
            'code_challenge=' + code_challenge + '&' +
            'state=' + state + '&' +
            'redirect_uri=' + this.callbackUri() + '&' +
            'code_challenge_method=S256';
    }

    redirectToAuthorizeAndSaveState() {
        const state = this.createRandomString(40);
        const verifier = this.createRandomString(128);

        window.localStorage.setItem('state', state);
        window.localStorage.setItem('verifier', verifier);

        window.location.href = this.authorizeUri(this.base64Url(crypto.SHA256(verifier)), state);
    }

    tokenValidationUri(code) {
        return process.env.VUE_APP_SPRINGER_OAUTH_HOST + 'oauth2.0/accessToken?' +
            'client_id=' + this.clientId + '&' +
            'redirect_uri=' + this.callbackUri() + '&' +
            'code=' + code + '&' +
            'code_verifier=' + window.localStorage.getItem('verifier');
    }

    async askForToken(code) {
        return await $http.get(this.tokenValidationUri(code));
    }

    async fetchUserProfile(token) {
        return await $http.get(process.env.VUE_APP_SPRINGER_OAUTH_HOST + 'oauth2.0/profile?access_token=' + token);
    }

    createRandomString(num) {
        return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
    }

    base64Url(string) {
        return string.toString(crypto.enc.Base64)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }
};

export default SpringerCasService;
