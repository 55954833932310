<template>
    <div :key="`block-container-${blockContainerKey}`"
         class="block-container"
         :class="{ 'mobile': isMobile }"
    >
        <div v-for="(block, blockIndex) in blocks"
             :key="`block-${block._id}`"
             :style="{ 'order': blockIndex }"
             class="block-wrapper"
             :class="blockClass(block)"
        >
            <div :ref="`block-${block._id}`"
                 class="block"
                 :style="blockStyle(block, isMobile)"
            >
                <div v-if="getComponentType(block) === 'column'"
                     class="block-column"
                     :class="{'gradient': hasGradient(block)}"
                     :style="styles"
                >
                    <div v-for="(subBlock, subBlockIndex) in block.componentOptions.blocks"
                         :key="`block-${subBlock._id}`"
                         class="block-wrapper"
                         :style="{ order: subBlockIndex }"
                         :class="blockClass(subBlock)"
                    >
                        <div :ref="`block-${subBlock._id}`"
                             class="block"
                             :style="blockStyle(subBlock, isMobile)"
                        >
                            <component
                                :is="getComponentType(subBlock)"
                                class="block-content"
                                :options="subBlock.componentOptions"
                                :width="blockClass(subBlock)"
                                :gradient="hasGradient(subBlock)"
                                :block-id="subBlock._id"
                            />
                        </div>
                    </div>
                </div>
                <component
                    :is="getComponentType(block)"
                    v-else
                    class="block-content"
                    :width="blockClass(block)"
                    :options="block.componentOptions"
                    :gradient="hasGradient(block)"
                    :block-id="block._id"
                />
            </div>
        </div>
        <scroll-to-top-button scroll-element=".content-col-splash"
                              no-footer-reference
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import Speakers from '@/components/dynamic-blocks/Speakers';
    import CountDownBlock from '@/components/dynamic-blocks/CountDownBlock';
    import VideoContentBlock from '@/components/dynamic-blocks/VideoContentBlock';
    import Slideshow from '@/components/dynamic-blocks/Slideshow';
    import ImageContentBlock from '@/components/dynamic-blocks/ImageContentBlock';
    import TextContentBlock from '@/components/dynamic-blocks/TextContentBlock';
    import Spacer from '@/components/dynamic-blocks/Spacer';
    import WaitingPageProgramElement from '@/components/dynamic-blocks/WaitingPageProgramElement';
    import WaitingPageHeaderBlock from '@/components/dynamic-blocks/WaitingPageHeaderBlock.vue';
    import ScrollToTopButton from '@/components/dynamic-blocks/ScrollToTopButton.vue';
    import {blockClass, blockStyle, dispatchLoaded, getStyles, hasGradient} from '@/services/BlockHelper';
    import UpcomingEvents from '@/components/dynamic-blocks/UpcomingEvents.vue';

    export default {
        name: 'DynamicSplash',
        components: {
            ScrollToTopButton,
            Speakers,
            CountDownBlock,
            VideoContentBlock,
            Slideshow,
            ImageContentBlock,
            TextContentBlock,
            Spacer,
            WaitingPageProgramElement,
            WaitingPageHeaderBlock,
            UpcomingEvents,
        },
        data() {
            return {
                blockContainerKey: 0,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('waitingPageDynamicBlocks', ['elementToScrollTo', 'blocksFromPreview', 'openRegistrationForm']),
            ...mapGetters('project', ['primaryColorRGB']),
            blocks() {
                return this.blocksFromPreview ?? this.project.waitingPageDynamicBlocks.blocks.blocks;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            styles() {
                return getStyles(this.primaryColorRGB);
            },
        },
        methods: {
            ...mapMutations('waitingPageDynamicBlocks', ['setBlocksFromPreview', 'setElementToScrollTo']),
            blockClass,
            blockStyle,
            hasGradient,
            getComponentType(block) {
                switch (block.componentType) {
                default:
                    return block.componentType;
                case 'header-block':
                    return 'waiting-page-header-block';
                case 'program-element':
                    return 'waiting-page-program-element';
                }
            },
            async listenForBlocksUpdate(e) {
                if (
                    process.env.VUE_APP_DASHBOARD_ORIGIN.split(',').indexOf(
                        e.origin
                    ) < 0
                ) {
                    return;
                }
                const message = JSON.parse(e.data);
                if (message.event === 'qlive-app-blocks-updated') {
                    this.setBlocksFromPreview(message.blocks);
                    this.blockContainerKey++;
                    await this.$nextTick();
                    dispatchLoaded(this.blocks, this.$refs);
                }
            },
        },
        watch:{
            elementToScrollTo(value){
                if(value){
                    const elementPosition = this.$refs[value][0].getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - 80;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                    this.setElementToScrollTo(null);
                }
            }
        },
        updated() {
            dispatchLoaded(this.blocks, this.$refs);
        },
        async mounted() {
            await this.$nextTick();
            dispatchLoaded(this.blocks, this.$refs);
            window.addEventListener('message', this.listenForBlocksUpdate);
        },
        beforeDestroy() {
            window.removeEventListener('message', this.listenForBlocksUpdate);
        }
    };
</script>
