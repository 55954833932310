<template>
    <div class="slideshow" 
         :class="{mobile: isMobile}"
         @mouseover="hover = true" 
         @mouseleave="hover = false"
    >
        <v-carousel interval="5000" 
                    :cycle="!hover" 
                    :height="isMobile ? 250 : 500 "
                    show-arrows-on-hover
                    hide-delimiter-background 
                    delimiter-icon="mdi-minus"
        >
            <v-carousel-item
                v-for="(item,i) in options.images"
                :key="i"
                :src="item.url"
            />
        </v-carousel>
    </div>
</template>

<script>
    export default {
        name: 'Carousel',
        props: {
            options: {
                type: Object,
                default:() => {}
            }
        },
        data () {
            return {
                hover:false,
            };
        },
        computed:{
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        }
    };
</script>