<template>
    <registration-page v-if="showDefaultRegistrationConfirmationPage" />
    <dynamic-event-page v-else />
</template>

<script>
    import RegistrationPage from '@/components/pages/RegistrationPage';
    import DynamicEventPage from '@/components/pages/DynamicEventPage';
    import {mapState} from 'vuex';
    export default {
        name: 'RegistrationConfirmationRoot',
        components: {DynamicEventPage, RegistrationPage},
        computed: {
            ...mapState('project', ['project']),
            showDefaultRegistrationConfirmationPage() {
                return this.project.dynamicBlocks.layout === 'basic'
                    || this.$route.query.previewMode === 'default-registration';
            }
        }
    };
</script>