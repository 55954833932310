<template>
    <div class="event-page-header rounded-0"
         :class="{'mobile':isMobile}"
    >
        <img :src="logo"
             class="company-logo"
             alt="Logo"
             @click="scrollToTop"
        >
        <div class="button-container">
            <div v-if="!isMobile"
                 class="d-flex align-center"
            >
                <div v-for="block in blockTitles"
                     :key="`block-button-${block.id}`"
                     class="d-flex"
                >
                    <v-btn 
                        text
                        class="block-buttons"
                        @click="scrollToElement(`block-${block.id}`)"
                    >
                        {{ block.title }}
                    </v-btn>
                    <v-divider vertical
                               class="mx-2"
                    />
                </div>
            </div>
            <v-btn
                class="registration-button"
                :style="{...textColorStyle, ...buttonBackgroundStyle}"
                @click="openRegistrationForm"
            >
                {{ $t('registration.title') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'DynamicPageHeader',
        props:{
            hideRegistrationButton:{
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'logo', 
                'darkenPrimaryColor',
                'textColorStyle',
                'buttonBackgroundStyle',
            ]),
            ...mapGetters('dynamicBlocks', ['blocksFromPreview']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            blocks() {
                return this.blocksFromPreview ?? this.project.dynamicBlocks.blocks.blocks;
            },
            blockTitles(){
                let titles = [];
                if(this.blocks){
                    this.blocks.map(block => {
                        if(block.tabTitle){
                            titles.push({
                                title: block.tabTitle,
                                id: block._id,
                            });
                        }
                        if(block.componentType === 'column' && block.blocks !== undefined){
                            block.blocks.map(subBlock => {
                                if(subBlock.tabTitle){
                                    titles.push({
                                        title: subBlock.tabTitle,
                                        id: subBlock._id,
                                    });
                                }
                            });
                        }
                    });
                }
                return titles;
            }
        },
        methods:{
            ...mapMutations('dynamicBlocks', ['setElementToScrollTo', 'setOpenRegistrationForm']),
            scrollToElement(block){
                this.setElementToScrollTo(block);
            },
            scrollToTop(){
                window.scrollTo(0, 0);
            },
            openRegistrationForm(){
                this.setOpenRegistrationForm(true);
            }
        },
    };
</script>

<style scoped lang="scss">
.event-page-header {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px -1px 0px rgba(62, 62, 62, 0.3);
    padding: 20px 60px;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index:2;

    .scroll-to-top{
        position: absolute;
        right: 30px;
        top: 87vh;
    }

    .company-logo{
        max-height: 35px;
        cursor: pointer;
    }

    .button-container{
        display: inline-flex;
        gap: 20px;
        .block-buttons{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            .v-btn .v-btn__content{
                color: #3e3e3e !important;
            }
        }

        .registration-button{
            color: var(--text-color) !important;
            width: 148px;
            height: 40px;
            padding: 10px 32px ;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-transform: uppercase;
            background: var(--button-background);
        }
    }

    &.mobile{
        height: 64px;
        padding: 16px;

        .company-logo{
            max-height: 28px;
        }

        .registration-button{
            width: 101px;
            height: 32px;  
            padding: 10px 16px ;
            font-size: 11px;
        }
    }

}

</style>

<style lang="scss">
    .block-buttons{
        .v-btn .v-btn__content{
            color: #3e3e3e !important;
        }
    }
</style>
