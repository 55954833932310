<template>
    <div class="program-element waiting-page-program-element"
         :class="{'mobile': isMobile, 'xl-screen': isExtraLargeScreen, 'gradient': gradient}"
         :style="styles"
    >
        <agenda v-if="isMultiSession"
                class="agenda-splash"
                :show-status="agendaShowsStatus"
                collapse-breakouts
        />
        <program-single-session v-else />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import ProgramSingleSession from '@/components/controls/ProgramSingleSession';
    import {hidesAgendaButtons} from '@/services/AgendaService';

    export default {
        name: 'WaitingPageProgramElement',
        props: {
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        components: { Agenda, ProgramSingleSession },
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', ['primaryColorRGB', 'isMultiSession']),
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            isExtraLargeScreen(){
                return this.$vuetify.breakpoint.xlOnly;
            },
            agendaShowsStatus() {
                return !hidesAgendaButtons(this.project._id);
            }
        }
    };
</script>
