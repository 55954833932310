<template>
    <div class="mx-0 event-page">
        <qld-controller-switch
            data-qld-name="state"
            data-qld-controller-value="pre"
            :class="{'live-container': isRegisteredForPwa}"
        >
            <pwa-default-live v-if="isRegisteredForPwa" />
            <default-splash v-else />
        </qld-controller-switch>
        <qld-controller-switch
            class="live-container"
            data-qld-name="state"
            data-qld-controller-value="on-air"
        >
            <pwa-default-live v-if="isRegisteredForPwa" />
            <default-live v-else />
        </qld-controller-switch>
        <qld-controller-switch
            data-qld-name="state"
            data-qld-controller-value="post"
        >
            <default-end />
        </qld-controller-switch>

        <auto-navigation v-if="activeAutoNavigation" />
    </div>
</template>

<script>
    import DefaultEnd from '@/components/templates/default/EventPage/End';
    import DefaultSplash from '@/components/templates/default/EventPage/Splash';
    import PwaDefaultLive from '@/pwa/templates/default/EventPage/Live.vue';
    import DefaultLive from '@/components/templates/default/EventPage/Live';
    import AutoNavigation from '@/components/templates/default/EventPage/AutoNavigation';
    import { mapGetters } from 'vuex';

    export default {
        name: 'EventPage',
        components: {
            DefaultLive,
            DefaultSplash,
            PwaDefaultLive,
            DefaultEnd,
            AutoNavigation,
        },
        computed: {
            ...mapGetters('qld-vuex-store', ['property']),
            ...mapGetters('project', ['isMultiSession', 'isInPreviewMode']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            broadcastEnded() {
                return this.property('controller-switch', 'state', 'state') === 'post';
            },
            activeAutoNavigation() {
                return (
                    this.isMultiSession && this.broadcastEnded && !this.isInPreviewMode
                );
            },
        },
    };
</script>

<style scoped lang="scss">
.live-container {
  overflow: hidden;
}
</style>