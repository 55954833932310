const timeStore = {
    namespaced: true,
    state: {
        currentTime: null,
    },
    mutations: {
        tick: (state) => state.currentTime = Math.floor(Date.now() / 1000)
    }
};

export default timeStore;

timeStore.mutations.tick(timeStore.state);
setInterval(function () {
    timeStore.mutations.tick(timeStore.state);
}, 1000);