<template>
    <div>
        <div v-for="event in events"
             :key="event.title"
             class="event-wrapper"
             :style="{...darkenPrimaryColorStyle,...textColorStyle}"
        >
            <span class="time">{{ eventTime(event) }}</span>
            <span class="title">{{ event.name }}</span>
            <a :href="eventLink(event)"
               target="_blank"
               class="upcoming-link"
            >
                {{ $t('registration.title') }}
            </a>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name:'UpcomingEventsList',
        props: {
            events: {
                type: Array,
                required: true
            }
        },
        computed:{
            ...mapGetters('project', [
                'textColorStyle',
                'darkenPrimaryColorStyle',
            ]),
        },
        methods: {
            eventLink(event) {
                switch (event.registrationType) {
                default: return `/${event._id}/registration`;
                case 'open': return `/${event._id}/gm84cf8a74`;
                }
            },
            eventTime(event) {
                return event.startTimeFormatted.split(' ')[0];
            }
        }
    };
</script>

<style scoped lang="scss">

.program-title{
    font-family: "Archivo", arial, helvetica, sans-serif !important;
    font-size: 24px !important;
}

.event-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    font-family: "Archivo", arial, helvetica, sans-serif !important;
    text-decoration: none;
    color: inherit;
    justify-content: space-between;
    min-width: 500px;

    .time{
        padding: 0 12px;
        height: 24px;
        margin-top: 4px;
        display: flex;
        justify-content: center;
        flex: 0 0 130px;
        color: var(--block-text-color)
    }

    .title{
        font-size: 30px;
        font-family: "Archivo", arial, helvetica, sans-serif !important;
        flex: 1 1 0;
      color: var(--block-text-color)
    }

    .upcoming-link {
        background-color: var(--darken-primary-color);
        color: var(--text-color);
        border-radius: var(--label-border-radius);
        text-decoration: none;
        font-weight: bold;
        padding: 0 20px;
        flex: 0 0 auto;
    }
}

</style>