<template>
    <v-dialog :value="value"
              max-width="600"
              @input="cancel"
    >
        <v-card :style="{...fontFamilyStyle,...buttonBorderRadiusStyle}"
                class="handout-confirmation"
        >
            <v-card-title>
                {{ $t('event.download_handout_title') }}
            </v-card-title>
            <v-divider />
            <v-card-text class="d-flex align-center pa-8 subtitle-1">
                {{ $t('event.download_handout_question') }}
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-center">
                <v-btn class="px-4"
                       @click="cancel"
                >
                    {{ $t('event.cancel_download') }}
                </v-btn>
                <v-btn :color="darkenPrimaryColor"
                       class="px-4"
                       @click="download"
                >
                    <span :style="textColorStyle"
                          class="handout-confirmation-button-text"
                    >
                        {{ $t('event.proceed_download') }}
                    </span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'HandoutDownloadConfirmation',
        props:{
            value: {
                type: Boolean,
                required: true
            }
        },
        computed:{
            ...mapGetters('project', [
                'fontFamilyStyle',
                'buttonBorderRadiusStyle',
                'darkenPrimaryColor',
                'textColorStyle'
            ]),  
        },
        methods:{
            download(){
                this.$emit('input', false);
                this.$emit('download');
            },
            cancel(){
                this.$emit('input', false);
            }
        },
    };
</script>

<style lang="scss">
.handout-confirmation{
    .v-btn{
        border-radius: var(--button-border-radius) !important;
    }

    .handout-confirmation-button-text {
        color: var(--text-color);
    }

    div,p{
        font-family: var(--font-family);
    }
}
</style>