const livePageStore = {
    namespaced: true,
    state: {
        sheet: false,
        externalFullscreen: false,
    },
    getters: {
        isPollVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'polls', 'visible');
        },
        isChatVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'livechat', 'visible');
        },
        isQuestionsVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'question', 'visible');
        },
        isHandoutVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'handouts', 'visible');
        },
        isQuizVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'quiz', 'visible');
        },
        isEvaluationButtonVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'evaluation-btn', 'visible');
        },
        isCustomCtaButtonVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'custom-cta', 'visible');
        }
    },
    mutations: {
        openSheet(state) {
            state.sheet = true;
        },
        closeSheet(state) {
            state.sheet = false;
        },
        updateExternalFullscreen: (state, value) => {
            if (state.externalFullscreen !== value) {
                state.externalFullscreen = value;
            }
        }
    }
};

export default livePageStore;
