<template>
    <v-tabs
        :key="renditionKey"
        v-model="tab"
        :color="darkenPrimaryColor"
        :show-arrows="!vertical"
        :vertical="vertical"
        fixed-tabs
        grow
        class="tabs"
        :class="{ 'mt-3': !isMobile, 'mobile': isMobile, 'vertical-tabs': vertical }"
    >
        <v-row class="ma-0"
               justify="center"
        >
            <v-col cols="12"
                   lg="12"
                   class="tabs-col"
            >
                <div v-for="item in tabsList"
                     :key="item.name"
                     :class="{'qld-controller-tab': item.visibility}"
                >
                    <v-tooltip :left="externalFullscreen"
                               :disabled="isMobile"
                    >
                        <template v-slot:activator="{ on }">
                            <v-tab v-show="item.visibility"
                                   :href="'#' + item.name"
                                   @click="doOpenSheet"
                            >
                                <v-badge v-if="item.badge"
                                         :value="item.active"
                                         right
                                         dot
                                         overlap
                                         offset-x="10"
                                         offset-y="15"
                                         :color="darkenPrimaryColor"
                                         transition="slide-x-transition"
                                >
                                    <v-icon :size="30"
                                            class="my-2"
                                            v-on="on"
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </v-badge>
                                <v-icon v-if="!item.badge"
                                        :size="30"
                                        class="my-2"
                                        v-on="on"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-tab>
                        </template>
                        <span>{{ item.label }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-tabs>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'TabHeaders',
        props: {
            value: {
                type: String,
                required: true,
            },
            vertical: {
                type: Boolean,
                default: false,
            }
        }, 
        data() {
            return {
                tab: null,
                renditionKey: 0,
            };
        },
        watch: {
            availableTabs() {
                this.selectTab(this.tab);
                this.renditionKey++;
            },
            tab: {
                handler(value) {
                    this.$emit('input', value);
                },
            },
            active(newValue, oldValue){
                if(this.showPollTab && !oldValue && newValue){
                    this.selectTab('poll');
                }
            },
        },
        computed: {
            ...mapState('livePage', ['externalFullscreen']),
            ...mapState('project', ['project']),
            ...mapGetters('livePage', [
                'isPollVisible',
                'isChatVisible',
                'isQuestionsVisible',
                'isHandoutVisible',
                'isQuizVisible',
            ]),
            ...mapGetters('qld-vuex-store/poll', ['active']),
            ...mapGetters('qld-vuex-store/quiz', ['activeQuestion']),
            ...mapGetters('project', [
                'isMultiSession',
                'showPollsOverVideo',
                'showQuizOverVideo',
                'customTabTitle',
                'customTabIcon',
                'showCustomTab',
                'currentEvent',
                'topics',
                'darkenPrimaryColor',
                'chatType',
                'showProgramTab',
                'hasSpeakers',
            ]),
            showContentTab() {
                return !!this.currentEvent.livePageContent;
            },
            tabOrder() {
                if (this.currentEvent.tabOrder.length === 0) {
                    return ['livecontent', 'agenda', 'program', 'speakers', 'question', 'poll', 'chat', 'handout', 'quiz', 'custom'];
                }
                return this.currentEvent.tabOrder;
            },
            showQuizTab() {
                return this.isQuizVisible && (!this.showQuizOverVideo || this.isMobile);
            },
            showPollTab() {
                return this.isPollVisible && (!this.showPollsOverVideo || this.isMobile);
            },
            showChatTab(){
                if(!this.isChatVisible){
                    return false;
                }
                if(this.chatType === 'slido' && this.currentEvent.slidoEvent){
                    return true;
                }
                if (this.chatType === 'crisp' && this.currentEvent.crispChatEvent){
                    return true;
                }
                return false;
            },
            showHandoutTab(){
                return this.isHandoutVisible && this.currentEvent.showHandoutTab;
            },
            availableTabs() {
                let availableTabs = [];
                let map = new Map([
                    ['livecontent', this.showContentTab],
                    ['handout', this.showHandoutTab],
                    ['agenda', this.isMultiSession],
                    ['speakers', this.hasSpeakers],
                    ['program', this.showProgramTab],
                    ['question', this.isQuestionsVisible],
                    ['poll', this.showPollTab],
                    ['chat', this.showChatTab],
                    ['quiz', this.showQuizTab],
                    ['custom', this.showCustomTab],
                ]);

                map.forEach((isVisible, key) => {
                    if (isVisible) {
                        availableTabs.push(key);
                    }
                });

                let sortedAvailableTabs = [];
                this.tabOrder.forEach(tab => {
                    if(availableTabs.indexOf(tab) !== -1){
                        sortedAvailableTabs.push(tab);
                    }
                });

                return sortedAvailableTabs;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            firstTab(){
                let i = 0;
                while(this.availableTabs.indexOf(this.tabOrder[i]) === -1){
                    i++;
                }
                return this.tabOrder[i];
            },
            tabsList() {
                return [
                    {
                        name: 'livecontent',
                        visibility: this.showContentTab,
                        icon: 'mdi-information-outline',
                        label: this.$t('event.info'),
                    },
                    {
                        name: 'handout',
                        visibility: this.showHandoutTab,
                        icon: 'mdi-download-outline',
                        label: this.$t('event.handout'),
                    },
                    {
                        name: 'agenda',
                        visibility: this.isMultiSession,
                        icon: 'mdi-calendar-outline',
                        label: this.$t('agenda.program'),
                    },
                    {
                        name: 'speakers',
                        visibility: this.hasSpeakers,
                        icon: 'mdi-account-tie-voice-outline',
                        label: this.$t('registration.speakers'),
                    },
                    {
                        name: 'question',
                        visibility: this.isQuestionsVisible,
                        icon: 'mdi-help',
                        label: this.$t('event.ask_a_question'),
                    },
                    {
                        name: 'poll',
                        visibility: this.showPollTab,
                        icon: 'mdi-chart-box-outline',
                        label: this.$t('event.poll'),
                        badge: true,
                        active: this.active
                    },
                    {
                        name: 'chat',
                        visibility: this.showChatTab,
                        icon: 'mdi-message-text-outline',
                        label: this.$t('event.chat'),
                    },
                    {
                        name: 'quiz',
                        visibility: this.showQuizTab,
                        icon: 'mdi-star-box-multiple-outline',
                        label: this.$t('event.quiz'),
                        badge: true,
                        active: this.activeQuestion
                    },
                    {
                        name: 'custom',
                        visibility: this.showCustomTab,
                        icon: this.customTabIcon,
                        label: this.customTabTitle,
                    },
                    {
                        name: 'program',
                        visibility: this.showProgramTab,
                        icon: 'mdi-calendar-outline',
                        label: this.$t('agenda.program'),
                    },
                ].sort((a,b) => {
                    if (this.tabOrder.indexOf(a.name) > this.tabOrder.indexOf(b.name)) {
                        return 1;
                    }
                    if (this.tabOrder.indexOf(b.name) > this.tabOrder.indexOf(a.name)) {
                        return -1;
                    }
                    return 0;
                });
            },
        },
        methods: {
            ...mapMutations('livePage', ['openSheet']),
            doOpenSheet() {
                this.openSheet();
                this.$emit('tabSelected');
            },
            selectTab(tab, skipEmit) {
                this.tab =
                    this.availableTabs.indexOf(tab) !== -1
                        ? tab
                        : this.availableTabs[0];

                if (!skipEmit) {
                    this.$emit('tabSelected');
                }
            },
        },
        mounted() {
            this.selectTab(this.value || this.availableTabs[0], true);
        },
    };
</script>

<style lang="scss">
.tabs-col {
    >div{
        border-bottom: 1px solid rgba(79, 79, 79, 0.3) !important;
    }    
}
.fullscreen-mode-tabs {
  position: fixed;
  top: 64px;
  z-index: 5;
  margin: 0 !important;
  right: 50px;

  .v-tabs {
    margin: 0 !important;
  }

  .tabs-col {
    padding: 12px 0 !important;
  }

  .v-tab:before,
  .v-tabs-slider {
    background-color: transparent !important;
  }

  .v-tabs-bar {
    height: auto;
    caret-color: transparent !important;
    background-color: transparent !important;
  }

  .v-tab {
    padding: 0 !important;
    i {
      color: #ffffff !important;
    }
  }
}
.v-tab{
    min-width: 0 !important;
}

.qld-controller-tab{
  width: 80px;
}

.tabs{
    .mobile{
        margin-top: 12px;
        .qld-controller-tab{
            width: 60px;
        }
    }
}

.vertical-tabs {
    .v-tabs-bar__content {
        transform: translateX(0) !important;
    }
}
</style>

