<template>
    <div v-if="loaded"
         class="field-on-site-viewer"
    >
        <v-radio-group
            v-model="model"
            :rules="required ? [(v) => !!v || v === 0 || $t('fields.validation.required')] : []"
            row
        >
            <v-radio
                :name="`radio-${name}`"
                :label="$t('registration.view_online')"
                :value="0"
                :color="color"
                class="my-2"
                :background-color="backgroundColor"
            />
            <v-radio
                :name="`radio-${name}`"
                :label="$t('registration.view_offline') + ` | ` + seats"
                :value="1"
                :color="color"
                class="my-2"
                :background-color="backgroundColor"
                :disabled="!hasRemainingSeats"
            />
        </v-radio-group>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {$http} from '@/services/http';

    export default {
        name: 'FieldOnSiteViewer',
        props: {
            name: {
                type: String,
                default: () => '',
            },
            value: {
                type: Number,
                default: 0,
            },
            color: {
                type: String,
                default: () => '',
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
                onSiteViewers: null,
            };
        },
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', ['expectedOnSiteViewers']),
            isRegistrationPage(){
                return this.$route.name === 'Registration' || this.$route.name === 'RegistrationConfirmation';
            },
            remainingSeats() {
                return this.expectedOnSiteViewers - this.onSiteViewers;
            },
            hasRemainingSeats() {
                return this.remainingSeats > 0;
            },
            loaded() {
                return this.onSiteViewers !== null;
            },
            seats(){
                if(this.remainingSeats === 1){
                    return this.$t('registration.one_seat_remaining');
                } else{
                    return this.$t('registration.seats_remaining').replace('{$x}', this.remainingSeats);
                }
            }
        },
        watch: {
            model(value) {
                this.$emit('input', value);
                window.sessionStorage.setItem('selected-attendance-type', value);
            },
        },
        methods: {
            async refreshOnSiteViewers() {
                const {data} = await $http.get(process.env.VUE_APP_REQUEST_REGISTRATION_COUNT_ONSITE_VIEWERS.replace('{projectID}', this.project._id));
                this.onSiteViewers = data.onSiteViewers;
                if (!this.hasRemainingSeats) {
                    this.model = 0;
                }
            },
            clearTimer() {
                if (this.$options.refreshInterval) {
                    clearInterval(this.$options.refreshInterval);
                }
            }
        },
        async mounted() {
            this.refreshOnSiteViewers();
            this.$options.refreshInterval = setInterval(this.refreshOnSiteViewers, 4500);
            this.model = parseInt(window.sessionStorage.getItem('selected-attendance-type')) >= 0 ? parseInt(window.sessionStorage.getItem('selected-attendance-type')) : 0;
            if(this.$route.query.pwa){
                this.model = 1;
            }
        },
        beforeDestroy() {
            this.clearTimer();
        }

    };
</script>

<style lang="scss">
.field-on-site-viewer{
    .v-input--radio-group__input {
        display: block !important;
    }
}

</style>
