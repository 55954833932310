<template>
    <div v-if="project.registration.showLinkedin"
         class="linkedin-container mt-6 mb-6"
    >
        <v-btn :color="darkenPrimaryColor"
               class="linkedin-button"
               outlined
               @click="authorize"
        >
            <v-icon>
                mdi-linkedin
            </v-icon>
            Register with LinkedIn
        </v-btn>

        <div class="linkedin-divider">
            <span>or with email</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { createRandomString } from '@/services/LinkedInOauthService';

    export default {
        name: 'LinkedIn',
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['currentEvent', 'darkenPrimaryColor']),
        },
        methods: {
            authorizeUri(state) {
                return (
                    'https://www.linkedin.com/oauth/v2/authorization?' +
                    'response_type=code&' +
                    `client_id=${process.env.VUE_APP_LINKEDIN_OAUTH_CLIENT_ID}&` +
                    `redirect_uri=${process.env.VUE_APP_LINKEDIN_OAUTH_REDIRECT_URL}&` +
                    `state=${state}&` +
                    'scope=r_emailaddress%20r_liteprofile'
                );
            },
            authorize() {
                const state = createRandomString(40);
                window.sessionStorage.setItem('state', state);
                window.sessionStorage.setItem('event_id', this.currentEvent._id);
                window.location.href = this.authorizeUri(state);
            },
        }
    };
</script>

<style scoped lang="scss">
.linkedin-container {
    text-align: center;
}

.linkedin-button {
    color: #767676 !important;
    border: 1px solid #767676;
    border-radius: 50px !important;

    i {
        color: #0E76A8 !important;
    }
}

.linkedin-divider {
    width: 80%;
    color: #767676;
    border-bottom: 1px solid #767676;
    line-height: 0.1em;
    margin: 0 auto;
    padding-top: 2rem;

    span {
        background:#fff !important;
        padding:0 10px;
    }
}
</style>
