<template>
    <span>{{ startTime }}</span>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'StartTime',
        computed: {
            ...mapGetters('project', ['currentEvent']),
            ...mapState('project', ['project']),
            startTime() {
                const timeFull = this.currentEvent.startDateTimeFormattedFull;
                return timeFull.charAt(0).toUpperCase() + timeFull.slice(1);
            },
        },
    };
</script>
<style>
span{
  /*text-transform: capitalize;*/
}
</style>
