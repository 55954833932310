<template>
    <registration-page v-if="showDefaultRegistrationPage" />
    <dynamic-event-page v-else />
</template>

<script>
    import { mapState } from 'vuex';
    import RegistrationPage from '@/components/pages/RegistrationPage.vue';
    import DynamicEventPage from '@/components/pages/DynamicEventPage.vue';

    export default {
        name: 'RegistrationPageRoot',
        components: {
            RegistrationPage,
            DynamicEventPage,
        },
        computed: {
            ...mapState('project', ['project']),
            showDefaultRegistrationPage() {
                return this.project.dynamicBlocks.layout === 'basic' || this.$route.query.previewMode === 'default-registration';
            },
        },
    };
</script>
