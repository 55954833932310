<template>
    <div>
        <qld-quiz-qlive @feedback="showQuizFeedback" />
        <div v-if="show"
             class="confirmation-message"
        >
            {{ $t('event.poll_feedback') }}
            <v-btn icon
                   color="white"
                   @click="hideQuizFeedback"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { toastSuccess} from '@/services/responses';
    import {mapGetters} from 'vuex';
    export default {
        name: 'Quiz',
        data(){
            return {
                show: false
            };
        },
        computed: {
            ...mapGetters('registration', ['isRegisteredForPwa']),
        },
        methods:{
            showQuizFeedback(){
                if (this.isRegisteredForPwa) {
                    toastSuccess(this.$t('event.poll_feedback'));
                    return;
                }
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            },
            hideQuizFeedback(){
                this.show = false;
            }
        },
    };
</script>

<style scoped lang="scss">
@import 'src/_scss/components/_colors.scss';

.confirmation-message{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background: $success;
    border-radius: var(--card-border-radius);
    color: #fff;
    font-size: 14px;
}
</style>