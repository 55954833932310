<template>
    <div class="field-email">
        <v-text-field
            v-model="model"
            type="email"
            outlined
            :rules="requiredRule.concat(emailFormat)"
            :color="color"
            :background-color="backgroundColor"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldEmail',
        props: {
            value: {
                type: String,
                default: () => '',
            },
            color: {
                type: String,
                default: () => '',
            }, 
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        computed: {
            requiredRule() {
                return this.required ? [(v) => !!v || this.$t('fields.validation.required')] : [];
            },
            emailFormat() {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return [(v) => pattern.test(v) || this.$t('fields.validation.notEmail')];
            },
        },
        watch: {
            model(value) {
                this.$emit('input', value.toLowerCase());
            },
        },
    };
</script>

<style lang="scss">
.field-email{
    .v-input{
        border-radius: var(--input-border-radius) !important;
    }
}

</style>