<template>
    <v-row class="mx-0 end-page-component"
           :class="[classes, {'preview' : isInPreviewMode}]"
           justify="center"
           :style="cssVars"
    >
        <v-col v-if="!isMobile"
               class="content-col-end mx-0 pa-0 pa-lg-4"
               cols="12"
               :xl="9"
               :lg="8"
               :md="12"
        >
            <div class="end-content-wrapper">
                <h1 class="event-title-end">
                    {{ project.eventTitle }}
                </h1>
                <div v-if="!isMobile"
                     class="content-text-end"
                     v-html="endPageContent"
                />
            </div>
            <qld-controller-toggle class="lower-center-of-video"
                                   data-qld-name="elearning-course-btn"
            >
                <v-btn class="elearning-button mb-4"
                       :class="{ mobile: isMobile, 'ml-5': isMobile }"
                       :href="navigateToElearningCourseButtonHrefProcessed"
                       target="_blank"
                >
                    <span class="elearning-button-content">
                        {{ navigateToElearningCourseButtonTitle }}
                    </span>
                </v-btn>
            </qld-controller-toggle>
            <evaluation-button v-if="!hideEvaluationButton"
                               :blank="true"
                               class="mb-8"
            />
            <handout-button v-if="isHandoutVisible"
                            v-model="handouts"
                            class="end-page-handouts mb-8"
            />
            <custom-cta-button class="end-page-custom-cta mb-8" />
            <default-footer class="end-footer mt-lg-16" />
        </v-col>
        <v-col v-if="isMobile || showCrispChat"
               cols="12"
               xl="3"
               lg="4"
               md="12"
               class="end-page-side-bar"
               :style="mobileSidebarHeight"
        >
            <tab-headers v-model="tab" />
            <tab-contents :tab="tab" />
        </v-col>
    </v-row>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import EvaluationButton from './EvaluationButton';
    import HandoutButton from './HandoutButton';
    import CustomCtaButton from '@/components/templates/default/EventPage/CustomCtaButton.vue';
    import TabHeaders from '@/components/templates/default/EventPage/EndComponents/TabHeaders.vue';
    import TabContents from '@/components/templates/default/EventPage/EndComponents/TabContents.vue';
    import mixin from '@/components/templates/default/EventPage/Mixins/searchElearningThirdPartyConnection.js';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';

    export default {
        name: 'End',
        mixins: [mixin, trackUserLinkClicks],
        components: {
            TabContents,
            TabHeaders,
            CustomCtaButton,
            HandoutButton,
            DefaultFooter,
            EvaluationButton,
        },
        data() {
            return {
                tab: 'livecontent',
                headerHeight: 64,
                tabHeight: 50,
            };
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'getContent',
                'isMultiSession',
                'springerLook',
                'navigateToElearningCourseButtonHref',
                'navigateToElearningCourseButtonTitle',
                'isInPreviewMode',
                'darkenPrimaryColorRGB',
                'darkenPrimaryColor',
                'pwaDarkenPrimaryColorRGB',
                'customCtaButtonEnabled',
            ]),
            ...mapGetters('endPage', ['isChatVisible', 'isHandoutVisible']),
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            classes() {
                const result = {};
                if (this.isMobile) {
                    result['mobile'] = true;
                }
                return result;
            },
            hideEvaluationButton() {
                return this.project.evaluationButton.hide;
            },
            endPageContent() {
                return this.getContent(this.project.endPage.content);
            },
            handouts() {
                return this.project.endPage.handouts;
            },
            cssVars() {
                const darkened = this.isRegisteredForPwa ? this.pwaDarkenPrimaryColorRGB : this.darkenPrimaryColorRGB;
                return {
                    '--button-shadow-color': `rgba(${darkened.r},${darkened.g},${darkened.b}, 0.3)`,
                    '--button-text-color': this.project.style.textInsideButtonColorHex,
                };
            },
            showCrispChat() {
                return this.project.endPage.showCrispChat
                    && this.isChatVisible
                    && !this.isMultiSession
                    && this.project.events[0].crispChatEvent
                    && this.project.events[0].chatType === 'crisp';
            },
            mobileSidebarHeight() {
                if (!this.isMobile) {
                    return {};
                }

                return {
                    '--link-color': this.darkenPrimaryColor,
                    '--selected-tab-index': this.tab,
                    '--mobile-sidebar-height': this.headerHeight + this.tabHeight + 12 + 'px'
                };
            },
        },
        methods: {
            updateSidebarDimensions() {
                this.headerHeight = document.querySelector('.header').getBoundingClientRect().height;
                this.tabHeight = document.querySelector('.v-tabs')?.getBoundingClientRect().height ?? 0;
            }
        },
        mounted() {
            this.searchForElearningThirdPartyConnectionParams();
            window.addEventListener('resize', this.updateSidebarDimensions);
            this.updateSidebarDimensions();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateSidebarDimensions);
        }
    };
</script>

<style scoped lang="scss">
.end-page-component {

    .event-title-end {
        font-style: normal;
        font-weight: normal;
        font-size: 57px;
        line-height: 120%;
        margin-bottom: 16px;
        @media (max-width: 1366px) and (min-width: 961px) {
            font-size: 45px !important
        }
    }

    .content-text-end:hover {
        overflow-y: auto;
    }

    .content-col-end {
        align-items: center;
        justify-content: center;
        text-align: center;
        height: calc(100vh - 64px);
        overflow-y: auto;
    }

    .end-content-wrapper {
        margin: 0 152px;
        padding-bottom: 32px;
        padding-top: 20vh;
    }

    .end-footer {
        position: absolute;
        left: 1rem;
        bottom: 0;
        width: calc(100vw - 2rem)
    }

    .elearning-button {
        height: 44px !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        padding: 10px 32px;
        background: #f5f5f5;
        box-shadow: 0 8px 16px var(--button-shadow-color);

        .elearning-button-content {
            color: #3e3e3e !important;
        }
    }

    &.mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-around !important;
        min-height: calc(100vh - 64px);
        .event-title-end {
            font-weight: 500;
            font-size: 33px;
            color: white;
        }

        .end-content-wrapper {
            text-align: left;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 50px;
            margin-left: 19.5px;
            margin-right: 20px;
        }

        .elearning-button {
            height: 38px !important;
            align-self: flex-start;
            background: #ffffff;
            font-size: 12px;

            .elearning-button-content {
                color: #3e3e3e !important;
            }
        }
    }
}
</style>

<style lang="scss">
.end-page-component {
    .content-text-end {
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: white !important;
            @media (max-width: 1366px) and (min-width: 961px) {
                font-size: 16px !important
            }

            span {
                color: white !important;
            }
        }

        li {
            list-style-position: inside;
        }
    }

    .end-page-side-bar {
        padding-top: 0;
        background-color: #ffffff;
        z-index: 1;

        .tabs {
            display: none;
        }

        .v-tab {
            text-transform: none !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 120% !important;
            color: #3e3e3e !important;
            padding: 0 8px;
        }

        .qld-controller-tab {
            border-bottom: 1px solid rgba(79, 79, 79, 0.3) !important;
        }

        iframe {
            height: calc(100vh - 100px);
        }
    }

    &.mobile {
        .end-page-side-bar {
            padding-top: 12px;
            min-height: calc(100vh - var(--mobile-sidebar-height)) !important;

            .v-window-item {
                height: calc(100vh - var(--mobile-sidebar-height)) !important;
            }

            .tabs {
                display: initial;
                .tabs-col {
                    padding: 0;
                }
            }

            iframe {
                height: calc(100vh - var(--mobile-sidebar-height) - 20px)
            }

            p {
                a {
                    color: var(--link-color) !important;
                }
            }
        }
    }
}

.template-default:not(.pwa) .end-page-side-bar p {
    color: #3e3e3e;
}
</style>
