<template>
    <label :class="{checkmark: (isRegistered && ! onDemandActive), clickable: clickable}"
           @click="handleClick"
    >
        <v-progress-circular v-if="loading" 
                             indeterminate 
                             :size="isMobile ? 10 : 20"
                             class="loading"
        />
        <span v-else-if="!loading && (!isRegistered || onDemandActive) ">
            {{ buttonText }}
        </span>
        <v-icon v-else-if="!loading && isRegistered">mdi-check</v-icon>
    </label>
</template>

<script>
    import {mapGetters, mapState, mapActions} from 'vuex';
    import {navigate, timeUntilReactive, timeUntilToString} from '@/services/AgendaService';
    import {ROUTE_EVENT_WITH_PERSONAL_ID} from '@/router/constants';

    export default {
        name: 'AgendaStatusBreakout',
        props: {
            event: {
                type: Object,
                required: true 
            },            
            forceMobileView: {
                type: Boolean,
                default: () => false
            },
            startTime: {
                type: Number,
                required: true
            },
            now: {
                type: Boolean,
                required: true
            },
            ended: {
                type: Boolean,
                required: true
            },        
            isMobile: {
                type: Boolean,
                required: true
            },
            isCurrentEvent: {
                type: Boolean,
                required: true
            },
            isRegistered: {
                type: Boolean,
                required: true
            },        
            onDemandActive: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('time', ['currentTime']),
            ...mapGetters('registration', ['registrationId']),
            buttonText() {
                if (this.ended) {
                    return this.onDemandActive 
                        ? this.$t('multiSession.watch')
                        : this.$t('multiSession.ended');
                } else if (this.now) {
                    return this.isCurrentEvent
                        ? this.$t('multiSession.now')
                        : this.$t('multiSession.join');
                } else {
                    return this.isRegistered
                        ? timeUntilToString(timeUntilReactive(this.startTime, this.currentTime))
                        : this.$t('multiSession.register');
                }
            },
            clickable() {
                return (!this.ended && !this.isRegistered) || (this.ended && this.onDemandActive);
            },
        },
        methods: {
            ...mapActions('registration', ['registerForEvent']),
            async handleClick() {
                if (!this.clickable) {
                    return;
                }
                if(this.ended && this.onDemandActive){
                    this.navigate();
                    return;
                }
                this.loading = true;
                await this.register(this.now);
                if (this.now) {
                    this.navigate();
                    return;
                } 
                this.loading = false;
            },
            async register(isNavigating) {
                const data = {
                    event: this.event,
                    isNavigating: isNavigating
                };
                await this.registerForEvent(data);
            },
            navigate() {
                navigate(this.$router, ROUTE_EVENT_WITH_PERSONAL_ID, this.event._id, this.registrationId);
            },
        },
    };
</script>

<style scoped lang="scss">
.loading{
    margin-top: 2px;
}

.clickable {
    cursor: pointer;
}

</style>
