<template>
    <div v-if="loaded">
        <div v-for="(participant, index) in participants"
             :key="index"
             class="participant"
        >
            <v-avatar :color="randomColor()"
                      size="38"
            >
                <v-icon dark>
                    mdi-account-circle
                </v-icon>
            </v-avatar>
            <div class="participant-info">
                {{ `${participant.title} ${participant.firstName} ${participant.lastName}` }}
            </div>
        </div>
    </div>
</template>

<script>
    import { $http } from '@/services/http';
    import { get } from 'lodash';
    import { mapState } from 'vuex';

    export default {
        name: 'ParticipantsList',
        data() {
            return {
                loaded: false,
                participants: [],
            };
        },
        computed: {
            ...mapState('project', ['project']),
            url() {
                return process.env.VUE_APP_REQUEST_NETWORKING_REGISTRANTS_LIST.replace(
                    '{projectID}',
                    this.project._id
                );
            },
        },
        methods: {
            async getParticipants() {
                const response = await $http.get(this.url);
                this.participants = response.data.map((participant) => {
                    return {
                        title: get(participant, 'title', ''),
                        firstName: get(participant, 'first_name', ''),
                        lastName: get(participant, 'last_name', ''),
                    };
                });
                this.loaded = true;
            },
            randomColor(){

                return '#'+ Math.floor(Math.random()*16777215).toString(16);
            }
        },
        mounted() {
            this.getParticipants();
        },
    };
</script>

<style lang="scss" scoped>
.participant{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid #636363 ;
    padding-bottom: 10px;

    .participant-info{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
    }
}
</style>