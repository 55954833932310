<template>
    <div :style="{width: '100%', height: '100%'}">
        <iframe v-if="chatType === 'slido'"
                class="embedded-chat"
                :src="slidoUrl"
                frameborder="0"
        />
        <iframe v-if="chatType === 'crisp'"
                class="embedded-chat"
                :src="crispChatUrl"
                :style="{border: '0', width: '100%'}"
        />

    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'Chat',
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'chatType',
                'currentEvent',
                'isInPreviewMode',
            ]),
            ...mapState('registration', ['registrationData']),
            slidoUrl() {
                return 'https://app.sli.do/event/' + this.currentEvent.slidoEvent;
            },
            crispChatUrl() {
                if (this.isInPreviewMode) {
                    return '/crisp-chat-embed.html?website_id=' +
                        this.currentEvent.crispChatEvent +
                        '&user-nickname=PreviewUser' +
                        '&locale=' + this.locale;
                }

                const name = (this.registrationData.first_name ? this.registrationData.first_name + ' ' : '')
                    + this.registrationData.last_name + ' ';
                return (
                    '/crisp-chat-embed.html?website_id=' +
                    this.currentEvent.crispChatEvent +
                    '&user_email=' +
                    encodeURIComponent(this.registrationData.email) +
                    '&user_nickname=' +
                    encodeURIComponent(name) +
                    '&locale=' +
                    this.locale
                );
            },
            locale() {
                switch(this.project.language) {
                case 'nlFormal':
                case 'nlInformal':
                    return 'nl';
                default:
                    return 'en';
                }
            },
        },
        
    };
</script>

<style scoped>

</style>