<template>
    <div>
        <dynamic-page-header class="dynamic-header" />
        <div :key="`block-container-${blockContainerKey}`"
             class="block-container"
             :class="{ 'mobile': isMobile }"
        >
            <div v-for="(block, blockIndex) in blocks"
                 :key="`block-${block._id}`"
                 :style="{ 'order': blockIndex }"
                 class="block-wrapper"
                 :class="blockClass(block, isMobile)"
            >
                <div :ref="`block-${block._id}`"
                     class="block"
                     :style="blockStyle(block)"
                >
                    <div v-if="block.componentType === 'column'"
                         class="block-column"
                         :class="{'gradient': hasGradient(block)}"
                         :style="styles"
                    >
                        <div v-for="(subBlock, subBlockIndex) in block.componentOptions.blocks"
                             :key="`block-${subBlock._id}`"
                             class="block-wrapper"
                             :style="{ order: subBlockIndex }"
                             :class="blockClass(subBlock, isMobile)"
                        >
                            <div :ref="`block-${subBlock._id}`"
                                 class="block"
                                 :style="blockStyle(subBlock)"
                            >
                                <component
                                    :is="subBlock.componentType"
                                    class="block-content"
                                    :options="subBlock.componentOptions"
                                    :width="blockClass(subBlock, isMobile)"
                                    :gradient="hasGradient(subBlock)"
                                    :block-id="subBlock._id"
                                />
                            </div>
                        </div>
                    </div>
                    <component
                        :is="block.componentType"
                        v-else
                        class="block-content"
                        :width="blockClass(block, isMobile)"
                        :options="block.componentOptions"
                        :gradient="hasGradient(block)"
                        :block-id="block._id"
                    />
                </div>
            </div>
            <scroll-to-top-button />
            <dynamic-page-registration-form v-if="openRegistrationForm"
                                            @registered="openRegistrationConfirmation"
            />
            <registration-confirmation v-if="renderRegistrationConfirmation" />
        </div>
        <dynamic-page-footer />
    </div>
</template>

<script>
    import Speakers from '@/components/dynamic-blocks/Speakers';
    import CountDownBlock from '@/components/dynamic-blocks/CountDownBlock';
    import VideoContentBlock from '@/components/dynamic-blocks/VideoContentBlock';
    import Slideshow from '@/components/dynamic-blocks/Slideshow';
    import ImageContentBlock from '@/components/dynamic-blocks/ImageContentBlock';
    import TextContentBlock from '@/components/dynamic-blocks/TextContentBlock';
    import Spacer from '@/components/dynamic-blocks/Spacer';
    import ProgramElement from '@/components/dynamic-blocks/ProgramElement';
    import HeaderBlock from '@/components/dynamic-blocks/HeaderBlock';
    import DynamicPageRegistrationForm from '@/components/dynamic-page-elements/DynamicPageRegistrationForm';
    import DynamicPageFooter from '@/components/dynamic-page-elements/DynamicPageFooter';
    import DynamicPageHeader from '@/components/dynamic-page-elements/DynamicPageHeader';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import { ROUTE_REGISTRATION_CONFIRMATION} from '@/router/constants';
    import RegistrationConfirmation from '@/components/pages/RegistrationPage/RegistrationConfirmation';
    import {blockClass, blockStyle, dispatchLoaded, hasGradient} from '@/services/BlockHelper';
    import ScrollToTopButton from '@/components/dynamic-blocks/ScrollToTopButton.vue';
    import UpcomingEvents from '@/components/dynamic-blocks/UpcomingEvents.vue';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';

    export default {
        name: 'DynamicEventPage',
        components: {
            ScrollToTopButton,
            RegistrationConfirmation,
            Speakers,
            CountDownBlock,
            VideoContentBlock,
            Slideshow,
            ImageContentBlock,
            TextContentBlock,
            Spacer,
            ProgramElement,
            HeaderBlock,
            DynamicPageRegistrationForm,
            DynamicPageFooter,
            DynamicPageHeader,
            UpcomingEvents
        },
        mixins: [trackUserLinkClicks],
        data() {
            return {
                blockContainerKey: 0,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('dynamicBlocks', ['elementToScrollTo', 'blocksFromPreview', 'openRegistrationForm']),
            ...mapGetters('project', ['primaryColorRGB', 'currentEvent']),
            blocks() {
                return this.blocksFromPreview ?? this.project.dynamicBlocks.blocks.blocks;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            renderRegistrationConfirmation() {
                return this.$route.name === ROUTE_REGISTRATION_CONFIRMATION;
            },
            shouldRedirectToPersonalOndemand() {
                return this.$route.query.redirectToPersonalOnDemand === true;
            },
        },
        methods: {
            ...mapMutations('dynamicBlocks', ['setBlocksFromPreview', 'setElementToScrollTo', 'setOpenRegistrationForm']),
            ...mapActions('project', ['registerAction']),
            hasGradient,
            blockClass,
            blockStyle,
            async listenForBlocksUpdate(e) {
                if (
                    process.env.VUE_APP_DASHBOARD_ORIGIN.split(',').indexOf(
                        e.origin
                    ) < 0
                ) {
                    return;
                }
                const message = JSON.parse(e.data);
                if (message.event === 'qlive-app-blocks-updated') {
                    this.setBlocksFromPreview(message.blocks);
                    this.blockContainerKey++;
                    await this.$nextTick();
                    this.autoSizeWhenShorterThanViewPort();
                    dispatchLoaded(this.blocks, this.$refs);
                }
            },
            openRegistrationConfirmation(registrationId) {
                this.$router.push({
                    name: ROUTE_REGISTRATION_CONFIRMATION,
                    params: {
                        'event_id': this.currentEvent._id,
                        'registration_id': registrationId
                    }
                });
            },
            autoSizeWhenShorterThanViewPort() {
                const totalHeight = document.querySelector('.block-container').getBoundingClientRect().height;
                const headerHeight = document.querySelector('.event-page-header').getBoundingClientRect().height;
                const footerHeight = document.querySelector('footer').getBoundingClientRect().height;
                const wrapperElementHeight = headerHeight + footerHeight;
                const heightDiff = (window.visualViewport.height - wrapperElementHeight) - totalHeight;
                if (heightDiff > 0) {
                    let lastBlock = document.querySelector('.block-container > .block-wrapper:nth-last-child(2) > .block');
                    if (lastBlock.firstChild?.classList.contains('block-column')) {
                        lastBlock = lastBlock.firstChild.childNodes[lastBlock.firstChild.childNodes.length - 1];
                    }
                    if (lastBlock) {
                        lastBlock.style.height = (lastBlock.getBoundingClientRect().height + heightDiff) + 'px';
                    }
                }
            }
        },
        watch:{
            elementToScrollTo(value){
                if(value){
                    const elementPosition = this.$refs[value][0].getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - 80;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                    this.setElementToScrollTo(null);
                }
            }
        },
        updated() {
            this.autoSizeWhenShorterThanViewPort();
            dispatchLoaded(this.blocks, this.$refs);
        },
        async mounted() {
            await this.$nextTick();
            this.autoSizeWhenShorterThanViewPort();
            dispatchLoaded(this.blocks, this.$refs);
            window.addEventListener('message',this.listenForBlocksUpdate);
            this.registerAction('registration-page-view');
            if(this.$route.query.linkedin || this.shouldRedirectToPersonalOndemand){
                this.setOpenRegistrationForm(true);
            }
        },
        beforeDestroy() {
            window.removeEventListener('message', this.listenForBlocksUpdate);
        },
    };
</script>
