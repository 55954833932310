<template>
    <div class="field-star-rating">
        <v-input
            :value="model"
            :rules="required ? [(v) => v > 0 || $t('fields.validation.required')] : []"
        >
            <v-rating
                v-model="model"
                background-color="grey lighten-2"
                :color="color"
                hover
                length="5"
                size="35"
                :required="required"
                :rules="required ? [(v) => v > 0 || $t('fields.validation.required')] : []"
            />
        </v-input>
    </div>
</template>

<script>
    export default {
        name: 'FieldStarRating',
        props: {
            value: {
                type: Number,
                default: () => null,
            },
            name: {
                type: String,
                default: () => '',
            },
            color: {
                type: String,
                default: () => '',
            },           
            label: {
                type: String,
                default: () => '',
            },
            required: {
                type: Boolean,
                default: () => false,
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style scoped>
</style>
