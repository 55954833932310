<template>
    <v-row class="field-checkbox">
        <v-col v-for="option in options"
               :key="option.id"
               class="pt-0 pb-0 option-checkbox"
        >
            <v-checkbox
                :key="`option-${name}-${option.id}`"
                v-model="model"
                :label="option.text"
                :value="option.id"
                :name="`checkbox-${name}`"
                :rules="rules"
                :color="color"
                :background-color="backgroundColor"
            />
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'FieldCheckbox',
        props: {
            name:{
                type: String,
                default: () => ''
            },        
            value: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Array,
                default: () => [],
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
            color:{
                type: String,
                default: () => ''
            }
        },
        data() {
            return {
                model: [...this.value],
            };
        },
        computed: {
            rules() {
                return this.required
                    ? [
                        this.model.length > 0 ||
                            this.validationMessage
                    ]
                    : [];
            },
            validationMessage () {
                if (this.options.length < 2) {
                    return this.$t('fields.validation.required');
                }

                return this.$t('fields.validation.required_checkbox');
            },
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style>
.field-checkbox{
    display: block;
}
.field-checkbox .option-checkbox:not(:last-child) .v-messages {
    display: none;
}

.v-input--selection-controls {
    margin-top: 0;
}
</style>
