<template>
    <div class="main pwa-default-body"
         :class="{'gradient': backgroundGradient}"
         :style="styles"
    >       
        <slot /> 
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'DefaultBody',
        computed: {
            ...mapGetters('project', ['pwaBackgroundImage','pwaPrimaryColor', 'pwaPrimaryColorRGB', 'backgroundGradient']),
            ...mapState('project', ['project']),
            styles() {
                return {
                    'background-image': `url(${this.pwaBackgroundImage})`,
                    'background-attachment': 'fixed',
                    '--project-primary-color': this.project.pwaStyle.primaryColor,
                    '--r': this.pwaPrimaryColorRGB.r,
                    '--g': this.pwaPrimaryColorRGB.g,
                    '--b': this.pwaPrimaryColorRGB.b,
                };
            }
        }
    };
</script>

<style scoped lang="scss">

.main{
    &:before{
        background-color: rgba(var(--r), var(--g), var(--b), 0.4);
    }
}
.gradient{
    &:before{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),linear-gradient(314.24deg, rgba(0, 0, 0, 0.5) 22.26%, rgba(var(--r), var(--g), var(--b), 0.5) 65.06%) !important;
        transform: rotate(180deg);
    }
}
</style>