<template>
    <v-tabs
        :key="renditionKey"
        v-model="tab"
        :color="darkenPrimaryColor"
        show-arrows
        fixed-tabs
        grow
        class="tabs"
        :class="{ 'mt-3': !isMobile, 'mobile': isMobile}"
    >
        <v-row justify="center">
            <v-col cols="12"
                   lg="12"
                   class="tabs-col d-flex justify-center"
            >
                <div v-for="item in tabsList"
                     :key="item.name"
                     :class="{'qld-controller-tab': item.visibility}"
                >
                    <v-tooltip :disabled="isMobile">
                        <template v-slot:activator="{ on }">
                            <v-tab v-show="item.visibility"
                                   :href="'#' + item.name"
                            >
                                <v-badge v-if="item.badge"
                                         :value="item.active"
                                         right
                                         dot
                                         overlap
                                         offset-x="10"
                                         offset-y="15"
                                         :color="darkenPrimaryColor"
                                         transition="slide-x-transition"
                                >
                                    <v-icon :size="iconSize"
                                            class="my-2"
                                            v-on="on"
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </v-badge>
                                <v-icon v-if="!item.badge"
                                        :size="iconSize"
                                        class="my-2"
                                        v-on="on"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-tab>
                        </template>
                        <span>{{ item.label }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-tabs>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'TabHeaders',
        props: {
            value: {
                type: String,
                required: true,
            }
        }, 
        data() {
            return {
                tab: null,
                renditionKey: 0,
            };
        },
        watch: {
            availableTabs() {
                this.selectTab(this.tab);
                this.renditionKey++;
            },
            tab: {
                handler(value) {
                    this.$emit('input', value);
                },
            },
            active(newValue, oldValue){
                if(this.showPollTab && !oldValue && newValue){
                    this.selectTab('poll');
                }
            },
            isEvaluationButtonVisible(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.activateLiveContentTab();
                }
            },
            isCustomCtaButtonVisible(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.activateLiveContentTab();
                }
            }
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('endPage', [
                'isHandoutVisible',
                'isEvaluationButtonVisible',
                'isCustomCtaButtonVisible',
                'isChatVisible',
            ]),
            ...mapGetters('project', [
                'currentEvent',
                'darkenPrimaryColor',
                'chatType',
                'springerLook',
            ]),
            tabOrder() {
                if (this.currentEvent.tabOrder.length === 0) {
                    return ['livecontent', 'chat', 'handout'];
                }
                return this.currentEvent.tabOrder;
            },
            availableTabs() {
                let availableTabs = [];
                let map = new Map([
                    ['livecontent', this.isMobile],
                    ['handout', this.showHandoutTab],
                    ['chat', this.isChatVisible],
                ]);

                map.forEach((isVisible, key) => {
                    if (isVisible) {
                        availableTabs.push(key);
                    }
                });

                let sortedAvailableTabs = [];
                this.tabOrder.forEach(tab => {
                    if(availableTabs.indexOf(tab) !== -1){
                        sortedAvailableTabs.push(tab);
                    }
                });

                return sortedAvailableTabs;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            iconSize(){
                return 30;
            },
            tabsList() {
                return [
                    {
                        name: 'livecontent',
                        visibility: this.isMobile,
                        icon: 'mdi-information-outline',
                        label: this.$t('event.info'),
                    },
                    {
                        name: 'handout',
                        visibility: this.isHandoutVisible && this.isMobile,
                        icon: 'mdi-download-outline',
                        label: this.$t('event.handout'),
                    },
                    {
                        name: 'chat',
                        visibility: this.isChatVisible,
                        icon: 'mdi-message-text-outline',
                        label: this.$t('event.chat'),
                    },
                ].sort((a,b) => {
                    if (this.tabOrder.indexOf(a.name) > this.tabOrder.indexOf(b.name)) {
                        return 1;
                    }
                    if (this.tabOrder.indexOf(b.name) > this.tabOrder.indexOf(a.name)) {
                        return -1;
                    }
                    return 0;
                });
            },
        },
        methods: {
            selectTab(tab) {
                const selectedTab = this.availableTabs.indexOf(tab) !== -1
                    ? tab
                    : this.availableTabs[0];
                this.tab = selectedTab;
                this.$emit('input',
                           selectedTab);
            },
            activateLiveContentTab() {
                if (this.tab !== 'livecontent') {
                    this.tab = 'livecontent';
                }
            }
        },
        mounted() {
            this.selectTab(this.value || this.availableTabs[0]);
        },
    };
</script>

<style lang="scss">
.tabs-col {
    >div{
        border-bottom: 1px solid rgba(79, 79, 79, 0.3) !important;
    }    
}
.v-tab{
    min-width: 0 !important;
}

.qld-controller-tab{
  width: 80px;
}

.tabs{
    .mobile{
        margin-top: 12px;
        .qld-controller-tab{
            width: 60px;
        }
    }
}
</style>

