<template>
    <div class="agenda-wrapper"
         :class="{'event-page': isDynamicBlocksFlavourAgenda}"
         :style="labelBorderRadiusStyle"
    >
        <h4 v-if="!isDynamicBlocksFlavourAgenda"
            class="font-weight-regular agenda-time-interval text-left"
            :class="{ mobile: isMobile, 'tablet': isTablet }"
        >
            {{ event.agendaStartAndEndTime }}
        </h4>
        <label v-else
               :style="{...darkenPrimaryColorStyle,...textColorStyle, ...buttonBackgroundStyle}"
               class="time-badge agenda-time-interval text-left"
               :class="{ mobile: isMobile, 'tablet': isTablet }"
        >
            {{ event.agendaStartAndEndTime }}
        </label>

        <h4 class="text-left agenda-title pl-3"
            :class="{ mobile: isMobile, 'tablet': isTablet }"
        >
            <span v-if="isMultiSession">{{ event.name }}</span>
            <span v-else>{{ project.eventTitle }}</span>
            <a v-if="collapseBreakouts && !preventSwitchingRooms"
               class="ml-4 change-breakout-link"
               href="#"
               @click.prevent="unCollapse"
            >
                <span class="mr-1">{{ $t('multiSession.changeBreakout') }}</span>
                <v-icon v-if="collapsed"
                        dark
                        small
                >mdi-chevron-down</v-icon>
                <v-icon v-else
                        dark
                        small
                >mdi-chevron-up</v-icon>
            </a>
        </h4>
        <agenda-status-event v-if="showStatus"
                             :event="event"
                             :force-mobile-view="forceMobileView"
        />
    </div>
</template>

<script>
    import AgendaStatusEvent from '@/components/controls/Agenda/AgendaStatusEvent';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'AgendaSession',
        components: { AgendaStatusEvent },
        props: {
            event: {
                type: Object,
                required: true 
            },            
            forceMobileView: {
                type: Boolean,
                default: () => false,
            },
            showStatus: {
                type: Boolean,
                default: false,
            },
            collapseBreakouts: {
                type: Boolean,
                default: () => false
            },
            collapsed: {
                type: Boolean,
                default: () => true
            }
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForEvent']),
            ...mapGetters('project', [
                'preventSwitchingRooms',
                'textColorStyle',
                'darkenPrimaryColorStyle',
                'isDynamicBlocksFlavourAgenda',
                'buttonBackgroundStyle',
                'labelBorderRadiusStyle',
                'isMultiSession',
            ]),
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.forceMobileView;
            },
            isTablet(){
                return this.$vuetify.breakpoint.sm;
            },
        },
        methods:{
            unCollapse(){
                this.$emit('unCollapse');
            }
        }
    };
</script>

<style scoped lang="scss">
.agenda-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    h4 {
        font-weight: 900;
        font-size: 23px;

        &.agenda-time-interval {
            font-size: 15px;
            font-weight: 400;
            order: 1;
            line-height: 120% !important;

        &.mobile {
            flex: 0 0 100%;
            order: 4;
            line-height: initial;
            line-height: 150% !important;
        }
    }


    &.agenda-title {
        flex: 1 1 0;
        order: 2;
        line-height: 22px;

        &.mobile {
            font-size: 16px !important;
            padding-left: 0 !important;
            padding-top: 2px;
            display: inline-flex;
        }
        &.tablet {
            font-size: 20px !important;
            line-height: 20px;
        }

        .change-breakout-link {
            text-decoration: none;
            color: inherit !important;
            font-size: 14px;
            line-height: 120%;
            min-width: 127px;
            span {
                font-weight: normal;
                text-decoration: underline;
            }
            .v-icon {
                color: inherit !important;
            }
        }
    }
  }

    &.event-page{
        .time-badge {
            padding: 0 12px;
            height: 24px;
            border-radius: var(--label-border-radius);
            min-width: 170px;
            background: var(--button-background);
            color: var(--text-color);
            display: flex;
            justify-content: center;
            align-items: center;

            &.agenda-time-interval {
                font-size: 14px !important;
                line-height: 24px;
                font-weight: 900;
                order: 1;
                line-height: 120% !important;

                &.mobile {
                    order: 4;
                    line-height: initial;
                    line-height: 150% !important;
                }
            }
        }
        h4{
            &.mobile{
                &.agenda-title {
                    flex: 1 1 100% !important;
                }
            }
        }
    }
}
</style>
