<template>
    <div v-if="!loading">
        <vue-tel-input-vuetify ref="phone" 
                               v-model="phone"
                               required
                               :rules="requiredRule.concat(validPhoneNumberRule)"
                               class="phone-input"
                               placeholder=""
                               label=""
                               :preferred-countries="['NL']"
                               :background-color="backgroundColor"
                               :default-country="defaultCountry"
        />
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {$http} from '@/services/http';
    export default {
        name: 'FieldMobile',
        props: {
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                phone: '',
                phoneNumberValid: true,
                currentIp: '',
                loading: false,
            };
        },

        computed:{
            ...mapState('project', ['project']),
            requiredRule() {
                return this.required ? [(v) => !!v || this.$t('fields.validation.required')] : [];
            },
            validPhoneNumberRule(){           
                return [() =>  this.phoneNumberValid || this.$t('fields.validation.notPhoneNumber')];
            },
            defaultCountry() {
                if (this.currentIp === '' || !this.restrictedCountryResolutionIps.includes(this.currentIp)) {
                    console.log('no currentIp or not blacklisted');
                    return null;
                }
                return 'NL';
            },
            restrictedCountryResolutionIps() {
                return process.env.VUE_APP_COUNTRY_RESOLUTION_IP_BLACKLIST.split(',');
            }
        },
        watch:{
            phone(){
                const phoneInput = this.$refs.phone;
                if(this.phone){
                    this.phoneNumberValid = phoneInput.phoneObject.isValid;
                } else {
                    this.phoneNumberValid = true;
                }
                this.$emit('input', phoneInput.phoneObject.number.international);
            }
        },
        methods: {
            async getVisitorIPAddress() {
                try {
                    const {data} = await $http.get(process.env.VUE_APP_IP_CHECK_URL);
                    return data.clientIp;
                } catch (e) {
                    return '';
                }
            },
        },
        async created() {
            this.loading = true;
            try {
                this.currentIp = await this.getVisitorIPAddress();
            } catch (e) {
                this.currentIp = '';
            }
            this.loading = false;
        }
    };
</script>

<style lang="scss">

.mobile-input-text{
    color: rgba(0,0,0,.87) !important;
    font-style: italic;
    font-size: 13px;
}

.phone-input{
    .v-text-field{
        margin-top: 0;
        padding-top: 0;
    }

    .country-code{
        .v-input{
            border-top-left-radius: var(--input-border-radius);
            border-bottom-left-radius: var(--input-border-radius);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .v-text-field{
            border-top-right-radius: var(--input-border-radius);
            border-bottom-right-radius: var(--input-border-radius);
    }

    .v-input__slot{
        border: 1px solid rgba(0,0,0,.40) !important ;
        min-height: 40px !important;
        margin-bottom: 0 !important;
    }
    .v-text-field__slot{
        margin-left: 10px;
    }

    .v-messages__message{
        padding-top: 1px !important;
    }

    .v-input__slot::before{
        border: none !important;
    }
}

.v-application{
    .phone-input{
        .error--text{
            .v-input__slot{
                border: 1px solid #ee3a23 !important
            }
        }
    }
}

</style>
