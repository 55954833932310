export function replaceFavicon(url) {
    let faviconElement = document.head.querySelector('link[rel="icon"]');
    if (!faviconElement) {
       createFaviconElement();
       return;
    }

    faviconElement.setAttribute('href', url);
}

function createFaviconElement(url) {
    const element = document.createElement('link');
    element.setAttribute('rel', 'icon');
    element.setAttribute('href', url);
    document.head.appendChild(element);
}