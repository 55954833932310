<template>
    <div>
        <password-dialog ref="secureDataRequest" />
        <v-row v-if="onDemandID !== '-password-protected-'"
               class="fullHeight mx-0 on-demand-page"
               :class="classes"
               justify="center"
        >
            <v-col cols="12"
                   :xl="xl"
                   :lg="lg"
                   :md="md"
                   :sm="sm"
                   class="py-0 px-2 on-demand-wrapper-col"
            >
                <div class="on-demand-wrapper">
                    <h4 class="on-demand-title mb-3 no-margin-top mt-16i">
                        {{ project.eventTitle }}
                        <span v-if="isMultiSession">
                            | {{ currentEvent.name }}
                        </span>
                    </h4>
                    <div id="onDemandVideoPlayer"
                         :class="playerClass"
                         class="on-demand-video-player"
                    >
                        <component
                            :is="'script'"
                            v-if="!registrationId"
                            :src="playerURL"
                            :data-video-id="onDemandID"
                            async
                        />
                        <component
                            :is="'script'"
                            v-else
                            :src="playerURL"
                            :data-video-id="onDemandID"
                            :data-registration-id="registrationId"
                            async
                        />
                    </div>
                    <default-footer class="end-footer" />
                </div>
            </v-col>
            <v-col v-if="shouldShowOndemandSidebar"
                   cols="12"
                   xl="3"
                   lg="4"
                   md="4"
                   sm="5"
                   class="on-demand-right-sidebar"
                   :style="{...textColorStyle, ...darkenPrimaryColorStyle, ...buttonShadowColorStyle}"
            >
                <v-card class=" mt-5 mb-5">
                    <v-card-text>
                        <div class="content-text-on-demand"
                             v-html="onDemandPageContent"
                        />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions v-if="customCtaButtonEnabled">
                        <v-spacer />
                        <div class="text-center">
                            <custom-cta-button :color="darkenPrimaryColor"
                                               :style="textColorStyle"
                            />
                        </div>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import PasswordDialog from '@/components/templates/default/EventPage/PasswordDialog';
    import {ROUTE_REGISTRATION} from '@/router/constants';
    import {isInIframe} from '@/services/Integration';
    import CustomCtaButton from '@/components/templates/default/EventPage/CustomCtaButton.vue';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';


    export default {
        name: 'OnDemand',
        components: {CustomCtaButton, PasswordDialog, DefaultFooter},
        mixins: [trackUserLinkClicks],
        data() {
            return {
                playerScale: 'normal',
            };
        },
        props: {
            registrationId: {
                type: String,
                default: null,
            },
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'getContent',
                'isMultiSession',
                'requiresRegistrationForOnDemand',
                'isInPreviewMode',
                'customCtaButtonEnabled',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'buttonShadowColorStyle',
                'textColorStyle',
            ]),
            ...mapState('project', ['project']),
            onDemandID() {
                return this.currentEvent.onDemandID;
            },
            playerURL() {
                return process.env.VUE_APP_PLAYER_URL;
            },
            playerClass() {
                const classes = [`playerscale-${this.playerScale}`, 'player-container'];
                if (this.isInIframe) {
                    classes.push('no-margin');
                }
                return classes;
            },
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown;
            },
            classes() {
                return {
                    'mobile': this.isMobile
                };
            },
            onDemandPageContent(){
                return this.getContent(this.currentEvent.onDemandPageContent);
            },
            shouldShowOndemandSidebar() {
                return !!this.onDemandPageContent || this.customCtaButtonEnabled;
            },
            xl() {
                return this.shouldShowOndemandSidebar ? '9' : '9';
            },
            lg() {
                return this.shouldShowOndemandSidebar ? '8' : '8';
            },
            md() {
                return this.shouldShowOndemandSidebar ? '8' : '8';
            },
            sm() {
                return this.shouldShowOndemandSidebar ? '7' : '7';
            },
            isInIframe,
        },
        methods: {
            ...mapMutations('project', ['setSecureData']),
            onForceFullScreen(p, v) {
                const header = document.querySelector('.header');
                if (v) {
                    if (this.isMobile && header) {
                        header.classList.remove('d-flex');
                        header.style.display = 'none';
                    }

                } else {
                    if (this.isMobile && header) {
                        header.classList.add('d-flex');
                        header.style.display = 'flex';
                    }
                }
            },
        },
        async mounted() {
            if (this.requiresRegistrationForOnDemand && !this.registrationId) {
                if (this.isInPreviewMode) {
                    return;
                }
                await this.$router.push({
                    name: ROUTE_REGISTRATION,
                    params: {
                        event_id: this.currentEvent._id,
                    },
                    query: {
                        redirectToPersonalOnDemand: true,
                    }
                });
                return;
            }
            if (this.project.security.passwordLivePageActive) {
                const protectedData = await this.$refs.secureDataRequest.open();
                this.setSecureData(protectedData);
            }

            const t = setInterval(() => {
                if (window.QuadiaPlayer) {
                    window.QuadiaPlayer.$on('forceFullscreen', this.onForceFullScreen);
                    clearInterval(t);
                }
            }, 10);
        },
        beforeDestroy() {
            window.QuadiaPlayer.$off('forceFullscreen', this.onForceFullScreen);
        }
    };
</script>

<style scoped lang="scss">
.on-demand-page {
    padding-top: 12px;
    margin-bottom: 0;

    .on-demand-title {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 120%;
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .content-text-on-demand {
        color: #3e3e3e;
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px !important;
            line-height: 150%;
            margin-bottom: 0 !important
        }
        li {
            list-style-position: inside;
        }
    }

    .content-text-on-demand:hover {
        overflow-y: auto !important;
    }

    .on-demand-video-player {
        margin-bottom: 24px;
        @media screen and (max-width: 640px) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .on-demand-wrapper {
        margin: 0 0;
        padding: 0 25px 2vh 25px;
    }

    @media screen and (max-width: 959px) {
        .on-demand-wrapper-col {
            height: auto;
        }
    }

    &.mobile {
        .on-demand-wrapper {
            margin: 40px 20px 0 20px;
        }

        h4.on-demand-title {
            font-size: 23px;
        }
    }

    .on-demand-wrapper.no-margin {
        margin: 0;
        padding: 0;
    }
}
</style>

<style lang="scss">
.on-demand-page {
    .no-margin {
        margin-bottom: 0 !important;
    }

    &.mobile {
        .content-text-on-demand {
            overflow: auto;
            p {
                font-size: 14px;
            }
        }
    }

    .on-demand-right-sidebar {
        min-height: calc(100vh - 64px);
        background-color: #ffffff;
        p, a, ul, li, ol, dd, dt, div, h1, h2, h3, h4, h5, h6, td {
            color: #3e3e3e !important;
        }

        p, a, ul, li, ol, dd, dt, td, div {
            font-size: 14px !important;
        }

        button.custom-cta span.v-btn__content {
            color: var(--text-color) !important;
        }
    }
}
</style>
