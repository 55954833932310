import { nl } from 'vuetify/es5/locale';

export default {
    ...nl,
    registration: {
        title: 'Registreer',
        submit: 'Doorgaan',
        join: 'Meedoen',
        accept_terms_1: 'Wij verwerken jouw persoonsgegevens. Lees in onze ',
        accept_terms_2: 'privacy policy',
        accept_terms_3: ' hoe wij dit doen.',
        thanks: 'Bedankt voor jouw registratie!',
        watch: 'Ga naar het event',
        watch_pwa: 'Open web app',
        personal_link_advice: 'Dit is jouw persoonlijke link. Deel deze link niet of stuur deze email niet door.',
        add_to_calendar: 'Voeg toe aan agenda',
        loading: 'Laden...',
        speakers: 'Sprekers',
        confirmation_title: 'Registratie voltooid.',
        simplesession_access: 'Ga voor meer informatie naar het event.',
        multisession_access: 'Ga voor meer informatie of het wijzigen van breakout rooms naar het event.',
        pwa_access: 'Open alvast de web app om mee te doen aan de interactie tijdens het event.',
        event_full: 'Deze sessie is momenteel vol',
        cannot_register_start: 'U kunt zich helaas niet registreren voor ',
        cannot_register_end_singular: 'Het event is vol.',
        cannot_register_end_plural: 'Deze events zijn vol.',
        view_online: 'Ik zal het evenement online bijwonen',
        view_offline: 'Ik zal het evenement bijwonen op de locatie',
        seats_remaining: 'Er zijn nog {$x} plaatsen beschikbaar',
        one_seat_remaining: 'Er is nog 1 plaats beschikbaar',
        unregister: 'Uitschrijven',
        unregistered: 'Je hebt je succesvol uitgeschreven.',
        unregister_title: 'Uitschrijven voor dit event.',
        already_unregistered: 'Je bent uitgeschreven voor dit event.',
        change_attendance_title_online: 'Aanwezigheid aanpassen naar online',
        change_attendance_title_on_site: 'Aanwezigheid aanpassen naar op locatie | {$x} plaatsen beschikbaar',
        change_attendance_title_on_site_one_seat: 'Aanwezigheid aanpassen naar op locatie | 1 plaats beschikbaar',
        change_attendance_button: 'Aanwezigheid aanpassen',
        change_attendance_information: 'Je zal een nieuwe bevestigingsmail ontvangen met informatie over het event.',
        attendance_changed_to_online: 'Aanwezigheid gewijzigd naar: online.',
        attendance_changed_to_on_site: 'Aanwezigheid gewijzigd naar: op locatie.',
        agreement: 'Ik ga ermee akkoord dat mijn persoonlijke gegevens verwijderd worden en dat ik geen toegang meer heb tot het event.',
    },
    agenda: {
        program: 'Programma',
        breakout_round: 'Breakout ronde',
        comingUpNext: 'Coming up next',
    },
    evaluation: {
        submit: 'Verzenden',
        resubmit: 'Update je antwoord',
        processing: 'Een ogenblik geduld, evaluatie wordt verstuurd ...',
        thanks: 'De evaluatie is verstuurd. Bedankt voor het invullen!',
        you_can_leave: 'Je kunt dit scherm nu afsluiten.',
        you_can_leave_pwa: 'Je verlaat dit scherm nu automatisch.',
    },
    splash: {
        test: 'Test uw audio en video'
    },
    test: {
        title: 'Beeld en geluid testen',
        video_url: 'https://s3.eu-west-1.amazonaws.com/video.quadia.net/public/622b17bc1fd3c9184f796144/18d1a970-e313-473d-8a84-b5044eb28951-hls.m3u8',
        content: '<p>Geen geluid? Controleer dan of het geluid van de video gedempt is. Het geluidspictogram linksonder bevat dan een kruis. Klik hier nogmaals op om het geluid te activeren. Toch nog een vraag? Bekijk onze <a href="#support">ondersteuningspagina</a>.</p>'
    },
    event: {
        LIVE: 'LIVE',
        ask_a_question: 'Stel een vraag',
        poll: 'Opinie',
        no_poll: 'Hier verschijnen straks de pollvragen',
        chat: 'Chat',
        evaluation_button: 'Evaluatieformulier',
        unmute_text: 'Geluid aan',
        quiz: 'Quiz',
        no_quiz: 'Hier verschijnen straks de quizvragen',
        info: 'Info',
        handout: 'Handout',
        home: 'Home',
        polls: 'Poll vragen',
        evaluation: 'Evaluatie',
        download_handout_question: 'Weet u zeker dat u wilt doorgaan met downloaden?',
        download_handout_title: 'Download handout',
        cancel_download: 'Annuleren',
        proceed_download: 'Doorgaan',
        poll_feedback: 'Uw antwoord is verwerkt.',
        question_submitted: 'Uw vraag is verzonden.',
        question_message_placeholder: 'Schrijf je bericht hier',
    },
    countdown: {
        text: 'We beginnen over',
        days: 'dagen',
        hours: 'uur',
        minutes: 'minuten',
        seconds: 'seconden',
        is_about_to_start: 'We beginnen over enkele ogenblikken. Je wordt automatisch doorgestuurd.',
        now: 'Dit event is al begonnen.',
        ended: 'Dit event is afgelopen',
    },
    layout: {
        support: 'Ondersteuning'
    },
    fields: {//registration & evaluation
        choose_answer: 'Kies een antwoord',
        enter_answer: 'Vul een antwoord in',
        validation: {
            required: 'Verplicht veld',
            required_checkbox: 'Er moet minimaal één item worden geselecteerd',
            notEmail: 'Ongeldig e-mail',
            notPhoneNumber: 'Ongeldig telefoonnummer',
            fixedLength: 'Dit veld moet {length} tekens lang zijn',
            notANumber: 'Dit veld moet een positief geheel getal zijn',
            notBetween: 'Dit veld moet tussen {min} en {max} liggen',
        },
        mobile_text: 'voor een eenmalige herinnering'
    },
    supportModal: {
        title: 'Veel gestelde vragen',
        sound: {
            title: 'Geluid',
            question_1: 'Ik hoor geen geluid',
            answer_1: 'Voorafgaand aan onze uitzendingen wordt muziek afgespeeld. Hiermee kun je het volume van jouw speaker instellen.',
            answer_1_subq_1: 'Externe boxen',
            answer_1_suba_1: 'Mocht je externe speakers gebruiken, zorg dan dat deze goed aangesloten zijn.',
            answer_1_subq_2: 'Stream instelling',
            answer_1_suba_2: 'Jouw browser heeft wellicht automatisch het geluid gedempt. Je kunt links onderin de mediaspeler het volume regelen met het geluidssymbool.',
            answer_1_subq_3: 'Systeem instelling',
            answer_1_suba_3: 'Controleer of het geluid van jouw pc niet gedempt is.',
            question_2: 'Ik hoor een echo',
            answer_2: 'De uitzending is misschien meerdere keren geopend in jouw browser, bijvoorbeeld in meerdere vensters of tabs. Sluit de tabbladen en browservensters die niet worden gebruikt voor het bekijken van deze uitzending.',
        },
        vision: {
            title: 'Beeld',
            question_1: 'De mediaspeler laat alleen een logo zien',
            answer_1: 'Dit probleem kan veroorzaakt worden door:',
            answer_1_subq_1: 'VPN',
            answer_1_suba_1: 'Als je een Virtual Private Network (VPN) heeft aanstaan kan deze de mediaspeler blokkeren, zet deze tijdelijk uit.',
            answer_1_subq_2: 'Citrix',
            answer_1_suba_2: 'Citrix is een applicatie die een computeromgeving simuleert via een server, vaak logt je hierop in. Als je gebruik maakt van Citrix moet deze uitgeschakeld/uitgelogd worden om de uitzending te kunnen bekijken.',
            answer_1_subq_3: 'Adblocker',
            answer_1_suba_3: 'Als je Adblocker hebt aanstaan kan deze de mediaspeler blokkeren, zet deze tijdelijk uit.',
            answer_1_subq_4: 'Browser',
            answer_1_suba_4: 'Check of jouw browser up to date is. Oudere browsers kunnen de mediaspeler niet ondersteunen.',
            question_2: 'De mediaspeler laat een zwart beeld zien (met een foutcode)',
            answer_2_part_0: 'Dit kan verschillende oorzaken hebben:',
            answer_2_part_1: 'Controleer of je browser voldoet aan de minimale eisen',
            answer_2_part_2: 'Je internetverbinding is niet stabiel of te langzaam. Controleer je verbinding en laad de pagina opnieuw',
            answer_2_part_3: 'De netwerkomgeving waar je je bevindt blokkeert het laden van de player. Neem contact op met je netwerkbeheerder',
        },
        network: {
            title: 'Netwerk',
            question_1: 'Het signaal is blokkerig, stottert of buffert veel',
            answer_1_part_1: 'Het signaal kan worden verstoord door een trage Internetverbinding. Zorg ervoor dat je over voldoende bandbreedte beschikt en gebruik bij voorkeur een snelle wifi- of kabelverbinding. Een minimum van 10 download mbps is aan te raden. Je kunt jouw internetsnelheid controleren door middel van een ',
            answer_1_part_2: 'https://www.speedtest.net/',
            answer_1_part_3: 'speedtest.',
            question_2: 'Kan ik een uitzending bekijken vanuit een kantoor / praktijk / ziekenhuisomgeving?',
            answer_2: 'Uit veiligheidsoverwegingen hebben veel bedrijven en ziekenhuizen toegang tot livestreams beperkt. Raadpleeg jouw ICT-afdeling om te controleren of er geen restricties liggen op het kijken naar live uitzendingen.'
        },
        browsers: {
            title: 'Browsers',
            subtitle: 'Je kunt de uitzending goed volgen met de volgende ondersteunde browsers:',
            question_1: 'Aanbevolen browserversie',
            answer_1_part_1: 'Google Chrome 88+',
            answer_1_part_2: 'Mozilla Firefox 85+',
            answer_1_part_3: 'Microsoft Edge 88+',
            answer_1_part_4: 'Apple Safari 14+',
            answer_1_part_5: 'iOS: Mobile Safari -of- Chrome (iOS14+)',
            answer_1_part_6: 'Android: Chrome 88+',
            question_2: 'Minimale browserversie',
            answer_2_part_1: 'Google Chrome 77+',
            answer_2_part_2: 'Mozilla Firefox 68+',
            answer_2_part_3: 'Microsoft Edge 44+',
            answer_2_part_4: 'Apple Safari 12+',
            answer_2_part_5: 'iOS: Mobile Safari -of- Chrome (iOS10+)',
            answer_2_part_6: 'Android: Chrome 77+',
        },
        system: {
            title: 'Systeem',
            question_1: 'Systeemspecificaties',
            answer_1_part_1: 'Minimale besturingssystemen:',
            answer_1_part_1_suba_1: 'Windows: 32-bits of 64-bits Windows 7 (SP1), Windows 8, Windows 8.1, Windows 10 of Windows 11',
            answer_1_part_1_suba_2: 'Android: vanaf 8.0 (OREO)',
            answer_1_part_1_suba_3: 'Mac: OS-versie: Metal vereist. macOS X 10.11 (El Capitan)',
            answer_1_part_1_suba_4: 'iOS: vanaf 10',
            answer_1_part_2: 'Aanbevolen besturingssystemen:',
            answer_1_part_2_suba_1: 'Windows: 64-bits Windows 10',
            answer_1_part_2_suba_2: 'Android: 10',
            answer_1_part_2_suba_3: 'Mac: OS-versie: Metal vereist. macOS X 10.14 (Mojave)',
            answer_1_part_2_suba_4: 'iOS: 12',
        },
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        overview: {
            title: 'Algemeen',
            content: 'In deze privacyverklaring lees je hoe wij met jouw persoonsgegevens omgaan. Je mag erop vertrouwen dat wij dat op een zorgvuldige manier doen. Om die reden vragen wij alleen om gegevens die wij nodig hebben om je een optimale ervaring tijdens de live uitzending te bieden. Wij zullen nooit zonder jouw toestemming jouw gegevens doorgeven aan derden.'
        },
        liveBroadcast: {
            title: 'Live uitzending',
            content: 'Als je je inschrijft voor en deelneemt aan een live uitzending geef je ons toestemming om jouw persoonsgegevens op te slaan. Dit doen wij om mensen toegang te kunnen geven aan de uitzending. Daarnaast kunnen wij hiermee het aantal kijkers voorspellen en je eraan herinneren wanneer een uitzending begint.',
        },
        cookies: {
            title: 'Cookies',
            content: 'Om de live uitzending te kunnen bekijken wordt er gebruik gemaakt van de Motionmakers Online Videoplayer. Deze player maakt gebruik van functionele en analytische cookies. De functionele cookies zijn benodigd voor de werking van de videoplayer. Denk bijvoorbeeld aan het onthouden van het volume of andere functionaliteiten van de videoplayer. De analytische cookies worden gebruikt om bij te houden welke kijkers, hoeveel kijkers er zijn en hoe lang iedere kijker de uitzending volgt.'
        },
        savedData: {
            title: 'Gegevens die wij opslaan',
            content: 'We bewaren alle gegevens die in het inschrijfformulier gevraagd worden: voornaam, achternaam, e-mailadres, IP-adres en open vragen. Daarnaast kun je tijdens de uitzending vragen stellen. Deze vragen met bijbehorend e-mailadres worden ook opgeslagen.'
        },
        retentionPeriod: {
            title: 'Bewaartermijn',
            content: 'Alle gegevens worden na beëindigen van de live uitzending maximaal 6 maanden opgeslagen. Alle informatie wordt daarna automatisch vernietigd.'
        },
        dataAccess: {
            title: 'Inzage gegevens',
            content: 'De gegevens kunnen alleen ingezien worden door Motionmakers en door de opdrachtgever van de uitzending. Alle gegevens worden veilig bewaard en zijn niet toegankelijk voor derden.'
        },
        contact: {
            title: 'Contact',
            content: 'Voor vragen en of opmerkingen kun je een mail sturen naar <a href="mailto:privacy@motionmakers.nl">privacy@motionmakers.nl</a> of via de onderstaande gegevens:'
        },
        contactAddressLine1: 'Motionmakers',
        contactAddressLine2: 'T.a.v: Privacy team',
        contactAddressLine3: 'Frits Spitsstraat 3',
        contactAddressLine4: '1217 WC Hilversum',
        contactAddressLine5: 'Nederland',
        contactAddressLine6: '+31 35 77 33 100',
        contactAddressLine7: 'info@motionmakers.nl'
    },
    termsAndConditions: {
        title: 'Algemene voorwaarden',
    },
    security: {
        title: 'Deze pagina is beveiligd',
        enterPassword: 'Vul het wachtwoord in, dat door de organisator aan jou verstrekt is.',
        error: 'Het wachtwoord dat je hebt ingevuld is onjuist. Let op: wachtwoord is hoofdlettergevoelig.',
        capsLockOn: 'Waarschuwing Caps lock staat AAN',
        button: 'Kijken',
    },
    singleSession: {
        alreadyWatching: 'Deze unieke kijklink is al geopend in een andere browser. Heb je de link met niemand gedeeld? Controleer dan of de link al in een ander venster of op een ander apparaat  geopend is.',
    },
    multiSession: {
        selectBreakout: 'Ik kies deze breakout sessie',
        changeBreakout: 'Kies breakout sessie',
        subscribingFor: 'Ik wil mij registeren en kies:',
        breakoutRound: 'Breakout ronde',
        breakoutRoundValidationText: 'Kies een sessie voor elke breakout ronde.',
        register: 'Registreren',
        ended: 'Beëindigd',
        now: 'NU',
        join: 'MEEDOEN',
        watch: 'KIJKEN',
        autoNavigationTitle: 'Deze sessie is afgelopen.',
        autoNavigationText: 'Je wordt automatisch doorgestuurd naar de volgende sessie {{session}} over <b>{{countdownSeconds}} seconden</b>.',
        autoNavigationNavigateNowButton: 'Ga naar de volgende sessie',
        sessionWillEndIn: 'Deze sessie eindigt over <b>{{countdown}}</b> seconden.',
        sessionWillEndSoon: 'Deze sessie zal spoedig eindigen.',
        breakoutWillEndIn: 'Deze break-out eindigt over <b>{{countdown}}</b> seconden.',
        breakoutWillEndSoon: 'Deze break-out zal spoedig eindigen.',
    },
    calendar: {
        downloaded_text: 'Het agenda bestand is gedownload. Controleer uw downloads.',
        click_to_download: 'Klik hier om opnieuw te downloaden',
        cta_before_link: 'Klik hier om deel te nemen',
        month1: 'januari',
        month2: 'februari',
        month3: 'maart',
        month4: 'april',
        month5: 'mei',
        month6: 'juni',
        month7: 'juli',
        month8: 'augustus',
        month9: 'september',
        month10: 'october',
        month11: 'november',
        month12: 'december',
        dayPlaceholder: 'dag',
        monthPlaceholder: 'maand',
        yearPlaceholder: 'jaar',
    },
    loginPage: {
        welcome: 'Welkom',
        loginFormInstruction: 'Indien je zich al heeft geregistreerd, vul dan hieronder uw e-mailadres in:',
        emailAddress: 'E-mailadres:',
        loginFormSubmitText: 'Log in',
        registrationLinkInstruction: 'Indien je nog niet geregistreerd bent voor dit event, vul dan het registratieformulier in.',
        registrationButtonText: 'Registreer',
        loginError: 'Er is geen registratie bekend met dit e-mailadres. Indien u nog niet geregistreerd bent, vul dan het registratieformulier in.',
    }
};
