<template>
    <div>
        <live-director-question :additional-data="dataToSend"
                                :custom-message-placeholder="$t('event.question_message_placeholder')"
                                :class="questionClasses"
                                @submitted="questionSubmitted"
        />
        <div v-if="show"
             class="confirmation-message mt-5"
        >
            {{ $t('event.question_submitted') }}
            <v-btn icon
                   color="white"
                   @click="hideFeedback"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import LiveDirectorQuestion from '@/components/templates/default/EventPage/LiveDirectorQuestion';
    import {mapGetters, mapState} from 'vuex';
    import { toastSuccess} from '@/services/responses';

    export default {
        name: 'Question',
        components: {LiveDirectorQuestion},
        data(){
            return {
                show: false
            };
        },
        computed: {
            ...mapState('registration', ['registrationData']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            ...mapGetters('project', [
                'shouldAttachRegistrationDataToAskAQuestionComponent',
                'isOpenRegistrationType',
                'isHybrid'
            ]),
            dataToSend() {
                if (this.shouldAttachRegistrationDataToAskAQuestionComponent) {
                    const data = {...this.registrationData};
                    delete data['SELECTED_EVENTS'];
                    delete data['created_at'];
                    delete data['_id'];

                    return data;
                }

                return null;
            },
            questionClasses() {
                return {
                    'with-registration-data': this.shouldAttachRegistrationDataToAskAQuestionComponent,
                    'show-email': this.isOpenRegistrationType && this.isHybrid
                };
            }
        },
        methods:{
            questionSubmitted(){
                if (this.isRegisteredForPwa) {
                    toastSuccess(this.$t('event.question_submitted'));
                    return;
                }
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            },
            hideFeedback(){
                this.show = false;
            }
        }
    };
</script>

<style scoped lang="scss">
@import 'src/_scss/components/_colors.scss';

.confirmation-message{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background: $success;
    border-radius: var(--card-border-radius);
    color: #fff;
    font-size: 14px;
}
</style>