const uuidv1 = require('uuid/v1');

export default {
    looksToBeValidRegistrationID(value) {
        return typeof value === 'string'
            && value.length === 24
            && (/^[a-z_\-\d]+$/i).test(value);
    },
    uid() {
        const storage = window.sessionStorage;
        const key = 'fake-registration-id';
        var uidFromStore = storage.getItem(key);
        var uid = uidFromStore ? uidFromStore : uuidv1();
        storage.setItem(key, uid);
        return uid;
    },
};
