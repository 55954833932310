<template>
    <v-tabs-items v-model="tab"
                  :style="cssVars"
    >
        <v-tab-item
            id="home"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <about-event />
            <v-card v-if="hasSpeakers"
                    class="pa-4 mb-5 content-card"
                    elevation="5"
                    color="#f5f5f5"
            >
                <speakers />
            </v-card>
            <v-card
                v-if="hasTopics"
                color="#f5f5f5"
                class="pa-4 content-card"
                elevation="5"
            >
                <program-element />
            </v-card>
        </v-tab-item>
        <v-tab-item id="handout"
                    :transition="false"
                    :reverse-transition="false"
                    class="pb-4"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('event.handout') }}
            </h5>
            <v-card class="pa-4 content-card"
                    color="#f5f5f5"
            >
                <div class="handout-text"
                     v-html="liveHandoutsText"
                />
                <div class="handout-buttons">
                    <handout-button v-model="handouts" />
                </div>
            </v-card>
        </v-tab-item>
        <v-tab-item
            id="agenda"
            :transition="false"
            :reverse-transition="false"
            class="mobile-agenda py-4"
        >
            <agenda
                class="px-7 live-sidebar-agenda"
                force-mobile-view
                show-status
                hide-title
                collapse-breakouts
            />
        </v-tab-item>
        <v-tab-item
            id="question"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('event.ask_a_question') }}
            </h5>
            <v-card class="content-card pa-4"
                    color="#f5f5f5"
            >
                <question />
            </v-card>
        </v-tab-item>

        <v-tab-item
            id="poll"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('event.polls') }}
            </h5>
            <v-card v-if="!active"
                    color="#f5f5f5"
                    class="pa-4 content-card"
            >
                <h5 class="no-poll-message">
                    {{ $t("event.no_poll") }}
                </h5>
            </v-card>
            <poll />
        </v-tab-item>
        <v-tab-item
            id="chat"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('event.chat') }}
            </h5>
            <chat/>
        </v-tab-item>
        <v-tab-item
            id="quiz"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('event.quiz') }}
            </h5>
            <v-card v-if="!activeQuestion"
                    color="#f5f5f5"
                    class="pa-4 content-card"
            >
                <h5
                    class="no-poll-message"
                >
                    {{ $t("event.no_quiz") }}
                </h5>
            </v-card>
            <quiz />
        </v-tab-item>
        <v-tab-item
            id="custom"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ customTabTitle }}
            </h5>
            <v-card class="pa-4 content-card"
                    color="#f5f5f5"
            >
                <div class="customTabContent"
                     v-html="customTabBodyText"
                />
            </v-card>
        </v-tab-item>
        <v-tab-item
            id="support"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('layout.support') }}
            </h5>
            <v-card class="support-card"
                    color="#f5f5f5"
            >
                <v-card-text>
                    <support />
                </v-card-text>
            </v-card>
        </v-tab-item>
        <v-tab-item
            id="privacy-policy"
            :transition="false"
            :reverse-transition="false"
            :class="tabItemClasses"
        >
            <h5 class="text-center mb-10 tab-title">
                {{ $t('privacyPolicy.title') }}
            </h5>
            <v-card class="privacy-policy-card pwa"
                    color="#f5f5f5"
            >
                <v-card-text>
                    <privacy-policy />
                </v-card-text>
            </v-card>
        </v-tab-item>
    </v-tabs-items>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    import Speakers from '@/pwa/controls/Speakers';
    import HandoutButton from '@/components/templates/default/EventPage/HandoutButton';
    import Support from '@/components/controls/Support.vue';
    import AboutEvent from '@/components/templates/default/EventPage/LiveComponents/AboutEvent';
    import Poll from '@/components/templates/default/EventPage/LiveComponents/Poll';
    import Question from '@/components/templates/default/EventPage/LiveComponents/Question';
    import Quiz from '@/components/templates/default/EventPage/LiveComponents/Quiz';
    import ProgramElement from '@/pwa/controls/ProgramElement.vue';
    import PrivacyPolicy from '@/components/controls/PrivacyPolicy.vue';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import Chat from '@/components/controls/Chat.vue';
    export default {
        name: 'TabContents',
        components: {
            Chat,
            HandoutButton,
            Speakers,
            Support,
            AboutEvent,
            Poll,
            Question,
            Quiz,
            ProgramElement,
            PrivacyPolicy,
            Agenda
        },
        props: {
            tab: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'customTabBodyText',
                'pwaDarkenPrimaryColorStyle',
                'textColorOnDarkenPrimaryColor',
                'pwaPrimaryColorRGB',
                'pwaPrimaryColor',
                'pwaTextColorStyle',
                'getContent',
                'speakers',
                'topics',
                'customTabTitle',
            ]),
            ...mapGetters('qld-vuex-store/poll', ['active']),
            ...mapGetters('qld-vuex-store/quiz', ['activeQuestion']),
            ...mapState('project', ['project']),
            cssVars() {
                return {
                    '--r': this.pwaPrimaryColorRGB.r,
                    '--g': this.pwaPrimaryColorRGB.g,
                    '--b': this.pwaPrimaryColorRGB.b,
                    '--primary-color': this.pwaPrimaryColor,
                    ...this.pwaDarkenPrimaryColorStyle,
                    ...this.textColorOnDarkenPrimaryColor,
                    ...this.pwaTextColorStyle,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            tabItemClasses() {
                return {
                    'mt-4': !this.isMobile,
                    'mobile-content': this.isMobile,
                };
            },
            liveHandoutsText() {
                return this.getContent(this.currentEvent.livePageHandoutText);
            },
            handouts() {
                return this.currentEvent.handouts;
            },
            hasSpeakers() {
                return this.speakers.length > 0;
            },
            hasTopics(){
                return this.topics.length > 0;
            },
        },
    };
</script>

<style lang="scss">
.pwa {
    .theme--light.v-tabs-items {
        background: transparent !important;
    }
}

.template-default.pwa {

    .content-card{
        border-radius: var(--card-border-radius) !important;
    }
  .live-page {
    .main {
      min-height: calc(100vh - 64px);
    }

    .live-content {
      color: white;
      background-position: center center;
      background-size: cover;
      z-index: 2;

      li {
        list-style-position: outside;
      }

      & > div {
        position: relative;
        z-index: 1;
      }

      h4 {
        margin-bottom: 16px;
        margin-top: 40px;
      }
    }

    .live-sidebar {
      background-color: white;
      overflow: auto;

      .about-card{
        background-color: var(--darken-primary-color);
        color: var(--text-color-on-darken);
        border-radius: var(--card-border-radius);
      }

      .no-poll-message {
        color: #3e3e3e !important;
        font-style: normal;
        font-size: 15px !important;
        line-height: 120%;
        text-align: center;
      }

      .handout-text {
        a {
            color: var(--darken-primary-color) !important;
        }

        p {
            font-style: normal;
            font-size: 15px !important;
            line-height: 120%;
            text-align: center;
            width: 90%;
            margin: 0 auto !important;
        }
        a,p,h1,h2,h3,h4,h5,h6,pre,div{
            overflow-wrap: break-word;
        }
        p,h1,h2,h3,h4,h5,h6,pre,div{
            color: #3e3e3e;
        }
      }

      .handout-buttons {
        margin-top: 30px;
        text-align: center;
        .handout-button {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }

      .tabs-col {
        display: contents;
        justify-content: center;
      }

      .v-tab {
        text-transform: none !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 120% !important;
        color: transparent;
        padding: 0 8px;
      }

      .customTabContent {
        p,
        li,
        ul,
        ol,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            color: #3e3e3e;
            font-size: 16px;
        }
        a {
          color: var(--darken-primary-color) !important;
          font-size: 16px;
        }
      }

      .qld-question {
        &.show-email{
            form{
                .qld-form-group{
                    &:nth-child(1) {
                        visibility: visible;
                        height: 80px;
                        input{
                            border-radius: var(--input-border-radius);
                        }
                    }
                    &:nth-child(2) {
                        visibility: visible;
                        height: 80px;
                        input{
                            border-radius: var(--input-border-radius);
                        }
                    }
                }
            }
        }

        form {
          .qld-form-group {
            input,
            textarea,
            select {
              width: 100%;
              padding: 8px 8px;
              outline-color: var(--darken-primary-color) !important;
              border-radius: var(--input-border-radius);
              border: 1px solid #3e3e3e;
              &:focus {
                border-color: var(--darken-primary-color) !important;
              }
            }

            textarea{
              max-height: 30vh;
              border-radius: var(--input-border-radius);
            }

            input,
            select {
              min-height: 40px;
              margin-bottom: 24px;
            }

            &:nth-child(1) {
              visibility: hidden;
              height: 0;
            }

            &:nth-child(2) {
              visibility: hidden;
              height: 0;
            }

            &:nth-child(3) {
              display: none;
            }

            &:nth-child(5) {
              button {
                background-color: var(--primary-color) !important;
                box-shadow: 0 8px 16px rgba(var(--r), var(--g), var(--b), 0.3);
                border-radius: var(--button-border-radius);
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
              }
            }

            .qld-input-error {
              border-color: red !important;
            }
          }
        }
      }



      .qld-poll {
            background-color: #f5f5f5;
            padding: 20px 12px 12px 12px;
            border-radius: var(--card-border-radius);
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
          div{
              text-align: center;
              font-size: 16px;
                @media (min-width: 961px) {
                    font-size: 18px !important;
                }
          }
          form{
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
                order: 0;
                padding: 8px;
                font-style: normal;
                font-weight: normal;
                display: flex;
                align-items: center;
                font-size: 19px !important;
                min-height: 55px;
                color: var(--text-color-on-darken) !important;
                border-radius: var(--card-border-radius);
                background-color: var(--darken-primary-color);
                @media (min-width: 961px) {
                    font-size: 20px !important;
                }
            }
            .qld-poll-options {
                order: 1;
                padding-left: 0;
                list-style: none;
                li {
                    background: rgba(var(--r,),var(--g),var(--b), 0.4);
                    display: flex;
                    align-items: center;
                    min-height: 50px;
                    margin-bottom: 10px;
                    padding: 10px;
                    border-radius: var(--card-border-radius);
                    position: relative;
                    z-index: 1;
                }

                li:hover {
                    input[type="radio"]+ label{
                        position: relative;
                        &::before{
                            content: " ";
                            position: absolute;
                            top: -14px;
                            left: -33px;
                            right: -10px;
                            bottom: -14px;
                            border-radius: var(--card-border-radius);
                            background: rgba(0,0,0,0.3);
                            z-index: -1;
                            border: 1px solid rgb(124, 124, 124);
                        }
                    }
                }

                input[type="radio"]:checked + label{
                    position: relative;
                    &::before{
                        content: " ";
                        position: absolute;
                        top: -14px;
                        left: -33px;
                        right: -10px;
                        bottom: -14px;
                        border-radius: var(--card-border-radius);
                        background: rgba(0,0,0,0.3);
                        z-index: -1;
                        border: 1px solid rgb(124, 124, 124);
                    }
                }

                 input[type="radio"]{
                    position: relative;
                }
                input[type="radio"]:checked{
                    position: relative;
                }
                label {
                    width: 100%;
                    font-size: 17px;
                    padding-top: 2px;
                    padding-left: 2px;
                    margin-left: 9px;
                    color: var(--text-color-on-darken);
                    @media (min-width: 961px) {
                        font-size: 18px;
                    }
                }
            }
            .qld-poll-stats {
                order:2;
                width: 100%;
                border-collapse: separate;
                margin-top: -8px;
                border-spacing: 0 10px;
                tr {
                    td{
                        font-size: 16px;
                        @media (min-width: 961px) {
                            font-size: 18px;
                        }
                    }
                    td:nth-child(1) {
                        padding: 10px;
                        background: rgba(var(--r,),var(--g),var(--b), 0.4);
                        border-top-left-radius: var(--card-border-radius);
                        border-bottom-left-radius: var(--card-border-radius);
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        width: 85% !important;
                    }
                    td:nth-child(2) {
                        display: none;
                    }
                    td:nth-child(3) {
                        padding: 10px;
                        background: rgba(var(--r,),var(--g),var(--b), 0.4);
                        flex: 0 0 29px;
                        border-top-right-radius: var(--card-border-radius);
                        border-bottom-right-radius: var(--card-border-radius);
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        width: 15% !important;
                        text-align: center;
                    }
                }
            }

            .qld-poll-countdown {
                text-align: left;
                position: relative;
                height: 20px;
                margin-bottom: 15px;
                order: 0;
            }

            .qld-poll-countdown-digit {
                position: absolute;
                text-align: center;
                width: 100%;
                z-index: 2;
                text-shadow: 0 1px 1px #fff, 0 -1px 1px #fff, 1px 0 1px #fff,
                -1px 0 1px #fff;
                color: #3e3e3e;
                font-size: 14px;
            }

            .qld-poll-countdown-bar {
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: var(--darken-primary-color);
                text-align: -webkit-right;
                text-align: -moz-right;
                position: absolute;
                z-index: 1;
                border: 1px solid #aaa;
                border-radius: 3px;
                order: 2;
                overflow: hidden;
            }

            .qld-poll-countdown-progress {
                display: block;
                height: 100%;
                background-color: #fff;
            }
          }
      }

    .qld-quiz{
        background-color: #f5f5f5;
        padding: 20px 12px 12px 12px;
        border-radius: var(--card-border-radius);
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        .qld-quiz-question  {
            display: flex;
            flex-direction: column;
            p {
                order: 0;
                padding: 8px;
                font-style: normal;
                font-weight: normal;
                display: flex;
                align-items: center;
                font-size: 18px !important;
                min-height: 50px;
                color: var(--text-color-on-darken) !important;
                margin-bottom: 15px !important;
                border-radius: var(--card-border-radius);
                background-color: var(--darken-primary-color);
            }
            ul{
                padding-left: 0;
                list-style: none;
                li {
                    background: rgba(var(--r,),var(--g),var(--b), 0.4);
                    display: flex;
                    align-items: center;
                    min-height: 50px;
                    margin-bottom: 8px;
                    padding: 10px;
                    border-radius: var(--card-border-radius);
                    position: relative;
                    z-index: 1;
                }

                li:hover {
                    input[type="radio"]+ label{
                        position: relative;
                        &::before{
                            content: " ";
                            position: absolute;
                            top: -14px;
                            left: -33px;
                            right: -10px;
                            bottom: -14px;
                            border-radius: var(--card-border-radius);
                            background: rgba(0,0,0,0.3);
                            z-index: -1;
                            border: 1px solid rgb(124, 124, 124);
                        }
                    }
                }

                input[type="radio"]:checked + label{
                    position: relative;
                    &::before{
                        content: " ";
                        position: absolute;
                        top: -14px;
                        left: -33px;
                        right: -10px;
                        bottom: -14px;
                        border-radius: var(--card-border-radius);
                        background: rgba(var(--r,),var(--g),var(--b), 0.4);
                        z-index: -1;
                        border: 1px solid rgb(124, 124, 124);
                    }
                }

                 input[type="radio"]{
                    position: relative;
                }
                input[type="radio"]:checked{
                    position: relative;
                }
                label {
                    width: 100%;
                    font-size: 17px;
                    padding-top: 2px;
                    padding-left: 2px;
                    margin-left: 9px;
                    color: var(--text-color-on-darken);
                    @media (min-width: 961px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .qld-quiz-countdown {
            text-align: left;
            position: relative;
            height: 20px;
            margin-bottom: 15px;
        }

        .qld-quiz-countdown-digit {
            position: absolute;
            text-align: center;
            width: 100%;
            z-index: 2;
            text-shadow: 0 1px 1px #fff, 0 -1px 1px #fff, 1px 0 1px #fff,
                -1px 0 1px #fff;
            color: #3e3e3e;
            font-size: 14px;
        }

        .qld-quiz-countdown-bar {
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--darken-primary-color);
            text-align: -webkit-right;
            text-align: -moz-right;
            position: absolute;
            z-index: 1;
            border: 1px solid #aaa;
            border-radius: 3px;
            order: 2;
            overflow: hidden;
        }

        .qld-quiz-countdown-progress {
            display: block;
            height: 100%;
            background-color: #fff;
        }

        .qld-quiz-feedback{
            text-align: center;
        }
    }
      .embedded-chat {
        height: calc(100vh - 250px);
        width: 100%;
      }

      .tab-title{
          font-size: 28px;
          color: #fff;
      }
    }

    &.mobile {
      flex-direction: column;
      .live-sidebar {
        flex: 0 0 500px;
        position: static;

        .mobile-content {
          height: 100%;
          width: 100%;
          overflow: auto;
          position: fixed;

          .customTabContent {
            padding-bottom: 70vh !important;
          }
        }

        .qld-question {
          form {
            .qld-form-group {
              input,
              select {
                height: 32px;
                min-height: initial;
                margin-bottom: 1rem;
                padding: 3px !important;
              }

              textarea {
                 border: 1px solid rgba(255, 255, 255, 0.561);
                height: 3rem;
                margin-bottom: 1rem;
              }
            }
          }
        }

        .embedded-chat {
          height: 40vh;
          width: 100%;
        }

        .qld-poll {
          max-height: 40vh;
        }

        .qld-quiz {
          max-height: 40vh;
        }
      }
    }

    .handout-buttons .handout-button {
      display: block;
      margin: 0 auto;
      max-width: 65%;
    }
  }

  .live-sidebar-agenda {
    color: #3e3e3e;
    h4 {
      color: #3e3e3e;
    }
  }
}
</style>
<style lang="scss">
.privacy-policy-card.pwa {
    ::-webkit-scrollbar-thumb {
        background: #201f1f4b;
    }

    font-family: "Archivo", arial, helvetica, sans-serif;
    color: #3e3e3e !important;

    .contents{
        color: #3e3e3e !important;

        h1, h2, h3, h4, h5, h6, p, li {
            color: #3e3e3e !important;
        }
    }
}
</style>