<template>
    <div class="multisession-breakout-select">
        <label>{{ breakoutGroupLabel }}</label>
        <v-select
            v-model="model"
            :placeholder="$t('multiSession.selectBreakout')"
            :items="breakouts"
            item-value="_id"
            item-text="name"
            :color="darkenPrimaryColor"
            outlined
            class="dropdown-field"
            hide-details
            background-color="#fff"
            attach
        >
            <template v-slot:item="{item}">
                <span :class="{'text-decoration-line-through':item.disabled}">
                    {{ item.name }}
                </span>
                <span v-if="item.disabled"
                      class="ml-3"
                >{{ $t('registration.event_full') }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'MultiSessionBreakoutSelect',
        props:{
            group: {
                type: Array,
                default: () => []
            },
            value: {
                type: String,
                default: () => ''
            },
        },
        data(){
            return {
                breakouts: [],
                model: this.value,
            };
        },
        computed: {
            ...mapState('project', ['project', 'eventCountMap']),
            ...mapGetters('project', ['darkenPrimaryColor']),
            breakoutGroupLabel() {
                return (this.group[0].breakoutGroupName || this.$t('multiSession.breakoutRound')) + ' ' + this.group[0].agendaStartAndEndTime;
            }
        },
        created() {
            this.breakouts = [...this.group];
            this.breakouts.forEach((item) => {
                item.disabled = false;
                if (item.registrationsCountLimit && this.eventCountMap[item._id] >= item.registrationsCountLimit) {
                    item.disabled = true;
                }
            });
        },
        watch: {
            model(newValue) {
                this.$emit('input', newValue);
            }
        },
    };
</script>

<style lang="scss">
.v-menu__content{
    border-radius: var(--input-border-radius) !important;
}

.multisession-breakout-select{
    .v-input{
        border-radius: var(--input-border-radius) !important;
    }
}
</style>