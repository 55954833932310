import { render, staticRenderFns } from "./AgendaStatusEvent.vue?vue&type=template&id=22004ea6&scoped=true&"
import script from "./AgendaStatusEvent.vue?vue&type=script&lang=js&"
export * from "./AgendaStatusEvent.vue?vue&type=script&lang=js&"
import style0 from "./AgendaStatusEvent.vue?vue&type=style&index=0&id=22004ea6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22004ea6",
  null
  
)

export default component.exports