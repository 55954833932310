<template>
    <div>
        <password-dialog ref="secureDataRequest" />
        <component :is="mainComponent"
                   :error="liveDirectorError"
                   :split-error="splitError"
        />
    </div>
</template>

<script>
    import EventPageContent from './EventPageContent';
    import LiveDirectorService from '@/services/LiveDirectorService';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import MainErrorDisplay from '@/components/controls/MainErrorDisplay';
    import PasswordDialog from '@/components/templates/default/EventPage/PasswordDialog';

    export default {
        name: 'EventPage',
        components: {
            PasswordDialog,
            MainErrorDisplay,
            EventPageContent,
        },
        data() {
            return {
                liveDirectorReady: false,
                liveDirectorError: null,
                splitError: false,
            };
        },
        async mounted() {
            if (this.project.security.passwordLivePageActive) {
                const secureData = await this.$refs.secureDataRequest.open();
                this.setSecureData(secureData);
            }

            await this.initLiveDirector();
        },
        computed: {
            ...mapGetters('project', ['currentEvent', 'registrationType']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationData']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            mainComponent() {
                if (this.liveDirectorReady) {
                    return 'event-page-content';
                }

                return this.liveDirectorError ? 'main-error-display' : null;
            }
        },
        methods: {
            ...mapMutations('project', ['setSecureData']),
            async initLiveDirector() {
                try {
                    await new LiveDirectorService(
                        this.currentEvent.liveDirectorBroadcastSlug,
                        this.$route.params.registration_id,
                        this.registrationData,
                        this.isRegisteredForPwa,
                    ).init();
                    this.liveDirectorReady = true;
                } catch (e) {
                    const errorString = e.toString();
                    if (errorString === 'ConnectionError: You are already watching this broadcast') {
                        this.liveDirectorError = this.$t('singleSession.alreadyWatching');
                        this.splitError = true;
                    } else {
                        this.liveDirectorError = 'We can\'t connect you to your broadcast.';
                        this.splitError = false;
                    }
                }
            }
        }
    };
</script>
