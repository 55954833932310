<template>
    <div>
        <agenda-session :event="registeredEvent"
                        :force-mobile-view="forceMobileView"
                        :show-status="showStatus"
                        :collapse-breakouts="collapseBreakouts"
                        :collapsed="collapsed"
                        @unCollapse="unCollapse"
        />
        <ul class="agenda-breakouts"
            :class="{'mobile': isMobile, 'tablet': isTablet}"
        >
            <li v-for="breakout in displayableBreakouts"
                :key="`breakout-item-${breakout._id}`"
                class="agenda-breakout-name text-left"
                :class="{'mobile': isMobile}"
            >
                <div class="d-flex justify-space-between">
                    <span class="breakout-name">
                        {{ breakout.name }}
                    </span>
                    <agenda-status-event v-if="showStatus && !collapsed"
                                         :event="breakout"
                                         :force-mobile-view="forceMobileView"
                    />
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import AgendaSession from '@/components/controls/Agenda/AgendaSession';
    import AgendaStatusEvent from '@/components/controls/Agenda/AgendaStatusEvent';
    import { mapGetters } from 'vuex';

    export default {
        name: 'AgendaBreakoutGroup',
        components: {AgendaStatusEvent, AgendaSession},
        data() {
            return {
                collapsed: !this.isQueried(),
            };
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            forceMobileView: {
                type: Boolean,
                default: () => false
            },
            showStatus: {
                type: Boolean,
                default: () => false,
            },
            collapseBreakouts: {
                type: Boolean,
                default: () => false
            },
            hideBreakouts: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            ...mapGetters('registration', ['isRegisteredForEvent']),
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.forceMobileView;
            },
            isTablet(){
                return this.$vuetify.breakpoint.sm;
            },
            displayableBreakouts() {
                if(this.hideBreakouts){
                    return [];
                }
                if (this.collapsed && this.collapseBreakouts) {
                    return this.event.breakouts.filter(b => this.isRegisteredForEvent(b._id));
                }
                return this.event.breakouts;
            },
            registeredEvent() {
                const registeredEvent = this.event.breakouts.filter(b => this.isRegisteredForEvent(b._id)).shift();
                if (!registeredEvent) {
                    return this.event;
                }
                return {
                    ...this.event, _id: registeredEvent._id,
                };
            }
        },
        methods: {
            unCollapse() {
                this.collapsed = !this.collapsed;
            },
            isQueried() {
                return this.$route.hash === `#${this.event.breakout_group}`;
            }
        },
        mounted() {
            if (this.isQueried()) {
                this.$el.scrollIntoView(true);
            }
        }
    };
</script>

<style scoped lang="scss">
ul.agenda-breakouts {
    margin-left: 190px;
    margin-top: 2px;
    margin-bottom: 8px;

    &.mobile {
        margin-left: 0;
        margin-bottom: 4px;
    }

    li.agenda-breakout-name {
        font-weight: normal;
        padding-top: 3px;
        padding-bottom: 3px;
        list-style-position: initial;

        span.breakout-name {
            flex: 1 1 auto;
        }

        &.mobile {
            font-size: 14px !important;
            padding-top: 0;
            padding-bottom: 0;

            span.breakout-name {
                flex: 0 0 auto !important;
            }
        }
        &.tablet{
            font-size: 14px !important;

        }
    }
}

</style>