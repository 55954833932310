<template>
    <v-dialog
        persistent
        :value="dialogOpen"
        max-width="700"
    >
        <v-card class="rounded-0"
                :class="{mobile: isMobile}"
        >
            <form action="/"
                  method="post"
                  @submit.prevent="checkPassword"
            >
                <v-card-title>
                    {{ $t('security.title') }}
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">
                        {{ $t('security.enterPassword') }}
                    </p>
                    <v-text-field
                        v-model="passwordEntered"
                        filled
                        autofocus
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :error-messages="securityErrors"
                        @click:append="showPassword = !showPassword"
                        @keydown="checkCapsLock"
                    />
                    <div v-if="passwordError"
                         class="error--text mt-2"
                    >
                        {{ $t('security.error') }}
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-center">
                    <v-btn :dark="isDark"
                           :color="project.style.primaryColor"
                           type="submit"
                    >
                        {{ $t('security.button') }}
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapState } from 'vuex';
    import { isDark } from '@/services/ColorService';
    import { $http } from '@/services/http';

    export default {
        name: 'PasswordDialog',
        data() {
            return {
                passwordEntered: '',
                passwordError: false,
                showPassword: false,
                hasCapsLock: false,
                dialogOpen: false,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            isDark() {
                return isDark(this.project.style.primaryColor);
            },
            hasMsCapsLockWarning() {
                // IE 10 and 11 caps lock warning proprietary support check
                // Caps lock detection in IE is fishy, but since it has a caps lock warning anyway,
                // we degrade to it
                return typeof document.msCapsLockWarningOff !== 'undefined';
            },
            securityErrors() {
                if(this.hasCapsLock && !this.hasMsCapsLockWarning) {
                    return this.$t('security.capsLockOn');
                }
                return [];
            },
        },
        methods: {
            async open() {
                this.dialogOpen = true;
                return new Promise((resolve) => {
                    this.resolve = resolve;
                });
            },
            async checkPassword() {
                this.passwordError = false;
                const url = process.env.VUE_APP_EVENT_ENDPOINT.replace('{eventID}', this.$route.params.event_id) + '/protected';
                try {
                    const {data} = await $http.post(url, {
                        password: this.passwordEntered,
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        }
                    });
                    this.dialogOpen = false;
                    return this.resolve(data);
                } catch (e) {
                    this.passwordError = true;
                }
            },
            checkCapsLock(event) {
                if (typeof event.getModifierState === 'function') {
                    const capsLockState = event.getModifierState('CapsLock');
                    this.hasCapsLock = event.keyCode === 20 ? !capsLockState : capsLockState;
                }
            },
        },
    };
</script>