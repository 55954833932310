<template>
    <vue-jitsi-meet
        v-if="jwt"
        ref="jitsiRef"
        domain="8x8.vc"
        :options="jitsiOptions"
    />
</template>

<script>
    import { JitsiMeet } from '@mycure/vue-jitsi-meet';
    import { mapState } from 'vuex';
    import { $http } from '@/services/http';
    import { toolbarButtons } from '@/services/JitsiToolbarConfig';
    import { get } from 'lodash';

    export default {
        components: {
            VueJitsiMeet: JitsiMeet,
        },
        data() {
            return {
                jwt: null,
            };
        },
        created() {
            this.requestJWT();
        },
        methods: {
            async requestJWT() {
                const response = await $http.post(process.env.VUE_APP_JITSI_JWT, {
                    registration_id: this.registrationId,
                    event_id: this.eventID,
                    email: get(this.registrationData, 'email'),
                    moderator: this.project.event.moderator,
                });
                this.jwt = response.data.jwt;
            },
        },
        computed: {
            ...mapState('registration', ['registrationId', 'registrationData']),
            ...mapState('project', ['project']),
            eventID() {
                return this.$route.params.event_id;
            },
            isModerator() {
                let buff = Buffer.from(this.jwt.split('.')[1], 'base64');
                const jwtBody = JSON.parse(buff.toString('ascii'));
                return jwtBody.context.user.moderator === true;
            },
            jitsiOptions() {
                return {
                    roomName: `${process.env.VUE_APP_JITSI_APPID}/${this.eventID}`,
                    jwt: this.jwt,
                    configOverwrite: {
                        hideConferenceSubject: true,
                        hideConferenceTimer: true,
                        prejoinPageEnabled: false,
                        startWithAudioMuted: true,
                        startWithVideoMuted: false,
                        toolbarButtons: toolbarButtons(this.isModerator),
                    },
                };
            },
        },
    };
</script>