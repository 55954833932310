<template>
    <div class="speakers"
         :style="styles"
    >
        <div class="speaker-title">
            {{ $t('registration.speakers') }}
        </div>
        <div class="speakers-wrapper">
            <div v-for="(speaker, index) in speakers"
                 :key="`speaker-${speaker.id}`"
                 class="speaker"
                 :class="{'disable-link' : disableLink(speaker.link), 'with-border': index !== speakers.length-1}"
            >
                <div class="speaker-main-information-wrapper">
                    <a v-if="!isUWVProject"
                       :href="speaker.link"
                       target="_blank"
                       class="speaker-avatar-link"
                    >
                        <v-avatar size="80">
                            <img v-if="speaker.avatar.url"
                                 :src="speaker.avatar.url"
                                 :alt="speaker.name"
                            >
                            <v-icon v-else>person</v-icon>
                        </v-avatar>
                    </a>
                    <div class="speaker-name-title-wrapper">
                        <a :href="speaker.link"
                           target="_blank"
                           class="speaker-name-link"
                        >
                            <span>
                                {{ speaker.name }}
                            </span>
                        </a>
                        <span class="speaker-job-title">
                            {{ speaker.title }}
                        </span>
                    </div>
                </div>
                <p class="speaker-summary">
                    {{ speaker.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'Speakers',
        computed: {
            ...mapGetters('project', ['speakers', 'isInPreviewMode', 'pwaPrimaryColorRGB']),
            ...mapState('project', ['project']),
            styles() {
                return {
                    '--r': this.pwaPrimaryColorRGB.r,
                    '--g': this.pwaPrimaryColorRGB.g,
                    '--b': this.pwaPrimaryColorRGB.b,
                };
            },
            isUWVProject(){
                return this.project._id === '632b13bacd781228ef2a0bfb';
            }
        },
        methods: {
            disableLink(link) {
                return link === '' || this.isInPreviewMode;
            }
        }
    };
</script>

<style lang="scss" scoped>

$font-size-base-px: 16px;
@function rem-calc($px) {
     @return $px / $font-size-base-px * 1rem;
}

.speakers{
    font-family: "Archivo", arial, helvetica, sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    color: #3e3e3e !important;

    .speaker-title{
        font-weight: 500;
        font-size: rem-calc(21px);
        margin-bottom: 20px;
        align-self: flex-start;
    }

    .speakers-wrapper{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;
        flex-direction: column;
        align-items: flex-start;
        min-width: 100%;

        .speaker{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 10px;
            flex: none;
            max-width: none;
            min-width: 100%;


            &.with-border{
                min-width: 100%;
                padding-bottom: 20px;
                border-bottom: 1px solid #0000001f;
            }

            .speaker-main-information-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                gap: 10px;

                .speaker-avatar-link{
                    .v-icon {
                        font-size: 6rem;
                    }
                }
                .speaker-name-title-wrapper{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .speaker-name-link{
                        font-weight: 500;
                        font-size: rem-calc(21px);
                        line-height: 120%;
                        text-decoration: none;
                        color: #3e3e3e !important;
                        margin-bottom: 4px;
                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                    .speaker-job-title{
                        font-weight: 400;
                        font-size: rem-calc(14px);
                        line-height: 120%;
                    }
                }
            }
            .speaker-summary{
                font-size: rem-calc(16px) !important;
            }
        }
    }
}
.disable-link {
    a{
        pointer-events: none;
    }
}

</style>

<style lang="scss">
$font-size-base-px: 16px;
@function rem-calc($px) {
     @return $px / $font-size-base-px * 1rem;
}
.pwa {
    .speakers {
        .speakers-wrapper {
            .speaker-avatar-link {
                .v-avatar {
                    min-width: rem-calc(70px) !important;
                    max-width: rem-calc(70px) !important;
                    min-height: rem-calc(70px) !important;
                    max-height: rem-calc(70px) !important;

                    img {
                        min-width: rem-calc(70px) !important;
                        max-width: rem-calc(70px) !important;
                        min-height: rem-calc(70px) !important;
                        max-height: rem-calc(70px) !important;
                    }
                }
            }
        }
    }
}
</style>
