<template>
    <div class="program-element"
         :class="{'mobile': isMobile, 'xl-screen': isExtraLargeScreen, 'gradient': gradient}"
         :style="styles"
    >
        <agenda v-if="isMultiSession" />
        <program-single-session v-else />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import ProgramSingleSession from '@/components/controls/ProgramSingleSession';

    export default {
        name: 'ProgramElement',
        props: {
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        components: { Agenda, ProgramSingleSession },
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', ['primaryColorRGB', 'isMultiSession']),
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            isExtraLargeScreen(){
                return this.$vuetify.breakpoint.xlOnly;
            }
        }
    };
</script>

<style lang="scss">
.program-element{
    $font-size-base-px: 16px;
    @function rem-calc($px) {
        @return $px / $font-size-base-px * 1rem;
    }
    color: #ffffff !important;
    padding: 158px 352px;
    @media (min-width:1600px) and (max-width: 1800px) {
        padding: 110px 200px;
    }

    @media (min-width:960px) and (max-width: 1600px) {
        padding: 80px 130px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(334.74deg, rgba(51, 51, 51, 0.5) -18.63%, rgba(var(--r), var(--g), var(--b), 0.5) 88.23%);
    }

    .single-session-title{
        font-size: rem-calc(20px) !important;
        margin-bottom: 20px;
        font-weight: 700;
        line-height: 150%;
        color: var(--block-text-color) !important;
    }

    .agenda-time-interval{
        font-size: rem-calc(16px) !important;
                color: var(--block-text-color) !important;

    }

    .agenda-title{
        font-size: rem-calc(23px) !important;
        color: var(--block-text-color) !important;


        &.program{
            font-size: rem-calc(20px) !important;
            font-weight: 700;
            line-height: 150%;
            margin-bottom: 20px;
        }
    }

    .agenda-event{
        margin-bottom: 23px;
        color: var(--block-text-color) !important;

    }

    .topic{
        border: none;

        .content{
            padding-left: 24px !important;
            .topic-title{
                font-size: rem-calc(23px) !important;
                margin: 0 !important;
                color: var(--block-text-color) !important;

            }

            .description{
                font-size: rem-calc(14px) !important;
                color: var(--block-text-color) !important;

            }
        }

        .interval-wrapper{
            justify-content: initial !important;
            padding-top: 4px;
            margin: 0 !important;
            min-width: 150px;
            .interval{
                align-self: baseline;
                font-size: rem-calc(16px) !important;
                color: var(--block-text-color) !important;
            }
            .time-badge{
                color: var(--block-text-color) !important;
            }
        }
    }

    &.mobile{
        padding: 20px;
        .agenda-time-interval{
            font-size: rem-calc(16px) !important;
        }

        .content{
            padding-left: 0 !important;
        }
    }

}

.half-width{
    .program-element{
        $font-size-base-px: 16px;
        @function rem-calc($px) {
            @return $px / $font-size-base-px * 1rem;
        }
        padding: 142px 60px;

        &.mobile{
            padding: 20px;
            .agenda-time-interval{
                font-size: rem-calc(16px) !important;
            }

            .agenda-time-interval{
                font-size: rem-calc(16px) !important;
            }

            .content{
                padding-left: 0 !important;
            }
        }
    }
}

</style>
