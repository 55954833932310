import {mapActions} from 'vuex';

const componentPageRelations = {
    'live': 'live',
    'ondemand': 'on-demand',
    'end': 'end',
    'splash': 'waiting',
    'dynamiceventpage': 'registration',
    'registrationform': 'registration',
};

const determinePage = (componentName) => {
    const result = componentPageRelations[componentName.toLowerCase()];
    if (!result) {
        console.error('analytics: unsupported component');
        return null;
    }

    return result;
};

export default {
    methods: {
        ...mapActions('project', ['registerUserLinkClick']),
        getPage() {
            return determinePage(this.$options.name);
        },
        async trackClicksForLink(node) {
            if (node.classList.contains('v-tab')) {
                return;
            }
            await this.registerUserLinkClick({
                page: this.getPage(),
                action: node.getAttribute('href'),
            });
        },
        findActionElement(node, nodeName) {
            if (!node) {
                return null;
            }

            if (node === this.$el) {
                return null;
            }

            if ('a' === node.nodeName.toLowerCase()) {
                return node;
            }

            return this.findActionElement(node.parentElement, nodeName);
        },
        async trackClicks(e) {
            const el = this.findActionElement(e.target);

            if (!el) {
                return;
            }

            e.preventDefault();
            const target = el.getAttribute('target');
            if (target && target === '_blank') {
                this.trackClicksForLink(el);
            } else {
                await this.trackClicksForLink(el);
            }
            const clicker = document.createElement('a');
            clicker.setAttribute('href', el.getAttribute('href'));

            if (target) {
                clicker.setAttribute('target', target);
            }
            document.querySelector('body').appendChild(clicker);
            clicker.click();

            return true;
        }
    },
    mounted() {
        this.$el.addEventListener('click', this.trackClicks);
    },
    beforeDestroy() {
        this.$el.removeEventListener('click', this.trackClicks);
    }
};
