<template>
    <div class="scroll-to-top-button-container">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn v-show="showScrollToTopButton"
                       icon
                       x-large
                       class="scroll-to-top"
                       color="#ffffff"
                       :style="styles"
                       v-on="on"
                       @click="scrollToTop"
                >
                    <v-icon x-large>
                        mdi-arrow-up-drop-circle-outline
                    </v-icon>
                </v-btn>
            </template>
            <span>Scroll to top</span>
        </v-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'ScrollToTopButton',
        data() {
            return {
                showScrollToTopButton: false,
                footerIsVisible: false,
            };
        },
        props: {
            scrollElement: {
                type: String,
                default: () => '',
            },
            noFooterReference: {
                type: Boolean,
                default: () => false,
            },
        },
        computed: {
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            styles() {
                const styles = {};
                if (this.footerIsVisible) {
                    styles['margin-bottom'] = this.isMobile ? '130px' : '60px';
                }
                return styles;
            },
            scrollingElement() {
                return this.scrollElement.length > 0
                    ? document.querySelector(this.scrollElement)
                    : window;
            },
        },
        methods: {
            scrollToTop() {
                this.scrollingElement.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            windowScroll() {
                // In case of the Live page components (including the waiting page)
                // the scrolling element is not window/body, but a div element deep
                // down the
                const scrollY = this.getScrollY();
                this.showScrollToTopButton = scrollY > 0;
                if (!this.noFooterReference) {
                    const difference = this.isMobile ? 90 : 40;
                    this.footerIsVisible = (window.innerHeight + scrollY) >= document.body.offsetHeight - difference;
                }
            },
            getScrollY() {
                return this.scrollingElement === window
                    ? window.scrollY
                    : this.scrollingElement.scrollTop;
            }
        },
        mounted() {
            this.scrollingElement.addEventListener('scroll', this.windowScroll);
        },
        beforeDestroy() {
            this.scrollingElement.removeEventListener('scroll', this.windowScroll);
        }
    };
</script>

<style scoped>

</style>