<template>
    <v-row class="live-page my-0 no-margin"
           :class="{
               'mobile': isMobile,
               'preview' : isInPreviewMode,
           }"
           tabindex="0"
           :style="{...darkenPrimaryColorStyle, ...rgbStyleVars, ...buttonColorStyle, ...mobileSidebarHeight}"
    >
        <v-col class="live-content pa-0 ma-0"
               :xl="tabsAreNotShown ? 12 : videoColumnSize.xl"
               :lg="tabsAreNotShown ? 12 : videoColumnSize.lg"
               :md="tabsAreNotShown ? 12 : videoColumnSize.md"
               sm="12"
               :style="backgroundStyle"
               :class="livePageContentClass"
               @click="closeSheet"
        >
            <div :class="{'has-gradient': backgroundGradient}"
                 class="gradient-div"
            >
                <v-row class="pa-0 ma-0 no-margin"
                       :class="{'justify-center': nothingActive}"
                >
                    <v-col cols="12"
                           class="pa-0"
                           :xl="nothingActive ? 9 : 12"
                           :lg="nothingActive ? 8 : 12"
                           :md="nothingActive ? 8 : 12"
                           :sm="nothingActive ? 7 : 12"
                           xs="12"
                           :class="{'px-2': (nothingActive && !isMobile) }"
                    >
                        <div class="content-wrapper-helper"
                             :class="{'ie11': isIe11, fullscreen: externalFullscreen }"
                        >
                            <div class="mx-0 mt-16i mb-3 d-flex flex-row align-end live-title-wrapper">
                                <div class="live-title-col flex-grow-1 flex-shrink-1">
                                    <h4 class="live-title no-margin-top">
                                        {{ project.eventTitle }}
                                        <span v-if="isMultiSession">
                                            | {{ currentEvent.name }}
                                        </span>
                                    </h4>
                                </div>
                                <div class="text-end flex-grow-0 flex-shrink-0"
                                     :class="{'ie11-live': isIe11}"
                                >
                                    <qld-controller-switch
                                        data-qld-name="state"
                                        data-qld-controller-value="on-air"
                                    >
                                        <v-chip elevation="0"
                                                color="red"
                                                class="white--text font-weight-bold px-5 live-chip rounded-0 no-margin-top"
                                        >
                                            {{ $t('event.LIVE') }}
                                        </v-chip>
                                    </qld-controller-switch>
                                </div>
                            </div>
                            <v-row justify="center"
                                   class="pt-0 mx-0 clear"
                            >
                                <v-col cols="12"
                                       lg="12"
                                       class="pa-0"
                                >
                                    <div ref="videoWrapper"
                                         class="video-wrapper"
                                         :class="{'with-sidebar': fullscreenTabsShown}"
                                    >
                                        <div class="player-container"
                                             :class="{fullscreen: externalFullscreen, mobile: isMobile}"
                                        >
                                            <img v-if="playerControlIsJitsiMeet && isInPreviewMode" 
                                                 src="/img/jitsi-preview.png" 
                                                 alt="preview-jitsi" 
                                                 style="height: 100%; width:100%"
                                            >
                                            <jitsi-meet v-else-if="playerControlIsJitsiMeet"
                                                        style="height: 68vh"
                                            />
                                            <live-player v-else />
                                        </div>
                                        <qld-controller-toggle class="qld-controller-tab"
                                                               data-qld-name="polls"
                                        >
                                            <player-poll v-if="!isMobile && showPollsOverVideo" />
                                        </qld-controller-toggle>
                                        <qld-controller-toggle class="qld-controller-tab"
                                                               data-qld-name="quiz"
                                        >
                                            <player-quiz v-if="!isMobile && showQuizOverVideo" />
                                        </qld-controller-toggle>
                                        <qld-component-html class="top-right-of-video related-content"
                                                            data-qld-name="relatedcontent"
                                        />
                                        <div class="lower-center-of-video">
                                            <qld-component-text class="above-player mb-4"
                                                                data-qld-name="announcement"
                                            />
                                            <qld-controller-toggle data-qld-name="elearning-course-btn">
                                                <v-btn class="elearning-button"
                                                       :href="navigateToElearningCourseButtonHrefProcessed"
                                                       :style="buttonShadowColorStyle"
                                                       target="_blank"
                                                >
                                                    {{ navigateToElearningCourseButtonTitle }}
                                                </v-btn>
                                            </qld-controller-toggle>
                                            <qld-controller-toggle data-qld-name="evaluation-btn"
                                                                   style="text-align: center;"
                                            >
                                                <evaluation-button :blank="true"
                                                                   class="mt-4 in-video"
                                                />
                                            </qld-controller-toggle>
                                            <qld-controller-toggle data-qld-name="custom-cta">
                                                <custom-cta-button class="mt-4 in-video" />
                                            </qld-controller-toggle>
                                        </div>
                                        <div v-if="externalFullscreen && !isMobile && !fullscreenTabsShown"
                                             class="fullscreen-mode-tabs"
                                        >
                                            <tab-headers v-model="selectedTab"
                                                         vertical
                                                         @tabSelected="tabSelected"
                                            />
                                        </div>
                                        <div v-if="externalFullscreen && !tabsAreNotShownExternalFullscreen && fullscreenTabsShown"
                                             class="live-sidebar"
                                        >
                                            <v-btn class="close-fullscreen-tabs"
                                                   ripple
                                                   color="rgba(64,64,64,0.9)"
                                                   tile
                                                   icon
                                                   elevation="10"
                                                   @click="closeFullscreenSidebar"
                                            >
                                                <v-icon color="white">
                                                    close
                                                </v-icon>
                                            </v-btn>
                                            <tab-headers v-model="selectedTab" />
                                            <tab-contents :tab="selectedTab" />
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="isMultiSession">
                                <v-col cols="12"
                                       lg="12"
                                >
                                    <event-ends-countdown />
                                </v-col>
                            </v-row>
                            <v-row justify="center"
                                   class="mx-0"
                            >
                                <v-col cols="12"
                                       lg="12"
                                       class="pa-0 px-3"
                                >
                                    <default-footer class="mb-6" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col v-if="!tabsAreNotShown"
               cols="12"
               :xl="rightSideBarColumnSize.xl"
               :lg="rightSideBarColumnSize.lg"
               :md="rightSideBarColumnSize.md"
               sm="12"
               class="live-sidebar py-0 pr-0 pl-1"
        >
            <tab-headers v-model="selectedTab" />

            <tab-contents :tab="selectedTab" />
        </v-col>
    </v-row>
</template>
<script>
    import {mapGetters, mapMutations, mapState} from 'vuex';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import PlayerPoll from '@/components/templates/default/EventPage/LiveComponents/PlayerPoll';
    import PlayerQuiz from '@/components/templates/default/EventPage/LiveComponents/PlayerQuiz';
    import LivePlayer from '@/components/templates/default/EventPage/LiveComponents/LivePlayer';
    import TabContents from '@/components/templates/default/EventPage/LiveComponents/TabContents';
    import TabHeaders from '@/components/templates/default/EventPage/LiveComponents/TabHeaders';
    import EventEndsCountdown from '@/components/templates/default/EventPage/LiveComponents/EventEndsCountdown';
    import JitsiMeet from '@/components/templates/default/EventPage/LiveComponents/JitsiMeet';
    import mixin from '@/components/templates/default/EventPage/Mixins/searchElearningThirdPartyConnection.js';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';
    import screenfull from 'screenfull';
    import {forceFullScreen} from '@/services/FullscreenService';
    import EvaluationButton from '@/components/templates/default/EventPage/EvaluationButton.vue';
    import CustomCtaButton from '@/components/templates/default/EventPage/CustomCtaButton.vue';

    export default {
        name: 'Live',
        mixins: [mixin, trackUserLinkClicks],
        components: {
            CustomCtaButton, EvaluationButton,
            TabHeaders,
            TabContents,
            DefaultFooter,
            PlayerPoll,
            PlayerQuiz,
            JitsiMeet,
            LivePlayer,
            EventEndsCountdown
        },
        data() {
            return {
                closedPasswordDialog: false,
                selectedTab: '',
                sidebarHeight: 0,
                fullscreenTabsShown: false,
            };
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'backgroundImage',
                'getContent',
                'showPollsOverVideo',
                'showQuizOverVideo',
                'isMultiSession',
                'showCustomTab',
                'springerLook',
                'navigateToElearningCourseButtonHref',
                'navigateToElearningCourseButtonTitle',
                'isInPreviewMode',
                'backgroundGradient',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'primaryColorRGB',
                'showProgramTab',
                'hasSpeakers',
                'buttonShadowColorStyle'
            ]),
            ...mapGetters('livePage', [
                'isPollVisible',
                'isChatVisible',
                'isQuestionsVisible',
                'isHandoutVisible',
                'isQuizVisible',
                'isCustomCtaButtonVisible',
                'isEvaluationButtonVisible',
            ]),
            ...mapGetters('qld-vuex-store', ['property']),
            ...mapState('project', ['project']),
            ...mapState('livePage', ['sheet', 'externalFullscreen']),
            rgbStyleVars() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            buttonColorStyle() {
                return {
                    '--button-text-color': this.project.style.textInsideButtonColorHex,
                };
            },
            backgroundStyle() {
                return {
                    'background-image': `url(${this.backgroundImage})`,
                };
            },
            playerControlIsJitsiMeet() {
                const property = this.property('controller-switch', 'player-control', 'state');
                return typeof property !== 'undefined' && property === 'jitsi-meet';
            },
            somethingActive() {
                return this.isMultiSession
                    || this.livePageContent
                    || (this.isPollVisible && !this.showPollsOverVideo)
                    || this.isQuestionsVisible
                    || this.isChatVisible
                    || (this.isQuizVisible && ! this.showQuizOverVideo)
                    || this.showCustomTab
                    || this.isHandoutVisible
                    || this.isEvaluationButtonVisible
                    || this.isCustomCtaButtonVisible
                    || !!this.currentEvent.livePageContent
                    || this.showProgramTab
                    || this.hasSpeakers;
            },
            nothingActive() {
                return !this.isMultiSession
                    && !this.livePageContent
                    && (!this.isPollVisible || this.showPollsOverVideo)
                    && !this.isQuestionsVisible
                    && !this.isChatVisible
                    && (!this.isQuizVisible || this.showQuizOverVideo)
                    && !this.showCustomTab
                    && !this.isHandoutVisible
                    && !this.isCustomCtaButtonVisible
                    && !this.isEvaluationButtonVisible
                    && !this.currentEvent.livePageContent
                    && !this.showProgramTab
                    && !this.hasSpeakers;
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },        
            livePageContent(){
                return this.getContent(this.currentEvent.livePageContent);
            },
            livePageContentClass() {
                return {
                    'no-sidebar': this.nothingActive,
                };
            },
            isIe11(){
                return !!window.MSInputMethodContext && !!document.documentMode;
            },
            mobileSidebarHeight() {
                if (!this.isMobile) {
                    return {};
                }

                return {
                    '--mobile-sidebar-height': this.sidebarHeight +'px',
                };
            },
            tabsAreNotShownExternalFullscreen() {
                return !this.somethingActive || this.isMobile;
            },
            tabsAreNotShown(){
                return !this.somethingActive || this.externalFullscreen;
            },
            videoColumnSize(){
                return this.springerLook ?
                    {
                        xl: 9,
                        lg: 9,
                        md: 8
                    } :
                    {
                        xl: 9,
                        lg: 8,
                        md: 8
                    };
            },
            rightSideBarColumnSize(){
                return this.springerLook ?
                    {
                        xl: 3,
                        lg: 3,
                        md: 4
                    } :
                    {
                        xl: 3,
                        lg: 4,
                        md: 4
                    };
            },
            mobileHeight () {
                if (this.isMobile) {
                    let h = document.querySelector('.live-content')?.getBoundingClientRect().height ?? 0;
                    if (h < 100) {
                        h = 300;
                    }
                    return visualViewport.height
                        - document.querySelector('.header').getBoundingClientRect().height
                        - h;
                }

                return 0;
            },
            mobileStyle () {
                if (this.isMobile) {
                    return {
                        maxHeight: this.mobileHeight + 'px',
                    };
                }

                return {};
            },
        },
        methods: {
            ...mapMutations('livePage', ['openSheet', 'closeSheet', 'updateExternalFullscreen']),
            async findElement(selector) {
                return new Promise(resolve => {
                    const i = setInterval(() => {
                        const el = document.querySelector(selector);
                        if (el) {
                            clearInterval(i);
                            return resolve(el);
                        }
                    }, 10);
                });
            },
            async onResize() {
                setTimeout(async () => {
                    this.sidebarHeight = (await(this.findElement('.live-sidebar'))).getBoundingClientRect().height - 50;
                },1000);
            },
            orientationChange() {
                if (window.matchMedia('(orientation: portrait)').matches) {
                    window.QuadiaPlayer.players['qld-player-player'].$emit('externalFullscreen', {
                        newValue: true,
                        oldValue: false,
                        emulate: true,
                    });
                }
                if (window.matchMedia('(orientation: landscape)').matches) {
                    window.QuadiaPlayer.players['qld-player-player'].$emit('externalFullscreen', {
                        newValue: false,
                        oldValue: true
                    });
                }
            },
            tabSelected() {
                if (this.externalFullscreen) {
                    this.fullscreenTabsShown = true;
                }
            },
            closeFullscreenSidebar() {
                this.fullscreenTabsShown = false;
            }
        },
        watch: {
            async externalFullscreen(v) {
                console.log('trying to switch, ' + v);
                if (!v) {
                    this.fullscreenTabsShown = false;
                }
                if (screenfull.isEnabled) {
                    if (v) {
                        try {
                            await screenfull.request(this.$refs.videoWrapper);
                        } catch (e) {
                            forceFullScreen(v);
                            console.log('fullscreen request error');
                        }
                    } else {
                        try {
                            await screenfull.exit();
                        } catch (e) {
                            console.log('fullscreen exit error');
                        }
                        forceFullScreen(v);
                    }
                } else {
                    forceFullScreen(v);
                }
            }
        },
        mounted() {
            this.searchForElearningThirdPartyConnectionParams();
            this.onResize();
            window.addEventListener('resize', this.onResize);
            window.addEventListener('orientationchange', this.orientationChange);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
            window.removeEventListener('orientationchange', this.orientationChange);
        }
    };
</script>

<style scoped lang="scss">
.no-margin {
  margin: 0 !important;
}

.text-end {
  float: right;
  width: auto;
  text-align: right !important;
  margin-bottom: 0 !important;
}
.clear {
  clear: both;
}
.live-title-col {
  float: left;
  width: auto;
}

.mobile {
    .no-sidebar {
        flex: 1 1 auto !important;
        height: calc(100vh - 64px);
    }
}
</style>

<style lang="scss">
.template-default:not(.pwa) {
    .live-page.mobile {
        .live-sidebar {
            div.v-window {
                height: var(--mobile-sidebar-height);
                div.v-window__container {
                    height: var(--mobile-sidebar-height);
                    max-height: var(--mobile-sidebar-height);
                    overflow: auto;
                }
            }
            div.program-single-session,
            div.mobile-content {
                overflow: unset;
            }
        }
    }
}

.fullscreen:not(.qld-poll) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    &.mobile {
        @media('orientation: landscape') {
            iframe {
                zoom: 80%;
            }
        }
    }
}

.content-wrapper-helper.fullscreen {
    padding: 0 !important;
}

.fullscreen:not(:fullscreen .fullscreen) {
    margin-top: 8px;
}

.with-sidebar {
    padding-right: 20%;
    @media screen and (min-width: 1367px) and (max-width: 1600px) {
        padding-right: 25%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1366px) {
        padding-right: 30%;
    }
    @media screen and (max-width: 1024px) {
        padding-right: 35%;
    }
    .live-sidebar {
        right: 0;
        top: 0;
        height: 100vh !important;
        overflow: auto;
        width: 20%;
        @media screen and (min-width: 1367px) and (max-width: 1600px) {
            width:25%;
        }
        @media screen and (min-width: 1025px) and (max-width: 1366px) {
            width:30%;
        }
        @media screen and (max-width: 1024px) {
            width:35%;
        }
    }
}

.fullscreen-mode-tabs {
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(10px);
    .tabs-col {
        >div {
            border-bottom: 0 none transparent !important;
        }
    }
}

.close-fullscreen-tabs {
    position: fixed;
    right: calc(20% - 20px);
    z-index: 1;
    top: 50px;
    backdrop-filter: blur(10px);
    border-radius: 0 !important;
    @media screen and (min-width: 1367px) and (max-width: 1600px) {
        right: calc(25% - 20px);
    }
    @media screen and (min-width: 1025px) and (max-width: 1366px) {
        right: calc(30% - 20px);
    }
    @media screen and (max-width: 1024px) {
        right: calc(35% - 20px);
    }
}
</style>
