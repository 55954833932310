export const furtherEvents = (events, currentEvent, now, selectedBreakout) => {
    return events.filter((event) => {
        if (event._id === currentEvent._id) {
            // avoid navigation to same page
            return false;
        }
        if (event.breakout_group && selectedBreakout.indexOf(event._id) < 0) {
            // exclude non-selected breakouts
            return false;
        }
        return (event.startTime + threshold(event)) > now;
    });
};

const threshold = (event) => {
    return (60 * event.durationInMinutes);
};