import Vue from 'vue';
import Vuex from 'vuex';
import projectStore from '@/store/projectStore';
import registrationStore from '@/store/registrationStore';
import timeStore from '@/store/timeStore';
import livePageStore from '@/store/livePageStore';
import dynamicBlocksStore from '@/store/dynamicBlocksStore';
import dynamicWaitingPageBlockStore from '@/store/dynamicBlocksStore';
import snackbarStore from '@/store/snackbar';
import endPageStore from '@/store/endPageStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    time: timeStore,
    project: projectStore,
    registration: registrationStore,
    livePage: livePageStore,
    endPage: endPageStore,
    dynamicBlocks: dynamicBlocksStore,
    waitingPageDynamicBlocks: dynamicWaitingPageBlockStore,
    snackbar: snackbarStore
  }
});
