export const walkRecursive = (blocks, callback, level) => {
    for (let i = 0; i < blocks.length; i++) {
        callback(blocks[i], level);

        if (blocks[i].componentOptions.blocks) {
            walkRecursive(blocks[i].componentOptions.blocks, callback, level + 1);
        }
    }
};

export const dispatchLoaded = (blocks, refs) => {
    let blockOffsets = [];
    walkRecursive(blocks, (block, level) => {
        const blockId = block._id;
        blockOffsets.push({
            id: blockId,
            offsetHeight: refs[`block-${blockId}`][0].offsetHeight,
            level,
        });
    }, 0);

    const totalHeight = parseInt(window.getComputedStyle(document.querySelector('.block-container')).height) + 120;

    window.parent.postMessage(
        JSON.stringify({
            event: 'qlive-app-blocks-loaded',
            components: blockOffsets,
            scrollHeight: totalHeight,
        }),
        '*'
    );
};

export const blockClass = (block) => {
    return {
        third: 'third-width',
        'two-thirds': 'two-third-width',
        half: 'half-width',
        full: 'full-width',
    }[block.width];
};

export const blockStyle = (block, isMobile) => {
    return {
        'background-color': block.backgroundColor,
        'background-image': `url(${block.backgroundImage?.url || ''})`,
        'background-size': 'cover',
        'background-position': isMobile ? 'center center' : '',
        '--block-text-color': block?.textColor || '#fff'
    };
};

export const getStyles = (primaryColorRGB) => {
    return {
        '--r': primaryColorRGB.r,
        '--g': primaryColorRGB.g,
        '--b': primaryColorRGB.b,
    };
};

export const hasGradient = (block) => {
    return block?.gradient || false;
};
