<template>
    <router-link :to="supportRoute">
        {{
            $t('layout.support')
        }}
    </router-link>
</template>

<script>
    export default {
        name: 'SupportLink',
        computed: {
            supportRoute() {
                return {
                    name: this.$route.name,
                    params: this.$route.params,
                    query: this.$route.query,
                    hash: '#support'
                };
            },
        }
    };
</script>
