const dynamicBlocksStore = {
    namespaced: true,
    state: () => ({
        elementToScrollTo: null,
        blocksFromPreview: null,
        openRegistrationForm: false
    }),
    getters: {
        elementToScrollTo(state) {
            return state.elementToScrollTo;
        },    
        blocksFromPreview(state) {
            return state.blocksFromPreview;
        }, 
        openRegistrationForm(state){
            return state.openRegistrationForm;
        }
    },
    mutations: {
        setElementToScrollTo(state, payload) {
            state.elementToScrollTo = payload;
        },
        setBlocksFromPreview(state, payload) {
            state.blocksFromPreview = payload;
        }, 
        setOpenRegistrationForm(state, payload){
            state.openRegistrationForm = payload;
            if(payload){
                document.body.style.overflowY = 'hidden';
                document.body.style.height = '100vh';
            } else {
                document.body.style.overflowY = '';
                document.body.style.height = 'auto';            
            }
        }
    }
};

export default dynamicBlocksStore;
