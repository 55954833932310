import { en } from 'vuetify/es5/locale';

export default {
    ...en,
    registration: {
        title: 'Register',
        submit: 'Continue',
        join: 'Join',
        accept_terms_1: 'We process your personal data. Please read our ',
        accept_terms_2: 'privacy policy',
        accept_terms_3: ' on how we do this.',
        thanks: 'Thank you for registering!',
        watch: 'Go to the eventpage',
        watch_pwa: 'Open web app',
        personal_link_advice: 'This is your personal link. Please do not share this link or forward this email.',
        add_to_calendar: 'Add to calendar',
        loading: 'Loading...',
        speakers: 'Speakers',
        confirmation_title: 'Registration successful.',
        simplesession_access: 'For more information please access the event.',
        multisession_access: 'For more information, or changing breakout rooms, please access the event.',
        pwa_access: 'Click on the button below to open the web app for this event.',
        event_full: 'This session is currently full',
        cannot_register_start: 'Could not register for ',
        cannot_register_end_singular: 'It is full.',
        cannot_register_end_plural: 'They are full.',
        view_online: 'I will attend the event online',
        view_offline: 'I will attend the event at the venue',
        seats_remaining: '{$x} seats left',
        one_seat_remaining: '1 seat left',
        unregister: 'Unregister',
        unregistered: 'You unregistered successfully.',
        unregister_title: 'Unregister from this event',
        already_unregistered: 'You have been unregistered from this event.',
        change_attendance_title_online: 'Change attendance to online',
        change_attendance_title_on_site: 'Change attendance to on-site | {$x} seats left',
        change_attendance_title_on_site_one_seat: 'Change attendance to on-site | 1 seat left',
        change_attendance_button: 'Change attendance',
        change_attendance_information: 'You will receive a new confirmation email with information about the event.',
        attendance_changed_to_online: 'Attendance changed to: online.',
        attendance_changed_to_on_site: 'Attendance changed to: on-site.',
        agreement: 'I agree to delete my personal data and to lose access to this event.',
    },
    agenda: {
        program: 'Program',
        breakout_round: 'Breakout round',
        comingUpNext: 'Coming up next',
    },
    evaluation: {
        submit: 'Send',
        resubmit: 'Update your answer',
        processing: 'Please wait, evaluation is being sent ...',
        thanks: 'The evaluation has been sent. Thank you for completing!',
        you_can_leave: 'You can now exit this screen.',
        you_can_leave_pwa: 'You will now automatically exit this screen.',
    },
    splash: {
        test: 'Test uw audio en video',
    },
    test: {
        title: 'Test your audio and video',
        video_url: 'https://s3.eu-west-1.amazonaws.com/video.quadia.net/public/622b17bce672c768cd304453/f054b5bf-138c-4513-aa00-268913ff7f58-hls.m3u8',
        content: '<p>Can\'t hear any sound? Check if the video is muted. The sound icon at the bottom left will then contain a cross. Click again to activate the sound. Have any further questions? Check our support page at the top right.</p>'
    },
    event: {
        LIVE: 'LIVE',
        ask_a_question: 'Ask a question',
        poll: 'Poll',
        no_poll: 'The poll questions will appear here',
        chat: 'Chat',
        evaluation_button: 'Evaluation',
        unmute_text: 'Sound on',
        quiz: 'Quiz',
        no_quiz: 'The quiz questions will appear here',
        info: 'Info',
        handout: 'Handout',
        home: 'Home',
        polls: 'Polls',
        evaluation: 'Evaluation',
        download_handout_question: 'Are you sure you want to proceed with download?',
        download_handout_title: 'Download handout',
        cancel_download: 'Cancel',
        proceed_download: 'Download',
        poll_feedback: 'Your answer was sent successfully.',
        question_submitted: 'Your message was sent successfully.',
        question_message_placeholder: 'Type your question here',
    },
    countdown: {
        text: 'We will start in',
        days: 'days',
        hours: 'hours',
        minutes: 'minutes',
        seconds: 'seconds',
        is_about_to_start: 'We are about to start. You will be redirected automatically.',
        now: 'This event has already started.',
        ended: 'This event has already ended.',
    },
    layout: {
        support: 'Support'
    },
    fields: {//registration & evaluation
        choose_answer: 'Select an answer',
        enter_answer: 'Submit an answer',
        validation: {
            required: 'Required field',
            required_checkbox: 'Select at least one item',
            notEmail: 'Invalid email',
            notPhoneNumber: 'Invalid phone number',
            fixedLength: 'This entry must be {length} characters long',
            notANumber: 'This entry must be a positive integer number',
            notBetween: 'This entry must be between {min} and {max}',
        },
        mobile_text: 'for a one-time reminder'
    },
    supportModal: {
        title: 'Frequently asked questions',
        sound: {
            title: 'Audio',
            question_1: 'Why can\'t I hear any audio?',
            answer_1: 'Prior to our broadcasts, the stream will contain audio so you can adjust your audio and volume settings.',
            answer_1_subq_1: 'External speakers',
            answer_1_suba_1: 'If you have external speakers, please make sure they’re properly connected.',
            answer_1_subq_2: 'Stream setting',
            answer_1_suba_2: 'Your browser may have automatically muted the audio. You can adjust the volume at the bottom left of the media player with the sound symbol.',
            answer_1_subq_3: 'System setting',
            answer_1_suba_3: 'Make sure your PC/laptop is not muted.',
            question_2: 'Why do I hear an echo?',
            answer_2: 'The broadcast may have been opened multiple times in your browser, for example in multiple windows or tabs. Close the tabs and browser windows that are not being used to view this broadcast.',
        },
        vision: {
            title: 'Vision',
            question_1: 'The media player only shows a logo',
            answer_1: 'This problem can be caused by:',
            answer_1_subq_1: 'VPN',
            answer_1_suba_1: 'If you have turned on a Virtual Private Network (VPN), it can block the media player. Turn it off temporarily.',
            answer_1_subq_2: 'Citrix',
            answer_1_suba_2: 'Citrix is an application that simulates a computer environment via a server, you often log in to it. If you are using Citrix, it must be disabled / logged out to view the broadcast.',
            answer_1_subq_3: 'Ad Blocker',
            answer_1_suba_3: 'If you have Ad Blocker on, it can block the media player, turn it off temporarily.',
            answer_1_subq_4: 'Browser',
            answer_1_suba_4: 'Check if your browser is up to date. Some older browsers cannot support the media player.',
            question_2: 'The media player shows a black screen',
            answer_2_part_0: 'This could be caused by several issues:',
            answer_2_part_1: 'Please verify if your browser meets the minimum requirements',
            answer_2_part_2: 'Your internet connection might be unstable or too slow. Please check your connection and refresh the page',
            answer_2_part_3: 'The network environment you are connected to, blocks the loading of our player. Please contact your network administrator',
        },
        network: {
            title: 'Network',
            question_1: 'The video stutters or buffers a lot',
            answer_1_part_1: 'The signal can be disrupted by a slow internet connection. Make sure you have sufficient bandwidth and preferably use a fast WiFi or cable connection. A minimum of 10 download mbps is recommended. You can check your internet speed with a ',
            answer_1_part_2: 'https://www.speedtest.net/',
            answer_1_part_3: 'speed test.',
            question_2: 'Can I watch a broadcast from an office / practice / hospital environment?',
            answer_2: 'For security reasons, many companies and hospitals have restricted access to live streams. Consult your IT department to check that there are no restrictions on watching live broadcasts.'
        },
        browsers: {
            title: 'Browsers',
            subtitle: 'You can watch the broadcast properly with the following supported browsers:',
            question_1: 'Recommended browser requirements',
            answer_1_part_1: 'Google Chrome 88+',
            answer_1_part_2: 'Mozilla Firefox 85+',
            answer_1_part_3: 'Microsoft Edge 88+',
            answer_1_part_4: 'Apple Safari 14+',
            answer_1_part_5: 'iOS: Mobile Safari -or- Chrome (iOS14 +)',
            answer_1_part_6: 'Android: Chrome 88+',
            question_2: 'Minimal browser requirements',
            answer_2_part_1: 'Google Chrome 77+',
            answer_2_part_2: 'Mozilla Firefox 68+',
            answer_2_part_3: 'Microsoft Edge 77+',
            answer_2_part_4: 'Apple Safari 12+',
            answer_2_part_5: 'iOS: Mobile Safari -or- Chrome (iOS10 +)',
            answer_2_part_6: 'Android: Chrome 77+',
        },
        system: {
            title: 'Operating system',
            question_1: 'Operating system specifications',
            answer_1_part_1: 'Minimum required:',
            answer_1_part_1_suba_1: 'Windows: 32-bit or 64-bit Windows 7 (SP1), Windows 8, Windows 8.1, Windows 10 or Windows 11',
            answer_1_part_1_suba_2: 'Android: from 8.0 (OREO)',
            answer_1_part_1_suba_3: 'Mac: OS version: Metal required. macOS X 10.11 (El Capitan)',
            answer_1_part_1_suba_4: 'iOS: from 10',
            answer_1_part_2: 'Recommended:',
            answer_1_part_2_suba_1: 'Windows: 64-bit Windows 10',
            answer_1_part_2_suba_2: 'Android: 10',
            answer_1_part_2_suba_3: 'Mac: OS version: Metal required. macOS X 10.14 (Mojave)',
            answer_1_part_2_suba_4: 'iOS: 12',
        },
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        overview: {
            title: 'General',
            content: 'Motionmakers wants you to be familiar with how we collect, use and disclose Personal Information and Non-Personal Information (each as defined below). This Privacy Policy describes our practices in connection with information that we collect for the webinars. This Privacy Policy does not address the collection, use or disclosure of information by any third parties. We will never pass on your details to third parties without your permission.'
        },
        liveBroadcast: {
            title: 'Live broadcast',
            content: 'By subscribing to and participating in a live broadcast, you consent to the storage of your personal data. We do this to give people access to the broadcast. In addition, we can predict the number of viewers and remind you when a broadcast will start.',
        },
        cookies: {
            title: 'Cookies',
            content: 'To watch the live broadcast, the Motionmakers Online Video player is used. This player uses functional and analytical cookies. The functional cookies are required for the operation of the video player. For example, remembering the volume or other functionalities of the video player. The analytical cookies are used to keep track of which viewers, how many viewers there are and how long each viewer follows the broadcast.'
        },
        savedData: {
            title: 'Information we store',
            content: 'We store all data requested in the registration form: first name, last name, e-mail address, IP address and open questions. You can also ask questions by e-mail during the broadcast. These questions with corresponding e-mail address are also stored.'
        },
        retentionPeriod: {
            title: 'Retention period',
            content: 'All data is stored for a maximum of 6 months after the live broadcast has ended. All information is then automatically destroyed.'
        },
        dataAccess: {
            title: 'Access data',
            content: 'The data can only be viewed by Motionmakers and by the client of the broadcast. All data is stored securely and is not accessible to third parties.'
        },
        contact: {
            title: 'Contact',
            content: 'For questions and remarks, please send an e-mail message to <a href="mailto:privacy@motionmakers.nl">privacy@motionmakers.nl</a> or please write to the following address:'
        },
        contactAddressLine1: 'Motionmakers',
        contactAddressLine2: 'T.a.v: Privacy team',
        contactAddressLine3: 'Frits Spitsstraat 3',
        contactAddressLine4: '1217 WC Hilversum',
        contactAddressLine5: 'The Netherlands',
        contactAddressLine6: '+31 35 77 33 100',
        contactAddressLine7: 'info@motionmakers.nl'
    },
    termsAndConditions: {
        title: 'Terms and conditions',
    },
    security: {
        title: 'This page is secured',
        enterPassword: 'Please enter the password that has been provided to you by the organiser.',
        error: 'The password you entered is invalid. Note that the password is case-sensitive.',
        capsLockOn: 'Warning Caps lock is ON',
        button: 'Watch',
    },
    singleSession: {
        alreadyWatching: 'This unique viewing link is already open in a different browser. If you did not share this URL with someone else, please check if this link is opened on one of your other browsers or devices.',
    },
    multiSession: {
        selectBreakout: 'I select this breakout session',
        changeBreakout: 'Change breakout',
        subscribingFor: 'I want to register and I choose:',
        breakoutRound: 'Breakout round',
        breakoutRoundValidationText: 'Please select a session for all breakout rounds.',
        register: 'Register',
        ended: 'Ended',
        now: 'NOW',
        join: 'JOIN',
        watch: 'WATCH',
        autoNavigationTitle: 'This session has ended.',
        autoNavigationText: 'You will be automatically redirected to you next session {{session}} in <b>{{countdownSeconds}} seconds</b>.',
        autoNavigationNavigateNowButton: 'Go to next session',
        sessionWillEndIn: 'This session will end in <b>{{countdown}}</b> seconds.',
        sessionWillEndSoon: 'This session is about to end.',
        breakoutWillEndIn: 'This breakout will end in <b>{{countdown}}</b> seconds.',
        breakoutWillEndSoon: 'This breakout is about to end.',
    },
    calendar: {
        downloaded_text: 'The calendar file has been downloaded. Please check your downloads.',
        click_to_download: 'Click here to download again',
        cta_before_link: 'Click here to join',
        month1: 'January',
        month2: 'February',
        month3: 'March',
        month4: 'April',
        month5: 'May',
        month6: 'June',
        month7: 'July',
        month8: 'August',
        month9: 'September',
        month10: 'October',
        month11: 'November',
        month12: 'December',
        dayPlaceholder: 'day',
        monthPlaceholder: 'month',
        yearPlaceholder: 'year',
    },
    loginPage: {
        welcome: 'Welcome',
        loginFormInstruction: 'If you have already registered, please enter your e-mail address below:',
        emailAddress: 'E-mail address:',
        loginFormSubmitText: 'Login',
        registrationLinkInstruction: 'If you didn\'t sign up for this event yet, please complete the Registration form.',
        registrationButtonText: 'Register',
        loginError: 'Please double-check your email address. We did not find a registration for this email address.',
    }
};
