<template>
    <div>
        <qld-quiz-qlive id="video-quiz"
                        ref="quizContainer"
                        @tick="tick"
                        @feedback="showQuizFeedback"
        />
        <div v-if="show"
             class="confirmation-message mt-15"
        >
            {{ $t('event.poll_feedback') }}
            <v-btn icon
                   color="white"
                   @click="hideQuizFeedback"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import getSvgPath, {svgBase} from '@/services/CustomPollCountdown';

    export default {
        name: 'PlayerQuiz',
        computed: {
            ...mapState('project', ['project']),
        },
        data(){
            return {
                show: false
            };
        },
        methods: {
            showQuizFeedback(){
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            },
            hideQuizFeedback(){
                this.show = false;
            },
            tick(percentLeft) {
                const el = this.getElement();
                if (el) {
                    el.querySelector('path').setAttribute('d', getSvgPath(100 - percentLeft));
                }
            },
            getElement() {
                if (this.$refs.quizContainer.$el.nodeType !== Node.ELEMENT_NODE) {
                    return null;
                }
                const countdownBarElement = this.$refs.quizContainer.$el.querySelector('.qld-quiz-countdown-bar');

                if (!countdownBarElement) {
                    return null;
                }
                const el = countdownBarElement.querySelector('svg');
                if (!el) {
                    countdownBarElement.innerHTML = svgBase;
                }
                return countdownBarElement.querySelector('svg');
            }
        },
    };
</script>

<style scoped lang="scss">
@import 'src/_scss/components/_colors.scss';
    .confirmation-message{
        position: absolute;
        bottom: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        background: $success;
        border-radius: var(--card-border-radius);
        color: #fff;
        font-size: 14px;
        width: 40%;
        margin: 0 30%;
    }

</style>

<style lang="scss">

#video-quiz {
    position: absolute;
    bottom: 15%;
    width: 60%;
    margin: 0 20%;
    background-color: rgba(255,255,255,0.9) !important;
    padding: 0;
    color: #3E3E3E;
    color: var(--text-color);
    border-radius: var(--card-border-radius);

    &>div {
        padding: 5px 10px;
        color: #3e3e3e;
    }

    p{
        /*fallback for ie*/
        background-color: #1b8dff;
        background-color: var(--darken-primary-color);
        filter: brightness(0.85);
        color: var(--text-color);
        margin-bottom: 4px !important;
        line-height: normal;
        border-top-left-radius: var(--card-border-radius);
        border-top-right-radius: var(--card-border-radius);
        padding: 7px 40px 8px 10px;
    }

    li{
        font-size: 16px;
        padding-bottom: 1px;
        input{
            margin-left: -20px;
            opacity: 0;
        }
        label{
            color: #3e3e3e;
            position: relative;
            display: inline-block;
            padding-left: 20px;
            &:before {
                width: 15px;
                height: 15px;
                display: inline-block;
                /*fallback for ie*/
                border: 1px solid #1b8dff;
                border: 1px solid var(--darken-primary-color);
                content: '';
                border-radius: 50%;
                position: absolute;
                margin-right: 4px;
                left: 0;
                top: 0;
            }
            &:after {
                width: 9px;
                height: 9px;
                display: inline-block;
                content: '';
                border-radius: 50%;
                position: absolute;
                left: 3px;
                top: 3px;

            }
        }
        input:checked + label {
            &:after {
                border: 1px solid #1b8dff;
                border: 1px solid var(--darken-primary-color);
                background-color: #1b8dff;
                background-color: var(--darken-primary-color);
            }
        }
    }

    ul {
        list-style-type: none !important;
        margin: 5px 0;
        padding-left: 16px;
    }

    .qld-quiz-countdown {
        position: absolute;
        z-index: 1;
        right: 2px;
        top: 2px;
        color: #E3E3E3;
        color: var(--text-color);
        width: 31px;
        height: 31px;
        .qld-quiz-countdown-digit {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 !important;
            justify-content: space-around;
            font-size: 12px;
            font-weight: bold;
            left: 0;
            top: 0;
        }
        .qld-quiz-countdown-bar {
            position: absolute;
            left:0;
            top: 0;
            width: 100%;
            height: 100%;
            svg {
                width: 100%;
                height: 100%;
                fill: white;
            }
        }
    }

    table {
        color: #3e3e3e;
        margin: 5px 10px;
        width: 100%;
    }

    @media screen and (min-width: 961px) and (max-width: 1266px)  {
        bottom: 1%;
        width: 90%;
        margin: 0 5%;
        transform: scale(0.6);
    }
}

.with-sidebar {
    #video-quiz {
        margin: 0 40% 0 20%;
        width: 40%;
    }
}
</style>
