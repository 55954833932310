<template>
    <div class="speakers"
         :class="{'mobile': isMobile, 'tablet': isTablet, 'gradient': gradient}"
         :style="styles"
    >
        <span class="speaker-title">
            {{ $t('registration.speakers') }}
        </span>
        <div class="speakers-wrapper">
            <div v-for="speaker in speakers"
                 :key="`speaker-${speaker.id}`"
                 class="speaker"
                 :class="{'disable-link' : disableLink(speaker.link)}"
            >
                <div class="speaker-main-information-wrapper">
                    <a :href="speaker.link"
                       target="_blank"
                       class="speaker-avatar-link"
                    >
                        <v-avatar>
                            <img v-if="speaker.avatar.url"
                                 :src="speaker.avatar.url"
                                 :alt="speaker.name"
                            >
                            <v-icon v-else>person</v-icon>
                        </v-avatar>
                    </a>
                    <div class="speaker-name-title-wrapper">
                        <a :href="speaker.link"
                           target="_blank"
                           class="speaker-name-link"
                        >
                            <span>
                                {{ speaker.name }}
                            </span>
                        </a>
                        <span class="speaker-job-title">
                            {{ speaker.title }}
                        </span>
                    </div>
                </div>
                <p class="speaker-summary">
                    {{ speaker.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'Speakers',
        props: {
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        computed: {
            ...mapGetters('project', ['speakers', 'isInPreviewMode', 'primaryColorRGB']),
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                    '--percentage': this.speakerWidth
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.xsOnly;
            },
            isTablet() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            speakerWidth(){
                const numberOfSpeakers = this.speakers.length;
                if(numberOfSpeakers === 1){
                    return '100%';
                }
                if(numberOfSpeakers === 2){
                    return '50%';
                }
                if(numberOfSpeakers === 3){
                    return '30%';
                }
                if(numberOfSpeakers === 4){
                    return '25%';
                }
                if(numberOfSpeakers <= 6 ){
                    return '30%';
                }
                if(numberOfSpeakers <= 8 ){
                    return '25%';
                }
                if(numberOfSpeakers === 9){
                    return '30%';
                }
                if(numberOfSpeakers === 10){
                    return '20%';
                }
                return '25%';
            }
        },
        methods: {
            disableLink(link) {
                return link === '' || this.isInPreviewMode ? true : false;
            }
        }
    };
</script>

<style lang="scss" scoped>

$font-size-base-px: 16px;
@function rem-calc($px) {
     @return $px / $font-size-base-px * 1rem;
}

.speakers{
    padding: 60px 92px;
    @media (min-width:1600px) and (max-width: 1800px) {
        padding: 40px 90px;
    }
    
    @media (min-width:960px) and (max-width: 1600px) {
        padding: 40px 80px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(120.44deg, rgba(0, 0, 0, 0.5) -9.32%, rgba(var(--r), var(--g), var(--b), 0.5) 66.93%);
    }

    .speaker-title{
        font-weight: 500;
        font-size: rem-calc(42px);
        line-height: 120%;
        color: var(--block-text-color);
        margin-bottom: 42px;
    }

    .speakers-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;

        .speaker{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            gap: 24px;
            flex: 1 0 calc(var(--percentage) - 30px);
            max-width: calc(var(--percentage) - 30px);
            min-width: 300px;
            margin-left: 15px;
            margin-right: 15px;

            .speaker-main-information-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                gap: 24px;
                .speaker-avatar-link{
                    .v-icon {
                        color: #fff;
                        font-size: 6rem;
                    }
                }
                .speaker-name-title-wrapper{
                    display: flex;
                    flex-direction: column;
                    .speaker-name-link{
                        font-weight: 500;
                        font-size: rem-calc(24px);
                        line-height: 120%;
                        text-decoration: none;
                        color: var(--block-text-color);
                        margin-bottom: 4px;
                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                    .speaker-job-title{
                        color: var(--block-text-color);
                        font-weight: 400;
                        font-size: rem-calc(18px);
                        line-height: 120%;
                    }
                }
            }
            .speaker-summary{
                font-size: rem-calc(16px) !important;
                color: var(--block-text-color);

            }
        }
    }

    &.tablet{
        padding: 60px 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;

        .speaker-title{
            margin-bottom: 40px;
        }

        .speakers-wrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;

            .speaker{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                gap: 20px;
                width: auto;
                flex: none;
                max-width: none;
                .speaker-main-information-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0;
                    gap: 20px;

                    .speaker-name-title-wrapper{
                        display: flex;
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    &.tablet.mobile{
        .speaker-title{
            font-size: rem-calc(21px);
            margin-bottom: 20px;
            align-self: center;
        }
        .speakers-wrapper{
            gap: 16px;
            .speaker{
                gap: 10px;
                width: auto;
                flex: none;
                max-width: none;
                min-width: 0;
                .speaker-main-information-wrapper{
                    gap: 10px;
                    .speaker-name-link{
                        font-size: rem-calc(16px);
                    }
                    .speaker-job-title{
                        font-size: rem-calc(12px)
                    }
                }
            }
        }
        .speaker-summary{
            font-size: rem-calc(13px) !important;
        }
    }

    &.mobile{
        padding: 42px 69px;
    }
}
.half-width{
    .speakers{
        padding: 60px 30px;
        @media (min-width:1600px) and (max-width: 1800px) {
            padding: 40px 20px;
        }

        @media (min-width:960px) and (max-width: 1600px) {
            padding: 40px 10px;
        }

        display: flex;
        flex-direction: column;
        text-align: start;
        align-items: center;

        .speaker-title{
            margin-bottom: 20px;
        }

        .speakers-wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .speaker{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                flex: 1 1 30%;
                @media (max-width: 1360px) {
                    flex: 1 1 50%;
                }
                max-width: none !important;
                min-width: auto !important;
                margin: 0;
                .speaker-main-information-wrapper{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;

                    .speaker-name-title-wrapper{
                        display: flex;
                        align-items: center;
                        .speaker-name-link {
                          font-size: rem-calc(20px);
                          text-align: center;
                        }
                    }

                    .speaker-job-title {
                      font-size: rem-calc(16px);
                      text-align: center;
                    }
                }
            }
        }

        &.tablet{
            padding: 60px 90px;
        }

        &.mobile{
            padding: 42px 69px;
            .speaker-title{
                font-size: 21px;
                margin-bottom: 20px;
            }
            .speakers-wrapper{
                gap: 16px;
                .speaker{
                    gap: 10px;
                    min-width: 0;
                    .speaker-main-information-wrapper{
                        gap: 10px;
                        .speaker-name-link{
                            font-size: rem-calc(16px);
                        }
                        .speaker-job-title{
                            font-size: rem-calc(12px)
                        }
                    }
                }
            }
            .speaker-summary{
                font-size: rem-calc(13px) !important;
            }
        }
    }
}

.disable-link {
    a{
        pointer-events: none;
    }
}

</style>

<style lang="scss">
$font-size-base-px: 16px;
@function rem-calc($px) {
     @return $px / $font-size-base-px * 1rem;
}
.speakers{
    .speakers-wrapper{
        .speaker-avatar-link{
            .v-avatar{
                min-width: rem-calc(170px)!important;
                max-width: rem-calc(170px) !important;
                min-height: rem-calc(170px) !important;
                max-height: rem-calc(170px) !important;
                img{
                    min-width: rem-calc(170px) !important;
                    max-width: rem-calc(170px) !important;
                    min-height: rem-calc(170px) !important;
                    max-height: rem-calc(170px) !important;
                }
            }
        }
    }
    &.mobile{
        .speakers-wrapper{
            .speaker-avatar-link{
                .v-avatar{
                    min-width: rem-calc(60px) !important;
                    max-width: rem-calc(60px) !important;
                    min-height: rem-calc(60px) !important;
                    max-height: rem-calc(60px) !important;
                    img{
                        min-width: rem-calc(60px) !important;
                        max-width: rem-calc(60px) !important;
                        min-height: rem-calc(60px) !important;
                        max-height: rem-calc(60px) !important;
                    }
                }
            }
        }
    }
}

.half-width{
    .speakers{
        .speakers-wrapper{
            .speaker-avatar-link{
                .v-avatar{
                    min-width: rem-calc(100px) !important;
                    max-width: rem-calc(100px) !important;
                    min-height: rem-calc(100px) !important;
                    max-height: rem-calc(100px) !important;
                    img{
                        min-width: rem-calc(100px) !important;
                        max-width: rem-calc(100px) !important;
                        min-height: rem-calc(100px) !important;
                        max-height: rem-calc(100px) !important;
                    }
                }
            }
        }
        &.mobile{
            .speakers-wrapper{
                .speaker-avatar-link{
                    .v-avatar{
                        min-width: rem-calc(60px) !important;
                        max-width: rem-calc(60px) !important;
                        min-height: rem-calc(60px) !important;
                        max-height: rem-calc(60px) !important;
                        img{
                            min-width: rem-calc(60px) !important;
                            max-width: rem-calc(60px) !important;
                            min-height: rem-calc(60px) !important;
                            max-height: rem-calc(60px) !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
