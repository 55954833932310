<template>
    <div class="support">
        <component :is="getHeader"
                   class="font-weight-regular"
                   :class="headerClass"
        >
            {{ $t('supportModal.sound.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa" />
        <v-expansion-panels flat
                            multiple
        >
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp1)"
                    @click="exp1 = !exp1"
                >
                    {{ $t('supportModal.sound.question_1') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('supportModal.sound.answer_1') }}
                    <ul>
                        <li>
                            {{ $t('supportModal.sound.answer_1_subq_1') }} <br>
                            <i>{{ $t('supportModal.sound.answer_1_suba_1') }}</i>
                        </li>
                        <li>
                            {{ $t('supportModal.sound.answer_1_subq_2') }} <br>
                            <i>{{ $t('supportModal.sound.answer_1_suba_2') }}</i>
                        </li>
                        <li>
                            {{ $t('supportModal.sound.answer_1_subq_3') }} <br>
                            <i>{{ $t('supportModal.sound.answer_1_suba_3') }}</i>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp2)"
                    @click="exp2 = !exp2"
                >
                    {{ $t('supportModal.sound.question_2') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('supportModal.sound.answer_2') }}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <component :is="getHeader"
                   class="font-weight-regular"
                   :class="headerClass"
        >
            {{ $t('supportModal.vision.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa" />
        <v-expansion-panels flat
                            multiple
        >
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp3)"
                    @click="exp3 = !exp3"
                >
                    {{ $t('supportModal.vision.question_1') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('supportModal.vision.answer_1') }}
                    <ul>
                        <li>
                            {{ $t('supportModal.vision.answer_1_subq_1') }} <br>
                            <i>{{ $t('supportModal.vision.answer_1_suba_1') }}</i>
                        </li>
                        <li>
                            {{ $t('supportModal.vision.answer_1_subq_2') }} <br>
                            <i>{{ $t('supportModal.vision.answer_1_suba_2') }}</i>
                        </li>
                        <li>
                            {{ $t('supportModal.vision.answer_1_subq_3') }} <br>
                            <i>{{ $t('supportModal.vision.answer_1_suba_3') }}</i>
                        </li>
                        <li>
                            {{ $t('supportModal.vision.answer_1_subq_4') }} <br>
                            <i>{{ $t('supportModal.vision.answer_1_suba_4') }}</i>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp4)"
                    @click="exp4 = !exp4"
                >
                    {{ $t('supportModal.vision.question_2') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>
                        {{ $t('supportModal.vision.answer_2_part_0') }}
                    </p>
                    <ul>
                        <li>
                            <span class="internal-link"
                                  :style="internalLinkStyle"
                                  @click.stop="openBrowserSection"
                            >{{ $t('supportModal.vision.answer_2_part_1') }}</span>
                        </li>
                        <li>{{ $t('supportModal.vision.answer_2_part_2') }}</li>
                        <li>{{ $t('supportModal.vision.answer_2_part_3') }}</li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <component :is="getHeader"
                   class="font-weight-regular"
                   :class="headerClass"
        >
            {{ $t('supportModal.network.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa" />
        <v-expansion-panels flat
                            multiple
        >
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp5)"
                    @click="exp5 = !exp5"
                >
                    {{ $t('supportModal.network.question_1') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('supportModal.network.answer_1_part_1') }}
                    <a :href="speedTest"
                       target="_blank"
                    >{{ $t('supportModal.network.answer_1_part_3') }}</a>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp6)"
                    @click="exp6 = !exp6"
                >
                    {{ $t('supportModal.network.question_2') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ $t('supportModal.network.answer_2') }}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <component :is="getHeader"
                   class="font-weight-regular"
                   :class="headerClass"
        >
            {{ $t('supportModal.browsers.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa" />
        <p> {{ $t('supportModal.browsers.subtitle') }} </p>
        <v-expansion-panels ref="browser-section"
                            v-model="browserSections"
                            flat
                            multiple
        >
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp7)"
                    @click="exp7 = !exp7"
                >
                    {{ $t('supportModal.browsers.question_1') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul>
                        <li> {{ $t('supportModal.browsers.answer_1_part_1') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_1_part_2') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_1_part_3') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_1_part_4') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_1_part_5') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_1_part_6') }}</li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp8)"
                    @click="exp8 = !exp8"
                >
                    {{ $t('supportModal.browsers.question_2') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul>
                        <li> {{ $t('supportModal.browsers.answer_2_part_1') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_2_part_2') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_2_part_3') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_2_part_4') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_2_part_5') }}</li>
                        <li> {{ $t('supportModal.browsers.answer_2_part_6') }}</li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <component :is="getHeader"
                   class="font-weight-regular"
                   :class="headerClass"
        >
            {{ $t('supportModal.system.title') }}
        </component>
        <v-divider v-if="isRegisteredForPwa" />
        <v-expansion-panels flat
                            multiple
        >
            <v-expansion-panel>
                <v-expansion-panel-header
                    :expand-icon="chooseIcon(exp9)"
                    @click="exp9 = !exp9"
                >
                    {{ $t('supportModal.system.question_1') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ul>
                        <li>
                            {{ $t('supportModal.system.answer_1_part_1') }}
                            <ul>
                                <li> {{ $t('supportModal.system.answer_1_part_1_suba_1') }}</li>
                                <li class="important-color">
                                    {{ $t('supportModal.system.answer_1_part_1_suba_2') }}
                                </li>
                                <li> {{ $t('supportModal.system.answer_1_part_1_suba_3') }}</li>
                                <li> {{ $t('supportModal.system.answer_1_part_1_suba_4') }}</li>
                            </ul>
                        </li>
                        <li>
                            {{ $t('supportModal.system.answer_1_part_2') }}
                            <ul>
                                <li> {{ $t('supportModal.system.answer_1_part_2_suba_1') }}</li>
                                <li class="important-color">
                                    {{ $t('supportModal.system.answer_1_part_2_suba_2') }}
                                </li>
                                <li> {{ $t('supportModal.system.answer_1_part_2_suba_3') }}</li>
                                <li> {{ $t('supportModal.system.answer_1_part_2_suba_4') }}</li>
                            </ul>
                        </li>
                    </ul>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'Support',
        data() {
            return {
                exp1: false,
                exp2: false,
                exp3: false,
                exp4: false,
                exp5: false,
                exp6: false,
                exp7: false,
                exp8: false,
                exp9: false,
                browserSections: [],
            };
        },
        computed: {
            ...mapGetters(
                'project',
                [
                    'primaryColor',
                    'pwaPrimaryColor',
                    'pwaDarkenPrimaryColor',
                    'darkenPrimaryColor',
                    'darkenPrimaryColorStyle',
                    'pwaDarkenPrimaryColorStyle',
                    'fontFamilyStyle'
                ]
            ),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            internalLinkStyle() {
                return {
                    color: this.isRegisteredForPwa ? this.pwaDarkenPrimaryColor : this.darkenPrimaryColor,
                };
            },
            speedTest: {
                get() {
                    return this.$t('supportModal.network.answer_1_part_2');
                }
            },
            getHeader() {
                return this.isRegisteredForPwa ? 'h4' : 'h2';
            },
            headerClass() {
                const classes = [
                    this.isRegisteredForPwa ? 'mt-5' : 'mt-10',
                ];

                if (this.isRegisteredForPwa) {
                    classes.push('mb-2');
                }

                return classes;
            }
        },
        methods: {
            chooseIcon(value) {
                return value ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline';
            },
            openBrowserSection() {
                this.browserSections = [0, 1];
                this.exp7 = this.exp8 = true;
                setTimeout(() => {
                    this.$refs['browser-section'].$el.scrollIntoView(true, true);
                }, 500);
            },
        }
    };
</script>

<style scoped lang="scss">
.support-link {
    color: #3E3E3E;
    margin-top: 21px;
    margin-bottom: 21px;
    margin-right: 30px;
    cursor: pointer;
    @media (max-width: 960px) {
        margin-right: 20px;
        margin-top: 22.5px;
        margin-bottom: 22.5px;
    }
}
</style>

<style lang="scss">
.support {
    h2 {
        @media (max-width: 960px) {
            font-size: 18px !important;
        }
    }

    ul {
        margin-top: 1rem;
        font-size: 16px !important;
        @media (max-width: 960px) {
            font-size: 14px !important;
        }
    }

    p {
        margin-top: 1rem;
        font-size: 16px !important;
        @media (max-width: 960px) {
            font-size: 14px !important;
        }
    }

    a {
        color: var(--darken-primary-color) !important
    }

    .internal-link {
        cursor: pointer;
        text-decoration: underline;
    }

    .v-expansion-panels {
        .v-item--active {
            .v-expansion-panel-header {
                min-height: 48px !important;
                color: var(--darken-primary-color);

                .v-icon {
                    color: var(--darken-primary-color);
                }
            }
        }

        .v-expansion-panel-header {
            min-height: 48px !important;
            font-size: 18px !important;

            .v-icon {
                font-size: 30px;
            }

            @media (max-width: 960px) {
                font-size: 16px !important;
                .v-icon {
                    font-size: 26px;
                }
            }

        }

        :hover {
            .v-expansion-panel-header {
                color: var(--darken-primary-color);
                border-bottom: 1px solid var(--darken-primary-color);

                .v-icon {
                    color: var(--darken-primary-color);
                }
            }
        }
    }
}

.pwa {
    .support {
        background-color: transparent;
        color: #3e3e3e;
        font-size: 16px;

        .theme--light.v-expansion-panels .v-expansion-panel {
            background-color: transparent;
            color: #3e3e3e;
        }

        h1 {
            font-size: 33px;
            @media (max-width: 960px) {
                font-size: 22px !important;
            }
            @media(max-width: 380px) {
                font-size: 19px !important;
            }

            @media(max-width: 365px) {
                font-size: 17px !important;
            }
            @media(max-width: 300px) {
                font-size: 13px !important;
            }
        }

        h4 {
            @media (max-width: 960px) {
                font-size: 23px !important;
            }
        }

        ul {
            margin-top: 1rem;
            font-size: 16px !important;
            @media (max-width: 960px) {
                font-size: 14px !important;
            }
        }

        li {
            margin-top: 1rem;
            font-size: 16px !important;
            list-style-position: outside;
            @media (max-width: 960px) {
                font-size: 14px !important;
            }
        }

        li {
            margin-top: 1rem;
            font-size: 16px !important;
            @media (max-width: 960px) {
                font-size: 14px !important;
            }
        }

        p {
            margin-top: 1rem;
            font-size: 16px !important;
            @media (max-width: 960px) {
                font-size: 14px !important;
            }
        }

        a {
            color: var(--darker-primary-color) !important
        }

        .internal-link {
            cursor: pointer;
            text-decoration: underline;
        }

        .v-expansion-panels {
            .v-item--active {
                .v-expansion-panel-header {
                    min-height: 48px !important;
                    color: #3e3e3e;

                    .v-icon {
                        color: #3e3e3e;
                    }
                }
            }

            .v-expansion-panel-header {
                min-height: 48px !important;
                font-size: 18px !important;

                .v-icon {
                    font-size: 24px;
                }

                @media (max-width: 960px) {
                    font-size: 16px !important;
                    .v-icon {
                        font-size: 26px;
                    }
                }

            }

            :hover {
                .v-expansion-panel-header {
                    color: #3e3e3e;
                    border-bottom: 1px solid var(--darker-primary-color);

                    .v-icon {
                        color: #3e3e3e;
                    }
                }
            }
        }
    }
}
</style>