<template>
    <div class="input-wrapper"
         :style="textColorStyle"
    >
        <v-card 
            rounded="1"
            class="input-card"
            :style="buttonBorderRadiusStyle"
        >
            <v-card-title class="justify-center">
                {{ cardTitle }}
            </v-card-title>
            <v-divider />
            <v-card-text class="py-8">
                <div v-if="!alreadyunregistered"
                     class="d-flex align-center flex-column"
                >
                    <v-checkbox
                        v-model="agree"
                        :label="$t('registration.agreement')"
                        :color="darkenPrimaryColor"
                        class="checkbox"
                    />
                    <div class="center-button">
                        <v-btn 
                            :color="darkenPrimaryColor"
                            class="unsubscirbe-button mb-3"
                            :style="darkenPrimaryColorStyle"
                            :disabled="!agree"
                            :loading="loading"
                            @click="unregister"
                        >
                            {{ $t('registration.unregister') }}
                        </v-btn>
                    </div>
                </div>
                <div v-if="alreadyunregistered"
                     class="center-button"
                >
                    <v-btn
                        :color="darkenPrimaryColor"
                        class="unsubscirbe-button"
                        @click="toRegistrationPage"
                    >
                        {{ $t('registration.title') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { ROUTE_REGISTRATION } from '@/router/constants';

    export default {
        name: 'UnscubscribePage',
        data(){
            return {
                attendance: null,
                loading: false,
                loadingAttendance: false,
                unregistered: false,
                agree: false,
            };
        },
        computed:{
            ...mapGetters('project', [
                'darkenPrimaryColor', 
                'textColorStyle', 
                'darkenPrimaryColorStyle',
                'buttonBorderRadiusStyle',
            ]),
            ...mapState('registration', ['registrationData']),
            ...mapState('project', ['project']),
            alreadyunregistered(){
                return !this.registrationData || this.unregistered ? true : false;
            },
            cardTitle(){
                if(this.alreadyunregistered){
                    return this.$t('registration.already_unregistered');
                }
                return this.$t('registration.unregister_title');
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods:{
            ...mapActions('registration', ['unregisterFromProject']),
            async unregister(){
                this.loading = true;
                await this.unregisterFromProject();
                this.unregistered = true;   
                this.loading= false;
            },
            toRegistrationPage(){
                this.$router.push({ name: ROUTE_REGISTRATION });
            },
        },
    };
</script>

<style lang="scss" scoped>
.input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .input-card{
        width: 600px;
    }
    .center-button{
        flex: 1;
        display: flex;
        justify-content: center;
    }
}
</style>

<style lang="scss">
.template-default{
    .center-button {
        .unsubscirbe-button{
            .v-btn__content {
                color: var(--text-color) !important;
            }
        }
    }
}
</style>