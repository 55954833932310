const endPageStore = {
    namespaced: true,
    getters: {
        isChatVisible(state, getters, rootState, rootGetters) {
            return rootState['project'].project.endPage.showCrispChat
            && rootGetters['qld-vuex-store/property']('controller-toggle', 'livechat', 'visible')
            && !rootGetters['project/isMultiSession']
            && rootGetters['project/currentEvent'].crispChatEvent
            && rootGetters['project/currentEvent'].chatType === 'crisp';
        },
        isHandoutVisible(state, getters, rootState, rootGetters) {
            const handouts = rootState['project'].project.endPage.handouts;
            const hasHandouts = handouts.filter(h => !!h.url && !!h.buttonText).length > 0;
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'handouts', 'visible') && hasHandouts;
        },
        isEvaluationButtonVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'evaluation-btn', 'visible');
        },
        isCustomCtaButtonVisible(state, getters, rootState, rootGetters) {
            return rootGetters['qld-vuex-store/property']('controller-toggle', 'custom-cta', 'visible');
        }
    },
};

export default endPageStore;
