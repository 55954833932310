<template>
    <div class="field-text">
        <v-text-field
            v-model="model"
            type="text"
            class="input-text"
            outlined
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            :color="color"
            :background-color="backgroundColor"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldText',
        props: {
            value: {
                type: String,
                default: () => ''
            },            color: {
                type: String,
                default: () => ''
            },            label: {
                type: String,
                default() {
                    return this.$t('fields.enter_answer');
                },
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style lang="scss">
.field-text{
    .v-input{
        border-radius: var(--input-border-radius) !important;
    }
}
.v-text-field--outlined>.v-input__control>.v-input__slot {
    min-height: 44px !important;
    @media screen and (max-height: 960px) {
        min-height: 40px !important;
    }
}
</style>
