import { trim } from 'lodash';

const isBreakout = (evt) => !!evt.breakout_group;
const isNewBreakout = (evt, currentBreakoutGroup) => !!evt.breakout_group && evt.breakout_group !== currentBreakoutGroup?.breakout_group;

export const hidesAgendaButtons = (projectId) => {
    return [
        '6228dbdce3915e55881a6a09', // prod
        '6231c30b469fa03c4d4f0740', // prod
        '6221e557c06e572763155da3', // test
        '6231b2081b409d443564323d', // test
        '6231be9d5e481d0174477474', // test
    ].includes(projectId);
};

export const buildAgenda = (events, breakoutGroupTitle) => {
    const result = [];
    let currentBreakoutGroup = null;
    for (let index in events) {
        if (!Object.prototype.hasOwnProperty.call(events, index)) {
            continue;
        }

        const e = events[index];
        if (isBreakout(e)) {
            if (isNewBreakout(e, currentBreakoutGroup)) {
                currentBreakoutGroup = {
                    name: e.breakoutGroupName || breakoutGroupTitle,
                    startTime: e.startTime,
                    durationInMinutes: e.durationInMinutes,
                    agendaStartAndEndTime: e.agendaStartAndEndTime,
                    breakout_group: e.breakout_group,
                    breakouts: []
                };
                result.push(currentBreakoutGroup);
            }
            currentBreakoutGroup.breakouts.push(e);
        } else {
            result.push(e);
        }
    }

    return result;
};

export const timeUntilReactive = (startTime, currentTime) => {
    const dayLength = 60 * 60 * 24;
    const hourLength = 60 * 60;
    const minuteLength = 60;
    const diff = startTime - (currentTime);
    const days = Math.floor(diff / dayLength) * dayLength;
    const hours = Math.floor((diff - days) / hourLength) * hourLength;
    const minutes = Math.floor((diff - days - hours) / minuteLength) * minuteLength;
    const seconds = Math.floor(diff - days - hours - minutes);

    const dayCount = days / dayLength;
    const hourCount = hours / hourLength;
    const minuteCount = minutes / minuteLength;

    return {
        diff,
        days: dayCount,
        hours: hourCount,
        minutes: minuteCount,
        seconds
    };
};

export const timeUntilToString = (until, ds = 'd', hs = 'h', ms = 'min') => {
    const dayString = until.days > 0 ? `${until.days}${ds}` : '';
    const hoursString = until.hours > 0 ? `${until.hours}${hs}` : '';
    const minutesString = until.minutes > 0 ? `${until.minutes}${ms}` : '';
    if (until.seconds > 0 && until.minutes === 0 && until.hours === 0 && until.days === 0) {
        return `< 1${ms}`;
    }
    if (until.days > 0){
        return trim(`${dayString} ${hoursString}`).replace(/\s+/gi, ' ');
    } else {
        return trim(`${hoursString} ${minutesString}`).replace(/\s+/gi, ' ');
    }
};

export const registerForEvent = (project, currentEvents, event) => {
    if (event.breakout_group) {
        const siblingBreakoutIds = project.events.filter(breakout => {
            return breakout.breakout_group === event.breakout_group;
        }).map(siblingBreakout => {
            return siblingBreakout._id;
        });
        const currentEventsWithoutDestinationBreakoutGroup = currentEvents.filter(eventId => {
            return siblingBreakoutIds.indexOf(eventId) === -1;
        });
        currentEventsWithoutDestinationBreakoutGroup.push(event._id);
        return currentEventsWithoutDestinationBreakoutGroup;
    } else {
        return [...currentEvents, event._id];
    }
};

export const navigate = (router, name, event_id, registrationId) => {
    window.location.href = router.resolve({
        name,
        params: {
            event_id,
            registrationId
        }
    }).href;
};
