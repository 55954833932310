<template>
    <div v-if="isEnding"
         class="mt-4"
    > 
        <h6 v-if="!ended" 
            class="text-center mb-4" 
            v-html="willEndInText"
        />
        <h6 v-else 
            class="text-center mb-4" 
            v-html="willEndSoonText"
        />
        <v-progress-linear v-model="progressValue"
                           :indeterminate="ended"
                           :color="primaryColor"
                           class="will-end-soon-progress"
        />  
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { timeUntilReactive } from '@/services/AgendaService';

    export default {
        name: 'EventEndCountdown',
        data(){
            return {
                diff: null,
                progressValue: 0
            };
        },
        computed:{
            ...mapState('time', ['currentTime']),
            ...mapGetters('project', ['currentEvent', 'primaryColor']),
            endTime(){
                return this.currentEvent.startTime + this.currentEvent.durationInMinutes * 60;
            },
            isEnding(){
                return this.diff < 60;
            },
            ended(){
                return this.diff < 0;
            },
            isBreakout(){
                return this.currentEvent.breakout_group ? true : false;
            },
            willEndInText(){
                return this.isBreakout ? 
                    this.$t('multiSession.breakoutWillEndIn').replace('{{countdown}}', this.diff) : 
                    this.$t('multiSession.sessionWillEndIn').replace('{{countdown}}', this.diff);
            },
            willEndSoonText(){
                return this.isBreakout ? 
                    this.$t('multiSession.breakoutWillEndSoon').replace('{{countdown}}', this.diff) : 
                    this.$t('multiSession.sessionWillEndSoon').replace('{{countdown}}', this.diff);
            }
        },
        watch: {
            currentTime: {
                immediate: true,
                handler() {
                    const until = timeUntilReactive(this.endTime, this.currentTime);
                    this.diff = until.diff;
                    if(this.isEnding && !this.ended){
                        this.progressValue = (60 - this.diff) * 100/60;
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
.will-end-soon-progress .v-progress-linear__indeterminate--active .long{
    animation-duration: 8.2s !important;
}

.will-end-soon-progress .v-progress-linear__indeterminate--active .short{
    animation-duration: 8.2s !important;
}

</style>