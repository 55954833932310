<template>
    <div class="field-textarea">
        <v-textarea
            v-model="model"
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            :color="color"
            outlined
            :background-color="backgroundColor"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldTextarea',
        props: {
            value: {
                type: String,
                default: () => ''
            },            
            color: {
                type: String,
                default: () => ''
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style lang="scss">
.field-textarea{
    .v-textarea{
        border-radius: var(--input-border-radius) !important;
    }
}
</style>
