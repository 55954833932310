<template>
    <div>
        <span>Redirecting...</span>
    </div>
</template>

<script>
    import { ROUTE_EVENT_WITH_PERSONAL_ID } from '@/router/constants';
    import { mapState, mapGetters } from 'vuex';
    import { furtherEvents } from '@/services/FurtherEvents';
    import { minBy } from 'lodash';

    export default {
        computed: {
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId']),
            ...mapState('time', ['currentTime']),
            ...mapGetters('project', ['currentEvent']),
            ...mapGetters('registration', ['registeredEvents']),
            furtherEvents() {
                return furtherEvents(this.project.events, {}, this.currentTime, this.registeredEvents);
            },
        },
        created() {
            const nextEvent = minBy(this.furtherEvents, 'startTime');
            this.$router.push({
                name: ROUTE_EVENT_WITH_PERSONAL_ID,
                query: this.$route.query,
                hash: this.$route.hash,
                params: {
                    event_id: nextEvent ? nextEvent._id : this.currentEvent._id,
                    registration_id: this.registrationId,
                },
            });
        },
    };
</script>
