<template>
    <v-row justify="center"
           class="ma-0"
    >
        <v-col cols="12"
               class="evaluation-col"
               lg="7"
               md="7"
        >
            <v-card class="rounded-0">
                <control-holder>
                    <v-form v-if="hasEvaluation"
                            ref="form"
                            class="evaluation-form"
                            @submit.prevent="submit"
                    >
                        <v-row
                            v-for="(field,i) in fields"
                            :key="field.id"
                            align="center"
                            justify="center"
                        >
                            <v-col cols="12"
                                   lg="12"
                            >
                                <h6 class="question-text">
                                    {{ `${i+1}. ${field.question}` }}
                                    <span v-if="field.required"
                                          :style="{color: 'red !important'}"
                                    >*</span>
                                </h6>
                                <div class="input-element-wrapper">
                                    <component
                                        :is="getFieldComponent(field.type)"
                                        :name="`evaluation-${field.id}`"
                                        :options="field.options"
                                        :required="field.required"
                                        :color="darkenPrimaryColor"
                                        @input="(value) => updateAnswers(field.id, field.type, value)"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12"
                                   lg="12"
                            >
                                <v-btn
                                    v-if="!evaluationUploading"
                                    type="submit"
                                    :color="darkenPrimaryColor"
                                    class="evaluation-submit"
                                    :style="{...buttonShadowColorStyle, ...textColorStyle}"
                                >
                                    {{ submitButtonText }}
                                </v-btn>
                                <div v-else
                                     class="d-flex"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        :color="darkenPrimaryColor"
                                    />
                                    <h5 class="ml-3 pt-1 grey--text text--darken-3">
                                        {{ $t('evaluation.processing') }}
                                    </h5>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </control-holder>
            </v-card>
            <default-footer class="end-footer mt-n2" />
        </v-col>
        <v-dialog
            v-model="showFeedback"
            max-width="800"
        >
            <v-card class="after-submit-wrapper pa-3 text-center"
                    :style="fontFamilyStyle"
            >
                <v-btn
                    v-if="!fromPwa"
                    icon
                    class="close-button"
                    @click="showFeedback = false"
                >
                    <v-icon color="grey">
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3 class="after-submit-message text-center">
                    {{ $t('evaluation.thanks') }}
                </h3>
                <h3 v-if="!fromPwa"
                    class="after-submit-message text-center"
                >
                    {{ $t('evaluation.you_can_leave') }}
                </h3>
                <h3 v-if="fromPwa"
                    class="after-submit-message text-center"
                >
                    {{ $t('evaluation.you_can_leave_pwa') }}
                </h3>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import ControlHolder from '@/components/templates/default/ControlHolder';
    import FieldText from '@/components/controls/fields/FieldText';
    import FieldTextarea from '@/components/controls/fields/FieldTextarea';
    import FieldRadio from '@/components/controls/fields/FieldRadio';
    import FieldCheckbox from '@/components/controls/fields/FieldCheckbox';
    import FieldDropdown from '@/components/controls/fields/FieldDropdown';
    import FieldStarRating from '@/components/controls/fields/FieldStarRating';
    import FieldScale from '@/components/controls/fields/FieldScale';
    import FieldDropdownOpenAnswer from '@/components/controls/fields/FieldDropdownOpenAnswer';
    import { $http } from '@/services/http';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import FakeRegistrationIDService from '@/services/FakeRegistrationIDService';
    import {ROUTE_PERSONAL_LINK} from '@/router/constants';

    const getQuestionAnswer = (questionId, type, value) => {
        const questionAnswer = {
            questionId: questionId,
        };
        switch (type) {
        default:
        case 'text':
            questionAnswer.answerText = value;
            break;
        case 'textarea':
            questionAnswer.answerText = value;
            break;
        case 'radio':
            questionAnswer.answerObjectId = value;
            break;
        case 'dropdown':
            questionAnswer.answerObjectId = value;
            break;
        case 'checkbox':
            questionAnswer.answerArray = value;
            break;
        case 'star-rating':
            questionAnswer.answerInt = value;
            break;
        case 'scale':
            questionAnswer.answerInt = value;
            break;
        case 'dropdown-open-answer':
            questionAnswer.answerObjectId = value.id;
            questionAnswer.answerText = value.openAnswer;
        }

        return questionAnswer;
    };

    export default {
        name: 'EvaluationPage',
        data() {
            return {
                answers: [],
                evaluationUploading: false,
                showFeedback: false,
                answersSubmitted: false
            };
        },
        components: {
            DefaultFooter,
            ControlHolder,
            FieldText,
            FieldTextarea,
            FieldRadio,
            FieldCheckbox,
            FieldDropdown,
            FieldStarRating,
            FieldDropdownOpenAnswer,
            FieldScale,
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'registrationType',
                'isInPreviewMode',
                'darkenPrimaryColor',
                'buttonShadowColorStyle',
                'textColorStyle',
                'fontFamilyStyle'
            ]),
            ...mapGetters('registration', ['registrationId']),
            ...mapState('project', ['project']),
            fields() {
                if(this.fromPwa){
                    return this.project?.onSiteEvaluation?.fields ?? [];
                }
                return this.project?.evaluation?.fields ?? [];
            },
            evaluationId() {
                if(this.fromPwa){
                    return this.project?.onSiteEvaluation?._id ?? '';
                }
                return this.project?.evaluation?._id ?? '';
            },
            submitButtonText(){
                return this.answersSubmitted ? this.$t('evaluation.resubmit') : this.$t('evaluation.submit');
            },
            fromPwa() {
                return this.$route.query.pwa;
            },
            hasEvaluation() {
                if (!this.evaluationId) {
                    return false;
                }

                return this.fields.length > 0;
            }
        },
        methods: {
            ...mapActions('project', ['registerAction']),
            evaluationRegistrationID() {
                if (!FakeRegistrationIDService.looksToBeValidRegistrationID(this.registrationId)) {
                    return FakeRegistrationIDService.uid();
                }
                return this.registrationId;
            },
            getFieldComponent(type) {
                return `field-${type}`;
            },
            updateAnswers(questionId, type, value) {
                const questionAnswer = getQuestionAnswer(questionId, type, value);
                const answers = this.answers.filter(
                    (answer) => answer.questionId !== questionAnswer.questionId
                );
                answers.push(questionAnswer);
                this.answers = answers;
            },
            submit() {
                if (this.$refs.form.validate()) {
                    if(this.isInPreviewMode){
                        return this.submitForPreview();
                    }
                    const answers = {
                        evaluation_id: this.evaluationId,
                        registration_id: this.evaluationRegistrationID(),
                        answers: this.answers,
                    };
                    this.evaluationUploading = true;
                    $http
                        .post(process.env.VUE_APP_EVALUATION_ENDPOINT, answers)
                        .then(() => {
                            this.evaluationUploading = false;
                            this.answersSubmitted = true;
                            this.showFeedback = true;
                            setTimeout(() => {
                                if(this.fromPwa){
                                    this.$router.push(
                                        {
                                            name: ROUTE_PERSONAL_LINK,
                                            params: {
                                                event_id: this.currentEvent._id,
                                                registration_id: this.registrationId
                                            }
                                        }
                                    );
                                }
                            }, 5000);
                        })
                        .catch(() => {
                            this.evaluationUploading = false;
                        });

                }
            },
            submitForPreview(){
                this.evaluationUploading = true;
                setTimeout(() => {
                    this.evaluationUploading = false;
                    this.showFeedback = true;
                    this.answersSubmitted = true;
                }, 300);
            },
        },
        mounted() {
            this.registerAction('evaluation-page-view');
        }
    };
</script>

<style scoped lang="scss">

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #168516c9;
}

.after-submit-wrapper{
    font-family: var(--font-family);
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .close-button{
      position: absolute;
      top: 10px;
      right: 10px;
    }
}

.after-submit-message{
    color: #3E3E3E !important;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 120%;
    @media (max-width: 650px) {
        font-size: 20px;
    }
}

.evaluation-form{
    margin: 30px;
    @media (max-width: 960px) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.input-element-wrapper{
    padding-right: 19px;
    padding-left: 19px;
}

.evaluation-col{
    padding-top: 79px;
    @media (max-width: 960px) {
        padding-top: 66px;
        padding-bottom: 61px;
    }
}

.question-text{
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 120%;
    margin-bottom: 16px;
}

@media screen and (max-width: 1264px) and (min-width: 961px)  {
    .question-text{
        font-size: 17px !important;
    }
}

@media screen and (max-width: 960px) {
    .question-text{
        font-size: 16px !important;
    }
}

.evaluation-row {
    justify-content: center;
    align-items: center;
}

.evaluation-submit{
    height: 44px !important;
    min-width: 100px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    padding: 10px 32px;
    box-shadow: 0 8px 16px var(--button-shadow-color);
    color: var(--text-color)
}
</style>
