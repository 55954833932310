import { render, staticRenderFns } from "./LiveDirectorQuestion.vue?vue&type=template&id=b49ea342&"
import script from "./LiveDirectorQuestion.vue?vue&type=script&lang=js&"
export * from "./LiveDirectorQuestion.vue?vue&type=script&lang=js&"
import style0 from "./LiveDirectorQuestion.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports