<template>
    <div class="program-element"
         :class="{'mobile': isMobile, 'xl-screen': isExtraLargeScreen, 'gradient': gradient}"
         :style="styles"
    >
        <div class="upcoming-content align-center justify-center mb-5"
             v-html="content"
        />

        <upcoming-events-list :events="options.upcomingEvents" />
    </div>
</template>

<script>
    import UpcomingEventsList from '@/components/controls/UpcomingEventsList.vue';
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'UpcomingEvents',
        components: {UpcomingEventsList},
        props: {
            options: {
                type: Object,
                default: () => {},
            },
            gradient: {
                type: Boolean,
                default: () => false,
            },
        },
        computed:{
            ...mapState('project', ['project']),
            ...mapGetters('project', ['primaryColorRGB', 'getContent']),
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                    'flex-direction': 'column',
                };
            },
            content() {
                if(this.options.content){
                    return this.getContent(this.options.content);
                }
                return '';
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            isExtraLargeScreen(){
                return this.$vuetify.breakpoint.xlOnly;
            }
        }

    };
</script>

<style lang="scss">
.template-default:not(.pwa) {
    .upcoming-content,
    .upcoming-content > * {
        color: var(--block-text-color) !important;
    }
}
</style>