<template>
    <div class="field-scale">
        <v-slider
            v-model="model"
            :max="10"
            :thumb-size="24"
            thumb-label="always"
            class="mt-8"
            :required="required"
            :rules="required ? [(v) => v > 0 || $t('fields.validation.required')] : []"
            :color="color"
        />
    </div>
</template>

<script>
    export default {
        name: 'FieldScale',
        props: {
            name: {
                type: String,
                default: () => '',
            },
            color: {
                type: String,
                default: () => '',
            },
            value: {
                type: Number,
                default: () => 0,
            },
            required: {
                type: Boolean,
                default: () => false,
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style scoped></style>
