<template>
    <div>
        <hr class="speaker-divider">
        <h3 class="speaker-title mb-10">
            {{ $t('registration.speakers') }}
        </h3>
        <v-row justify="center">
            <v-col v-for="speaker in speakers"
                   :key="`speaker-${speaker.id}`"
                   cols="12"
                   lg="4"
                   md="6"
                   class="text-center px-10"
                   :class="{'disable-link' : disableLink(speaker.link)}"
            >
                <a :href="speaker.link"
                   target="_blank"
                   class="speaker-link"
                >
                    <v-avatar size="150"
                              class="outlined"
                    >
                        <img v-if="speaker.avatar.url"
                             :src="speaker.avatar.url"
                             :alt="speaker.name"
                        >
                        <v-icon v-else>person</v-icon>
                    </v-avatar>
                </a>

                <h3 class="mt-3 mb-2">
                    <a :href="speaker.link"
                       target="_blank"
                       class="speaker-link"
                    >
                        <span>
                            {{ speaker.name }}
                        </span>
                    </a>
                </h3>

                <h6 class="mb-2">
                    {{ speaker.title }}
                </h6>
                <p class="summary">
                    {{ speaker.description }}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'Speakers',
        computed: {
            ...mapGetters('project', ['speakers', 'isInPreviewMode']),
        },
        methods: {
            disableLink(link) {
                return link === '' || this.isInPreviewMode ? true : false;
            }
        }
    };
</script>

<style lang="scss" scoped>

.disable-link {
    a{
        pointer-events: none;
    }
}
.speaker-divider {
    border-top: 1px solid #ffffff;
    margin-top: 100px;
    margin-bottom: 30px;
    opacity: 0.4
}

a {
    color: white !important;

    h3 {
        font-size: 22px !important
    }
}

h6 {
    color: white !important;
}

p {
    color: white !important;
    font-size: 15px !important;
}

.speaker-title {
    color: white;
    font-size: 25px !important;
}

.speaker {
    max-width: 200px !important;
}

.summary {
    line-height: 110% !important;
}

.outlined {
    border: 2px solid white;
    border-radius: 50%;
}

.speaker-link {
    text-decoration: none;

    .v-icon {
        color: #fff;
        font-size: 6rem;
    }

    &:hover {
        span {
            text-decoration: underline;
        }
    }
}

</style>
