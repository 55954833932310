import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    data() {
        return {
            navigateToElearningCourseButtonHrefProcessed: '',
        };
    },
    computed: {
        ...mapState('registration', ['registrationId']),
        ...mapGetters('project', [
            'currentEvent',
        ]),
    },
    methods: {
        ...mapActions('registration', ['fetchElearningThirdPartyHash']),
        getPathFromUrl(url) {
            return url.split(/[?#]/)[0];
        },
        async searchForElearningThirdPartyConnectionParams() {
            this.navigateToElearningCourseButtonHrefProcessed = this.navigateToElearningCourseButtonHref;

            if (!this.project.elearningButton.href) {
                return;
            }

            let thirdPartyURL = this.project.elearningButton.href;
            if (window.localStorage.getItem('SpringerLoginFailed')) {
                thirdPartyURL = this.project.elearningButton.alternateHref;
            }

            let url = new URL(thirdPartyURL);
            let urlParams = new URLSearchParams(url.search);
            let form = {
                'courseSlug': urlParams.get('courseSlug'),
                'company': urlParams.get('company'),
                'event_id': this.currentEvent._id,
                'registration_id': this.registrationId
            };

            if (!form['courseSlug'] || !form['company']) {
                return;
            }

            this.navigateToElearningCourseButtonHrefProcessed = 'javascript:void(0)';
            const data = await this.fetchElearningThirdPartyHash(form);
            this.navigateToElearningCourseButtonHrefProcessed = this.getPathFromUrl(thirdPartyURL) + '?' + data;
        }
    },
};