import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import dateTimeFormats from '@/locale/datetime';
import en from '@/locale/en';
import nlFormal from '@/locale/nlFormal';
import nlInformal from '@/locale/nlInformal';
import de from '@/locale/de';

export const i18n = new VueI18n({
    locale: 'nlFormal',
    dateTimeFormats,
    messages: {
        nlFormal,
        nlInformal,
        en,
        de,
    }
});
