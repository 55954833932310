<template>
    <div class="main"
         :class="{'gradient': backgroundGradient,
                  'iframed': isInIframe,
         }"
         :style="styles"
    >
        <slot /> 
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {isInIframe} from '@/services/Integration';

    export default {
        name: 'DefaultBody',
        computed: {
            ...mapGetters('project', ['backgroundImage','primaryColor', 'backgroundGradient', 'primaryColorRGB', 'darkenPrimaryColorStyle', 'textColorStyle', 'isInPreviewMode']),
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            styles() {
                if (this.isInIframe) {
                    return {
                        '--project-primary-color': this.primaryColor,
                    };
                }
                return {
                    'background-image': `url(${this.backgroundImage})`,
                    '--project-primary-color': this.isRegisteredForPwa
                        ? this.project.pwaStyle.primaryColor
                        : this.primaryColor,
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            isInIframe,
        },
    };
</script>

<style scoped lang="scss">
.main{
    &:before{
        background-color: rgba(var(--r), var(--g), var(--b), 0.4);
    }
}
.gradient{
    &:before{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),linear-gradient(314.24deg, rgba(0, 0, 0, 0.5) 22.26%, rgba(var(--r), var(--g), var(--b), 0.5) 65.06%);
        transform: rotate(180deg);
    }
}
</style>