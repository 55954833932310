<template>
    <v-snackbar v-model="show"
                :color="color"
                :timeout="timeout"
                :style="styles"
                :rounded="roundedness"
    >
        <div class="d-flex flex-row">
            <div class="message-content flex-grow-1 flex-shrink-1 pa-2">
                {{ message }}
            </div>
            <div class="message-actions flex-grow-0 flex-shrink-0">
                <v-btn text
                       icon
                       @click="show = false"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </div>
        </div>
    </v-snackbar>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'Snackbar',
        data() {
            return {
                show: false,
                message: '',
                color: '',
                timeout: -1,
                roundedValues:{
                    '0' : '0',
                    '4' : 'md',
                    '20' : 'xl'
                }
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            styles() {
                if (this.isRegisteredForPwa) {
                    return {
                        'bottom': '70px',
                    };
                }

                return {};
            },
            roundedness(){
                return this.roundedValues[this.project.style.cardBorderRadius];
            }
        },
        created() {
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'snackbar/showMessage') {
                    this.message = state.snackbar.content;
                    this.color = state.snackbar.type;
                    this.timeout = state.snackbar.timeout || -1;
                    this.show = true;
                }

                if (mutation.type === 'snackbar/hideMessage') {
                    this.show = false;
                }
            });
        }
    };
</script>