<template>
    <a v-if="hasPrivacyPolicy"
       :href="hasPrivacyPolicy"
       target="_blank"
    >{{ $t('privacyPolicy.title') }}</a>
    <router-link v-else
                 :to="routeWithPrivacyPolicy"
    >
        {{ $t('privacyPolicy.title') }}
    </router-link>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'PrivacyPolicyLink',
        computed: {
            ...mapState('project', ['project']),
            hasPrivacyPolicy() {
                return this.project.privacyPolicyUrl;
            },
            routeWithPrivacyPolicy() {
                return {
                    name: this.$route.name,
                    params: this.$route.params,
                    query: this.$route.query,
                    hash: '#privacy-policy',
                };
            },
        }
    };
</script>
