<template>
    <div class="handout-buttons"
         :class="{mobile: isMobile}"
    >
        <span v-for="(handout, index) in internalValue"
              :key="index"
        >
            <v-btn
                v-if="handout.buttonText && handout.url"
                :href="handout.url"
                class="handout-button px-4"
                :class="{ mobile: isMobile, 'ml-7': !isMobile && index === 1}"
                @click.stop.prevent="startConfirmation(handout)"
            >
                <span class="handout-button-content">
                    {{ handout.buttonText }}
                </span>
            </v-btn>
        </span>
        <handout-download-confirmation
            v-model="showModal"
            @download="downloadHandout"
        />
    </div>
</template>

<script>
    import HandoutDownloadConfirmation from '@/components/templates/default/EventPage/HandoutDownloadConfirmation';
    import {mapGetters} from 'vuex';

    export default {
        name: 'HandoutButton',
        components: {HandoutDownloadConfirmation},
        props: {
            value: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                internalValue: this.value.filter(v => v.url && v.buttonText),
                fileToDownload: null,
                showModal: false
            };
        },
        computed: {
            ...mapGetters('registration', ['isRegisteredForPwa']),
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown;
            },
        },
        methods: {
            startConfirmation(handout){
                this.fileToDownload = handout;

                if (this.isRegisteredForPwa) {
                    this.downloadHandout();
                    return;
                }

                this.showModal = true;
                return false;
            },
            downloadHandout() {
                const link = document.createElement('a');
                link.href = this.fileToDownload.url;
                link.target = '_blank';
                link.click();
                URL.revokeObjectURL(link.href);
            },
        },
    };
</script>

<style scoped lang="scss">
.handout-buttons{
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.pwa {
    .handout-buttons {
        gap: initial;
        padding-bottom: 0;
    }
}

.handout-button {
    cursor: pointer;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    background: var(--darken-primary-color) !important;
    box-shadow: 0 8px 16px var(--button-shadow-color);
    color: var(--text-color);
    border-radius: var(--button-border-radius);
    margin: 0 auto;

    .handout-button-content {
        color: var(--text-color);
    }
}

.handout-button:hover {
    opacity: 0.9;
}

.handout-button.mobile:hover {
    opacity: 0.7;
}

.end-page-handouts{

    &.handout-buttons{
        flex-direction: row;
        justify-content: center;
        gap: 0;

        .handout-button{
            background: #fff !important;
            box-shadow: 0 8px 16px var(--button-shadow-color);
            height: 46px !important;
            .handout-button-content {
                color: #3e3e3e !important;
            }

            &.mobile {
                    align-self: flex-start;
                    background: #fff;
                    box-shadow: 0 8px 16px var(--button-shadow-color);
                    padding: 10.5px 15px;


                    .handout-button-content {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 150%;
                        color: #3e3e3e !important;
                    }
                }
        }
    }

    &.mobile {
        flex-direction: column;
        gap: 36px;
    }

    .handout-button.mobile {
        align-self: flex-start;
        background: var(--darken-primary-color);
        box-shadow: 0 8px 16px var(--button-shadow-color);
        height: 36px !important;

        .handout-button-content {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            color: var(--text-color) !important;
        }
    }
}

.end-page-side-bar {
    .handout-buttons.mobile {
        gap: 20px;
        span {
            text-align: center;
            a.handout-button {
                background-color: var(--darken-primary-color) !important;
                .handout-button-content {
                    color: var(--button-text-color) !important;
                }
            }
        }
    }
}
</style>
