export const getInitialFieldValues = (field) => {
    switch (field.type) {
        default:
            return '';
        case 'checkbox':
            return [];
        case 'on-site-viewer':
            return 0;
    }

};