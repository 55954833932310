<template>
    <div class="fullHeight mx-0 d-flex flex-column">
        <h2 class="event-title">
            Please wait
            <v-progress-circular model-value="20"
                                 class="progress-bar"
                                 indeterminate
            />
        </h2>
        <span v-if="accessTokenFail"
              class="clickable"
              @click="retry"
        >
            Requesting accessToken failed. Click here to retry.
        </span>
        <span v-if="profileFail"
              class="clickable"
              @click="retry"
        >
            Requesting profile failed. Click here to retry.
        </span>
        <span v-if="registrationFail"
              class="clickable"
              @click="retry"
        >
            Registration failed. Click here to retry.
        </span>
    </div>
</template>

<script>
    import SpringerCasService from '@/services/SpringerCasService';
    import { ROUTE_EVENT_WITH_PERSONAL_ID, ROUTE_REGISTRATION } from '@/router/constants';
    import { $http } from '@/services/http.js';
    import {mapGetters, mapState} from 'vuex';

    export default {
        data() {
            return {
                accessTokenFail: false,
                profileFail: false,
                registrationFail: false,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', ['springerOAuthClientId']),
        },
        methods: {
            async register(springer, profileData) {
                let form = {
                    event_id: this.$route.params.event_id,
                    cas_id: profileData.id,
                };

                profileData.attributes.forEach((item) => {
                    let key = Object.keys(item)[0];
                    if (
                        ['firstname', 'email', 'lastname', 'big_number'].indexOf(key) >= 0
                    ) {
                        form[this.mapFieldToRegistrationApi(key)] = item[key];
                    }
                });

                if (this.project.projectType === 'hybrid') {
                    const pwaEventId = window.localStorage.getItem('pwa');
                    form['on_site_viewer'] = pwaEventId === this.$route.params.event_id;
                }

                if (springer.isSpringerInternationalSmedcom()) {
                    this.$router.push({
                        name: ROUTE_REGISTRATION,
                        query: { smd: btoa(JSON.stringify(form)) },
                        params: {
                            event_id: this.$route.params.event_id,
                        },
                    });
                    return;
                }

                const registrationResponse = await $http.post(
                    process.env.VUE_APP_SPRINGER_REGISTRATION_ENDPOINT,
                    form
                );

                this.$router.push({
                    name: ROUTE_EVENT_WITH_PERSONAL_ID,
                    params: {
                        event_id: this.$route.params.event_id,
                        registration_id: registrationResponse.data.registrationID,
                    },
                });
            },
            mapFieldToRegistrationApi(field) {
                let mapping = {
                    firstname: 'first_name',
                    lastname: 'last_name',
                };
                if (mapping[field]) {
                    return mapping[field];
                }
                return field;
            },
            async retry() {
                let springer = new SpringerCasService(this.$route.params.event_id, this.springerOAuthClientId);
                springer.redirectToAuthorizeAndSaveState();
            },
            async redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer) {
                if (springer.isSpringerInternationalSmedcom()) {
                    return;
                }
                if (!this.project.elearningButton.alternateHref) {
                    return;
                }
                window.localStorage.setItem('SpringerLoginFailed', '1');
                this.$router.push({ name: ROUTE_REGISTRATION, params: { event_id: this.$route.params.event_id } });
            }
        },
        async mounted() {
            let springer = new SpringerCasService(this.$route.params.event_id, this.springerOAuthClientId);
            
            if (!this.$route.query.code || !this.$route.query.state) {
                this.redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer);
                alert('failed to log in.');
                return;
            }

            if (this.$route.query.state !== window.localStorage.getItem('state')) {
                this.redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer);
                alert('failed to log in!');
                return;
            }
            
            try {
                var tokenResponse = await springer.askForToken(this.$route.query.code);
            } catch (e) {
                this.accessTokenFail = true;
                this.redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer);
                return;
            }
            
            const token = tokenResponse.data.replace('access_token=', '');

            try {
                var profileResponse = await springer.fetchUserProfile(token);
            } catch (e) {
                this.profileFail = true;
                this.redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer);
                return;
            }

            try {
                await this.register(springer, profileResponse.data);
                window.localStorage.removeItem('pwa');
            } catch (e) {
                this.registrationFail = true;
                this.redirectToRegistrationSoAlternateElearningURLCanBeUsed(springer);
            }
        },
    };
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer !important;
}
.fullHeight {
  display: flex !important;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.progress-bar {
  top: -5px;
  margin-left: 5px;
}
</style>
