<template>
    <div class="field-radio">
        <v-radio-group
            v-model="model"
            :rules="required ? [(v) => !!v || $t('fields.validation.required')] : []"
            row
        >
            <v-radio
                v-for="option in options"
                :key="`option-${name}-${option.id}`"
                :name="`radio-${name}`"
                :label="option.text"
                :value="valueTextOrId ? option.text : option.id"
                :color="color"
                class="my-2"
                :background-color="backgroundColor"
            />
        </v-radio-group>
    </div>
</template>

<script>
    export default {
        name: 'FieldRadio',
        props: {
            name: {
                type: String,
                default: () => '',
            }, 
            value: {
                type: String,
                default: () => '',
            },   
            color: {
                type: String,
                default: () => '',
            },
            options: {
                type: Array,
                default: () => [],
            },
            required: {
                type: Boolean,
                default: () => false,
            },
            backgroundColor: {
                type: String,
                default: () => ''
            },
        },
        data() {
            return {
                model: this.value,
            };
        },
        computed:{
            isRegistrationPage(){
                return this.$route.name === 'Registration' || this.$route.name === 'RegistrationConfirmation';
            },
            valueTextOrId(){
                return this.isRegistrationPage && 
                    (this.name.includes('company') || this.name.includes('title')); 
            }
        },
        watch: {
            model(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style lang="scss">
.field-radio{
    .v-input--radio-group__input {
        display: block !important;
    }
}
</style>
