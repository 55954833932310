import router from '@/router';
import {
    ROUTE_PERSONAL_LINK,
    ROUTE_REGISTRATION,
    ROUTE_TEST_PAGE,
    ROUTE_EVALUATION,
    ROUTE_EVENT_WITH_PERSONAL_ID,
    ROUTE_UNREGISTER,
    ROUTE_CHANGE_ATTENDANCE, ROUTE_ONDEMAND_PAGE
} from '@/router/constants';

export const tagSubtitution = (text, currentEvent, registrationId, eventTitle, privacyPolicyUrl, onDemandEvents) => {
    let content = text;

    const personalUrl = router.resolve({
        name: ROUTE_PERSONAL_LINK,
        params: {
            event_id: currentEvent._id,
            registration_id: registrationId
        }
    }).href;
    const registrationPage = router.resolve({
        name: ROUTE_REGISTRATION,
        params: {
            event_id: currentEvent._id
        }
    }).href;
    const testPage = router.resolve({
        name: ROUTE_TEST_PAGE,
        params: {
            event_id: currentEvent._id
        }
    }).href;
    const evaluationPage = router.resolve({
        name: ROUTE_EVALUATION,
        params: {
            event_id: currentEvent._id,
            registration_id: registrationId
        }
    }).href;
    const unregisterPage = router.resolve({
        name: ROUTE_UNREGISTER,
        params:{
            event_id: currentEvent._id,
            registration_id: registrationId
        }
    }).href;
    const changeAttendancePage = router.resolve({
        name: ROUTE_CHANGE_ATTENDANCE,
        params:{
            event_id: currentEvent._id,
            registration_id: registrationId
        }
    }).href;

    content = content.replace(/{{registration}}/g, registrationPage)
        .replace(/{{test-page}}/g, testPage)
        .replace(/{{evaluation}}/g, evaluationPage)
        .replace(/{{unregister-link}}/g, unregisterPage)
        .replace(/{{change-attendance}}/g, changeAttendancePage)
        .replace(/{{privacy-policy}}/g, privacyPolicyUrl)
        .replace(/{{support}}/g, '#support')
        .replace(/{{event-name}}/g, eventTitle)
        .replace(/{{event-start-time-and-date}}/g, currentEvent.startDateTimeFormattedFull)
        .replace(/{{event-start-time}}/g, currentEvent.startTimeFormattedTime)
        .replace(/{{event-start-date}}/g, currentEvent.startTimeFormattedDate)
        .replace(/{{event-page}}/g, personalUrl)
        .replace(/{{event-page\|([0-9a-z]+)}}/g, (match, atom1) => {
            let resolvedRoute = router.resolve({
                name: ROUTE_EVENT_WITH_PERSONAL_ID,
                params: {
                    event_id: atom1,
                    registration_id: registrationId
                }
            });

            const onDemands = typeof onDemandEvents !== 'undefined' ? onDemandEvents : [];

            if (onDemands.includes(currentEvent._id) && !registrationId) {
                resolvedRoute = router.resolve({
                    name: ROUTE_ONDEMAND_PAGE,
                    params: {
                        event_id: atom1
                    }
                });
            }

            return resolvedRoute.href;
        });

    return content;
};