<template>
    <v-dialog
        :value="open"
        max-width="700"
        scrollable
        @click:outside.stop="close"
    >
        <v-card class="dialog-card rounded-0 support-card"
                :style="supportDialogStyle"
        >
            <v-card-title class="support-card-title">
                <h1 class="font-weight-regular mt-2 mb-2">
                    {{ $t('supportModal.title') }}
                </h1>
                <v-spacer />
                <v-btn
                    class=" close-button"
                    icon
                    @click.stop="close"
                >
                    <v-icon color="grey right">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-row class="mx-0">
                    <v-col cols="12"
                           class="pa-0"
                    >
                        <support />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Support from '@/components/controls/Support.vue';

    export default {
        name: 'SupportModal',
        components: {Support},
        props: {
            open: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            ...mapGetters(
                'project', 
                [
                    'primaryColor', 
                    'darkenPrimaryColor', 
                    'pwaDarkenPrimaryColor', 
                    'pwaDarkenPrimaryColorStyle', 
                    'darkenPrimaryColorStyle', 
                    'fontFamilyStyle'
                ]),
            ...mapGetters('registration', ['isRegisteredForPwa']),
            hashLessRoute() {
                return {
                    name: this.$route.name,
                    params: this.$route.params,
                    meta: this.$route.meta,
                    query: this.$route.query
                };
            },
            supportDialogStyle() {
                const darkened = this.isRegisteredForPwa ? this.pwaDarkenPrimaryColorStyle : this.darkenPrimaryColorStyle;
                return {...darkened, ...this.fontFamilyStyle};
            }
        },
        methods: {
            close() {
                this.$router.push(this.hashLessRoute);
            },
        }
    };
</script>

<style scoped lang="scss">
.support-link {
    color: #3E3E3E;
    margin-top: 21px;
    margin-bottom: 21px;
    margin-right: 30px;
    cursor: pointer;
    @media (max-width: 960px) {
        margin-right: 20px;
        margin-top: 22.5px;
        margin-bottom: 22.5px;
    }
}


</style>

<style lang="scss">
.v-dialog.v-dialog--active {
    scroll-behavior: smooth;
}

.dialog-card {

    ::-webkit-scrollbar-thumb {
        background: #201f1f4b;
    }

    font-family: var(--font-family);
    color: #3E3E3E !important;

    li {
        font-family: var(--font-family)
    }

    .support-card-title {
        position: relative;

        .close-button {
            position: absolute;
            top: 2px;
            right: 8px;
        }
    }

    h1 {
        font-size: 33px;
        @media (max-width: 960px) {
            font-size: 22px !important;
        }
        @media(max-width: 380px) {
            font-size: 19px !important;
        }

        @media(max-width: 365px) {
            font-size: 17px !important;
        }
        @media(max-width: 300px) {
            font-size: 13px !important;
        }
    }
}
.pwa {
    .support {
        h4{
            @media (max-width: 960px) {
                font-size: 23px !important;
            }
        }
    }
}
</style>
