export const isInIframe = () => {
    // without this, isInIframe breaks previews for all projects
    if (window.location.search.indexOf('previewMode') >= 0) {
        return false;
    }

    try {
        return window.top !== window.self;
    } catch (e) {
        return true;
    }
};
