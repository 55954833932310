import vuetify from '@/plugins/vuetify';
import { i18n } from '@/plugins/i18n';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './_scss/main.scss';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
// import VueGtag from "vue-gtag";
import './registerServiceWorker';
import customScripts from '@/plugins/customScripts';

Vue.use(customScripts);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = true;

var VueTouch = require('vue-touch');
Vue.use(VueTouch, {name: 'v-touch'});

if (!store.getters['project/isInPreviewMode']) {
  // Vue.use(VueGtag, {
  //   config: { id: "G-6F92V9W3N2" }
  // });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted() {
    window.WS_NODE_API_URL = process.env.VUE_APP_LIVEDIRECTOR;
  },
}).$mount('#app');
