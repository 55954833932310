export const ROUTE_REGISTRATION = 'Registration';
export const ROUTE_REGISTRATION_CONFIRMATION = 'RegistrationConfirmation';
export const ROUTE_EVENT_WITH_PERSONAL_ID = 'EventPageWithPersonalId';
export const ROUTE_EVALUATION = 'Evaluation';
export const ROUTE_REGISTRATION_COMPANY = 'RegistrationCompany';
export const ROUTE_TEST_PAGE = 'TestPage';
export const ROUTE_ONDEMAND_PAGE = 'Ondemand';
export const ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE = 'OndemandWithRegistration';
export const ROUTE_CAS_CALLBACK = 'CasCallback';
export const ROUTE_PERSONAL_LINK = 'PersonalLink';
export const ROUTE_DYNAMIC_EVENT_PAGE = 'DynamicEventPage';
export const ROUTE_UNREGISTER= 'UnregisterPage';
export const ROUTE_CHANGE_ATTENDANCE = 'ChangeAttendancePage';
export const ROUTE_PARTICIPANTS = 'ParticipantsPage';

