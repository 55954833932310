<template>
    <v-sheet class="login-page py-8"
             color="white"
             :style="style"
    >
        <h1 class="page-title text-center">
            {{ $t('loginPage.welcome') }}
        </h1>
        <v-divider class="my-4" />
        <h3 class="text-center"
            style="color: #3e3e3e"
        >
            {{ project.eventTitle }}
        </h3>
        <start-time class="text-center d-block" />
        <div class="text-center">
            {{ address }}
        </div>
        <v-divider class="my-4" />
        <login-form />
        <v-divider class="my-4" />
        <registration-link />
    </v-sheet>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import StartTime from '@/components/controls/StartTime';
    import LoginForm from '@/components/pages/LoginPage/LoginForm';
    import RegistrationLink from '@/components/pages/LoginPage/RegistrationLink';
    import {ROUTE_EVENT_WITH_PERSONAL_ID} from '@/router/constants';

    export default {
        name: 'LoginPage',
        components: {RegistrationLink, StartTime, LoginForm},
        computed: {
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'textColorOnDarkenPrimaryColor',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'primaryColorRGB',
                'registrationType',
                'currentEvent'
            ]),
            style() {
                return {
                    '--button-text-color': this.project.style.textInsideButtonColorHex,
                    '--secondary-color': this.project.pwaStyle.secondaryColor,
                    ...this.darkenPrimaryColorStyle,
                };
            },
            address(){
                return this.project.location.formattedAddress;
            }
        },
        created(){
            if(this.registrationType === 'open'){
                this.$router.push({
                    name: ROUTE_EVENT_WITH_PERSONAL_ID,
                    params:{'event_id': this.currentEvent._id, 'registration_id': 'gm84cf8a74'},
                    query: {'pwa': true},
                });
            }
        }
    };
</script>

<style>
.login-page {
    min-height: 100vh;
    /*noinspection CssUnresolvedCustomProperty*/
    color: var(--darken-primary-color) !important;
}
</style>